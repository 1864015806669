import React from 'react';

const StatIcon = ({ small }) => {
    return small ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1907 8.71029C18.1533 8.71029 18.1155 8.70563 18.0777 8.69629C17.8427 8.63604 17.7024 8.40396 17.7649 8.1774L18.9189 3.97535C18.9818 3.74879 19.222 3.61514 19.457 3.67369C19.6919 3.73394 19.8322 3.96602 19.7698 4.19258L18.6157 8.39463C18.5634 8.58471 18.3856 8.71029 18.1907 8.71029Z" fill="#022B4D" />
        <path d="M16.03 9.95244C15.9957 9.95244 15.961 9.94863 15.9262 9.94057L14.2152 9.54211C13.9794 9.487 13.8342 9.2581 13.8914 9.03047C13.9486 8.80284 14.187 8.66295 14.4224 8.71806L16.1334 9.11652C16.3692 9.17163 16.5144 9.40053 16.4572 9.62816C16.4088 9.82231 16.2289 9.95244 16.03 9.95244Z" fill="#022B4D" />
        <path d="M12.6089 9.15755C12.5745 9.15755 12.5398 9.15373 12.505 9.14567L9.08432 8.34843C8.8485 8.29327 8.70331 8.06415 8.76051 7.8363C8.8177 7.60846 9.05572 7.46971 9.29154 7.5236L12.7123 8.32085C12.9481 8.376 13.0933 8.60512 13.0361 8.83297C12.9872 9.02729 12.8073 9.15755 12.6089 9.15755Z" fill="#022B4D" />
        <path d="M5.39913 12.6418C5.34105 12.6418 5.2821 12.6312 5.22534 12.6074C5.00228 12.5149 4.89889 12.2654 4.9948 12.0504L6.38157 8.93128C6.47704 8.71576 6.73574 8.61648 6.95924 8.70854C7.1823 8.80061 7.28569 9.05006 7.18978 9.26558L5.80301 12.3851C5.73218 12.5459 5.56983 12.6418 5.39913 12.6418Z" fill="#022B4D" />
        <path d="M2.89326 18.2769C2.83518 18.2769 2.77623 18.2663 2.71991 18.2426C2.49641 18.1501 2.39302 17.9007 2.48893 17.6856L4.30202 13.606C4.39794 13.39 4.65751 13.2903 4.8797 13.3832C5.1032 13.4757 5.20659 13.7252 5.11068 13.9402L3.29759 18.0199C3.22631 18.1811 3.06397 18.2769 2.89326 18.2769Z" fill="#022B4D" />
        <path d="M7.48106 9.65976C6.28876 9.65976 5.28125 8.68818 5.28125 7.53841C5.28125 6.38863 6.28876 5.41705 7.48106 5.41705C8.67336 5.41705 9.68088 6.38863 9.68088 7.53841C9.68088 8.68818 8.67336 9.65976 7.48106 9.65976ZM7.48106 6.26559C6.76568 6.26559 6.16118 6.84854 6.16118 7.53841C6.16118 8.22827 6.76568 8.81122 7.48106 8.81122C8.19644 8.81122 8.80095 8.22827 8.80095 7.53841C8.80095 6.84854 8.19644 6.26559 7.48106 6.26559Z" fill="#022B4D" />
        <path d="M17.7408 12.0495C16.5485 12.0495 15.541 11.078 15.541 9.9284C15.541 8.77877 16.5485 7.80731 17.7408 7.80731C18.9331 7.80731 19.9406 8.77877 19.9406 9.9284C19.9406 11.078 18.9331 12.0495 17.7408 12.0495ZM17.7408 8.65575C17.0254 8.65575 16.4209 9.23862 16.4209 9.9284C16.4209 10.6182 17.0254 11.2011 17.7408 11.2011C18.4562 11.2011 19.0607 10.6182 19.0607 9.9284C19.0607 9.23862 18.4562 8.65575 17.7408 8.65575Z" fill="#022B4D" />
        <path d="M19.7975 4.56666C18.6052 4.56666 17.5977 3.59514 17.5977 2.44544C17.5977 1.29574 18.6052 0.324219 19.7975 0.324219C20.9898 0.324219 21.9973 1.29574 21.9973 2.44544C21.9973 3.59514 20.9898 4.56666 19.7975 4.56666ZM19.7975 1.17271C19.0821 1.17271 18.4776 1.75562 18.4776 2.44544C18.4776 3.13526 19.0821 3.71817 19.7975 3.71817C20.5129 3.71817 21.1174 3.13526 21.1174 2.44544C21.1174 1.75562 20.5129 1.17271 19.7975 1.17271Z" fill="#022B4D" />
        <path d="M2.19981 21.5364C1.00751 21.5364 0 20.5649 0 19.4152C0 18.2655 1.00751 17.294 2.19981 17.294C3.39211 17.294 4.39963 18.2655 4.39963 19.4152C4.39963 20.5649 3.39211 21.5364 2.19981 21.5364ZM2.19981 18.1425C1.48443 18.1425 0.879926 18.7254 0.879926 19.4152C0.879926 20.105 1.48443 20.6879 2.19981 20.6879C2.91519 20.6879 3.5197 20.105 3.5197 19.4152C3.5197 18.7254 2.91519 18.1425 2.19981 18.1425Z" fill="#022B4D" />
        <path d="M4.08024 8.83538C3.88577 8.83538 3.70759 8.70982 3.65523 8.51977C3.28038 7.16106 3.70671 5.7192 4.7679 4.75668C4.94476 4.59676 5.22282 4.60397 5.39 4.77492C5.55631 4.94545 5.54795 5.21397 5.37109 5.37474C4.54572 6.12346 4.21398 7.24505 4.50568 8.30173C4.56815 8.52826 4.42825 8.7603 4.19331 8.82053C4.15547 8.83071 4.11763 8.83538 4.08024 8.83538Z" fill="#022B4D" />
        <path d="M20.1864 21.5364C19.7508 21.5364 19.3311 21.3642 18.9923 21.0375C18.8203 20.8716 18.8203 20.6035 18.9923 20.4376C19.1643 20.2717 19.4424 20.2717 19.6144 20.4376C19.9008 20.713 20.1908 20.7117 20.3848 20.6621C20.713 20.5772 21.0038 20.2968 21.0918 19.9799C21.1433 19.794 21.145 19.5136 20.8586 19.2378C20.6866 19.0724 20.6866 18.8038 20.8586 18.638C21.0298 18.4721 21.3087 18.4721 21.4807 18.638C21.9295 19.0698 22.0971 19.6388 21.9418 20.1996C21.7711 20.8135 21.2497 21.3171 20.6127 21.4817C20.4701 21.5186 20.3276 21.5364 20.1864 21.5364Z" fill="#022B4D" />
        <path d="M15.6159 17.6074C15.5032 17.6074 15.3906 17.5658 15.3048 17.4831C15.1328 17.3172 15.1328 17.049 15.3048 16.8832L17.1711 15.0834C17.3431 14.9175 17.6212 14.9175 17.7932 15.0834C17.9653 15.2493 17.9653 15.5174 17.7932 15.6833L15.9269 17.4831C15.8411 17.5662 15.7285 17.6074 15.6159 17.6074Z" fill="#022B4D" />
        <path d="M19.3058 21.1633C19.1932 21.1633 19.0806 21.1217 18.9948 21.039L15.3048 17.4807C15.1328 17.3149 15.1328 17.0467 15.3048 16.8809C15.4768 16.715 15.7549 16.715 15.9269 16.8809L19.6169 20.4391C19.7889 20.605 19.7889 20.8731 19.6169 21.039C19.5311 21.1217 19.4185 21.1633 19.3058 21.1633Z" fill="#022B4D" />
        <path d="M21.1711 19.3658C21.0584 19.3658 20.9458 19.3242 20.86 19.2415L17.17 15.6833C16.998 15.5174 16.998 15.2493 17.17 15.0834C17.3421 14.9175 17.6201 14.9175 17.7921 15.0834L21.4821 18.6416C21.6541 18.8075 21.6541 19.0756 21.4821 19.2415C21.3963 19.3242 21.2837 19.3658 21.1711 19.3658Z" fill="#022B4D" />
        <path d="M7.50474 14.7478C3.96656 14.7478 0.876696 12.3386 0.157357 9.02023C-0.270727 7.04838 0.206633 4.96283 1.46713 3.29898C2.72278 1.64064 4.62958 0.570715 6.69829 0.364112C6.9495 0.339082 7.2038 0.326355 7.4559 0.326355C11.5216 0.325931 14.9573 3.62819 14.9573 7.5371C14.9573 11.1838 12.0148 14.3346 8.25839 14.7105C8.00893 14.7355 7.75508 14.7478 7.50474 14.7478ZM7.45502 1.1744C7.23372 1.1744 7.00934 1.18543 6.78848 1.20792C4.96659 1.39034 3.28593 2.33469 2.17723 3.79915C1.06412 5.26956 0.641756 7.10904 1.01792 8.84629C1.65279 11.7739 4.38056 13.8994 7.50386 13.8994H7.5043C7.72472 13.8994 7.94778 13.8883 8.16732 13.8663C11.4816 13.5354 14.0773 10.7549 14.0773 7.5371C14.0773 4.08806 11.0447 1.1744 7.45502 1.1744Z" fill="#022B4D" />
        <path d="M7.43815 13.0489C6.88996 13.0489 6.34485 12.9704 5.81689 12.816C3.79218 12.2237 2.23735 10.5458 1.85458 8.54167C1.46478 6.50147 2.32271 4.37726 4.04164 3.12912C5.01924 2.41935 6.24409 2.0282 7.49095 2.0282C8.39243 2.0282 9.28556 2.23311 10.0744 2.6213C11.9729 3.55507 13.199 5.48497 13.199 7.53748C13.199 9.01768 12.5804 10.4101 11.4568 11.4588C10.374 12.4694 8.90939 13.0489 7.43815 13.0489ZM7.49095 2.87712C6.43416 2.87712 5.39673 3.20761 4.57091 3.80749C3.1164 4.86345 2.39002 6.66183 2.71999 8.38852C3.04336 10.0842 4.35973 11.5033 6.07207 12.0044C6.51731 12.1346 6.97663 12.2008 7.43771 12.2008H7.43815C8.68325 12.2008 9.92438 11.7083 10.8448 10.8492C11.7955 9.96163 12.3191 8.78562 12.3191 7.53748C12.3191 5.80061 11.2812 4.16768 9.67492 3.37731C9.0097 3.05021 8.25428 2.87712 7.49095 2.87712Z" fill="#022B4D" />
        <path d="M15.9283 17.3046C15.8157 17.3046 15.703 17.263 15.6173 17.1803L11.4864 13.1974C11.3144 13.0315 11.3144 12.7634 11.4864 12.5975C11.6585 12.4316 11.9365 12.4316 12.1085 12.5975L16.2394 16.5804C16.4114 16.7463 16.4114 17.0144 16.2394 17.1803C16.1536 17.2635 16.0409 17.3046 15.9283 17.3046Z" fill="#022B4D" />
        <path d="M17.1716 16.105C17.0589 16.105 16.9463 16.0634 16.8605 15.9807L12.7306 11.997C12.5586 11.8311 12.5586 11.563 12.7306 11.3971C12.9026 11.2312 13.1807 11.2312 13.3527 11.3971L17.4826 15.3807C17.6546 15.5466 17.6546 15.8148 17.4826 15.9807C17.3968 16.0638 17.2842 16.105 17.1716 16.105Z" fill="#022B4D" />
    </svg>
        : <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.656 21.3504C44.5642 21.3504 44.4713 21.3385 44.3785 21.3147C43.8018 21.1614 43.4573 20.5707 43.6107 19.9941L46.4432 9.29889C46.5976 8.72223 47.1872 8.38207 47.7639 8.53109C48.3406 8.68444 48.685 9.27513 48.5317 9.85179L45.6992 20.547C45.5707 21.0307 45.1344 21.3504 44.656 21.3504Z" fill="#022B4D" />
            <path d="M39.3522 24.5118C39.2679 24.5118 39.1826 24.5021 39.0973 24.4816L34.8976 23.4665C34.3188 23.3261 33.9625 22.743 34.1028 22.1631C34.2432 21.5832 34.8285 21.2268 35.4063 21.3672L39.6059 22.3823C40.1847 22.5227 40.5411 23.1058 40.4007 23.6857C40.2819 24.1803 39.8403 24.5118 39.3522 24.5118Z" fill="#022B4D" />
            <path d="M30.9548 22.481C30.8706 22.481 30.7853 22.4712 30.7 22.4507L22.3039 20.4216C21.7251 20.2812 21.3687 19.6981 21.5091 19.1182C21.6495 18.5383 22.2337 18.1852 22.8125 18.3223L31.2086 20.3514C31.7874 20.4918 32.1438 21.0749 32.0034 21.6548C31.8835 22.1494 31.4419 22.481 30.9548 22.481Z" fill="#022B4D" />
            <path d="M13.2541 31.3484C13.1116 31.3484 12.9669 31.3214 12.8276 31.261C12.2801 31.0255 12.0263 30.3906 12.2617 29.8431L15.6655 21.9038C15.8999 21.3552 16.5348 21.1025 17.0834 21.3368C17.6309 21.5712 17.8847 22.2061 17.6493 22.7547L14.2455 30.6951C14.0716 31.1044 13.6731 31.3484 13.2541 31.3484Z" fill="#022B4D" />
            <path d="M7.10179 45.704C6.95924 45.704 6.81454 45.677 6.67631 45.6165C6.12773 45.3811 5.87396 44.7461 6.10937 44.1986L10.5596 33.8134C10.795 33.2637 11.4321 33.0099 11.9775 33.2464C12.526 33.4818 12.7798 34.1168 12.5444 34.6643L8.0942 45.0496C7.91926 45.4599 7.52078 45.704 7.10179 45.704Z" fill="#022B4D" />
            <path d="M18.3584 23.7575C15.4319 23.7575 12.959 21.2845 12.959 18.3581C12.959 15.4316 15.4319 12.9586 18.3584 12.9586C21.2849 12.9586 23.7578 15.4316 23.7578 18.3581C23.7578 21.2845 21.2849 23.7575 18.3584 23.7575ZM18.3584 15.1184C16.6025 15.1184 15.1188 16.6022 15.1188 18.3581C15.1188 20.114 16.6025 21.5977 18.3584 21.5977C20.1143 21.5977 21.598 20.114 21.598 18.3581C21.598 16.6022 20.1143 15.1184 18.3584 15.1184Z" fill="#022B4D" />
            <path d="M43.5479 29.8461C40.6214 29.8461 38.1484 27.3732 38.1484 24.4467C38.1484 21.5202 40.6214 19.0473 43.5479 19.0473C46.4743 19.0473 48.9473 21.5202 48.9473 24.4467C48.9473 27.3732 46.4743 29.8461 43.5479 29.8461ZM43.5479 21.207C41.792 21.207 40.3082 22.6908 40.3082 24.4467C40.3082 26.2026 41.792 27.6863 43.5479 27.6863C45.3037 27.6863 46.7875 26.2026 46.7875 24.4467C46.7875 22.6908 45.3037 21.207 43.5479 21.207Z" fill="#022B4D" />
            <path d="M48.5947 10.7988C45.6682 10.7988 43.1953 8.3259 43.1953 5.39942C43.1953 2.47293 45.6682 0 48.5947 0C51.5212 0 53.9941 2.47293 53.9941 5.39942C53.9941 8.3259 51.5212 10.7988 48.5947 10.7988ZM48.5947 2.15977C46.8388 2.15977 45.3551 3.64353 45.3551 5.39942C45.3551 7.15531 46.8388 8.63906 48.5947 8.63906C50.3506 8.63906 51.8344 7.15531 51.8344 5.39942C51.8344 3.64353 50.3506 2.15977 48.5947 2.15977Z" fill="#022B4D" />
            <path d="M5.39942 53.9948C2.47293 53.9948 0 51.5219 0 48.5954C0 45.6689 2.47293 43.196 5.39942 43.196C8.3259 43.196 10.7988 45.6689 10.7988 48.5954C10.7988 51.5219 8.3259 53.9948 5.39942 53.9948ZM5.39942 45.3558C3.64353 45.3558 2.15977 46.8395 2.15977 48.5954C2.15977 50.3513 3.64353 51.835 5.39942 51.835C7.15531 51.835 8.63906 50.3513 8.63906 48.5954C8.63906 46.8395 7.15531 45.3558 5.39942 45.3558Z" fill="#022B4D" />
            <path d="M10.0082 21.6644C9.53086 21.6644 9.09351 21.3448 8.965 20.861C8.04494 17.4021 9.09135 13.7316 11.696 11.2814C12.1301 10.8742 12.8126 10.8926 13.223 11.3278C13.6312 11.7619 13.6107 12.4455 13.1765 12.8548C11.1507 14.7608 10.3365 17.616 11.0524 20.306C11.2058 20.8826 10.8624 21.4733 10.2857 21.6266C10.1928 21.6526 10.1 21.6644 10.0082 21.6644Z" fill="#022B4D" />
            <path d="M49.5522 53.9981C48.4831 53.9981 47.4529 53.5597 46.6214 52.7282C46.1991 52.3059 46.1991 51.6235 46.6214 51.2012C47.0436 50.779 47.7261 50.779 48.1483 51.2012C48.8513 51.9021 49.563 51.8988 50.0392 51.7725C50.8448 51.5565 51.5586 50.8427 51.7746 50.036C51.9009 49.563 51.9052 48.8492 51.2022 48.1473C50.78 47.7262 50.78 47.0426 51.2022 46.6204C51.6223 46.1981 52.3069 46.1981 52.7292 46.6204C53.8307 47.7197 54.2421 49.1678 53.8609 50.5954C53.4419 52.158 52.1622 53.4398 50.5986 53.8588C50.2487 53.9528 49.8988 53.9981 49.5522 53.9981Z" fill="#022B4D" />
            <path d="M38.3282 43.9882C38.0518 43.9882 37.7753 43.8824 37.5647 43.6718C37.1425 43.2495 37.1425 42.5671 37.5647 42.1448L42.1456 37.564C42.5678 37.1417 43.2503 37.1417 43.6725 37.564C44.0948 37.9862 44.0948 38.6687 43.6725 39.0909L39.0917 43.6718C38.8811 43.8834 38.6047 43.9882 38.3282 43.9882Z" fill="#022B4D" />
            <path d="M47.3852 53.045C47.1087 53.045 46.8323 52.9391 46.6217 52.7286L37.5647 43.6716C37.1425 43.2494 37.1425 42.5669 37.5647 42.1446C37.987 41.7224 38.6694 41.7224 39.0917 42.1446L48.1487 51.2016C48.5709 51.6238 48.5709 52.3063 48.1487 52.7286C47.9381 52.9391 47.6616 53.045 47.3852 53.045Z" fill="#022B4D" />
            <path d="M51.9672 48.4643C51.6908 48.4643 51.4143 48.3585 51.2037 48.1479L42.1468 39.0909C41.7245 38.6687 41.7245 37.9862 42.1468 37.564C42.569 37.1417 43.2515 37.1417 43.6737 37.564L52.7307 46.6209C53.1529 47.0432 53.1529 47.7257 52.7307 48.1479C52.5201 48.3585 52.2437 48.4643 51.9672 48.4643Z" fill="#022B4D" />
            <path d="M18.4244 36.7131C9.73995 36.7131 2.15592 30.5804 0.390315 22.1336C-0.660412 17.1143 0.511262 11.8056 3.60513 7.57028C6.68711 3.34902 11.3673 0.625553 16.4449 0.09965C17.0616 0.0359369 17.6857 0.00354014 18.3045 0.00354014C28.2837 0.00246026 36.7165 8.40827 36.7165 18.3583C36.7165 27.641 29.4942 35.6613 20.2742 36.6181C19.6619 36.6818 19.0388 36.7131 18.4244 36.7131ZM18.3023 2.16223C17.7592 2.16223 17.2084 2.1903 16.6663 2.24754C12.1945 2.71189 8.06936 5.11571 5.34806 8.84346C2.61595 12.5863 1.57927 17.2687 2.50257 21.6908C4.06084 29.1431 10.7561 34.5533 18.4222 34.5533H18.4233C18.9643 34.5533 19.5118 34.5252 20.0507 34.4691C28.1854 33.6268 34.5567 26.5492 34.5567 18.3583C34.5567 9.57886 27.1131 2.16223 18.3023 2.16223Z" fill="#022B4D" />
            <path d="M18.257 32.3864C16.9114 32.3864 15.5734 32.1866 14.2776 31.7935C9.30796 30.286 5.49165 26.0151 4.55216 20.9137C3.59538 15.7205 5.70115 10.3136 9.92026 7.13656C12.3198 5.32991 15.3261 4.33426 18.3865 4.33426C20.5992 4.33426 22.7914 4.85584 24.7276 5.84394C29.3873 8.22076 32.3969 13.1331 32.3969 18.3576C32.3969 22.1253 30.8786 25.6695 28.1206 28.339C25.463 30.9113 21.8681 32.3864 18.257 32.3864ZM18.3865 6.49511C15.7927 6.49511 13.2463 7.33633 11.2194 8.86329C7.64926 11.5511 5.86637 16.1287 6.67629 20.5239C7.47 24.8402 10.701 28.4524 14.9039 29.7277C15.9968 30.0592 17.1242 30.2277 18.2559 30.2277H18.257C21.313 30.2277 24.3594 28.974 26.6185 26.7872C28.9521 24.5281 30.2372 21.5346 30.2372 18.3576C30.2372 13.9366 27.6897 9.78011 23.7471 7.76829C22.1143 6.9357 20.2601 6.49511 18.3865 6.49511Z" fill="#022B4D" />
            <path d="M39.0922 43.2239C38.8158 43.2239 38.5393 43.1181 38.3287 42.9075L28.1897 32.7695C27.7675 32.3473 27.7675 31.6648 28.1897 31.2426C28.612 30.8203 29.2944 30.8203 29.7167 31.2426L39.8557 41.3805C40.2779 41.8028 40.2779 42.4852 39.8557 42.9075C39.6451 43.1191 39.3687 43.2239 39.0922 43.2239Z" fill="#022B4D" />
            <path d="M42.1467 40.17C41.8703 40.17 41.5938 40.0642 41.3832 39.8536L31.2464 29.7146C30.8241 29.2923 30.8241 28.6099 31.2464 28.1876C31.6686 27.7654 32.3511 27.7654 32.7733 28.1876L42.9102 38.3266C43.3324 38.7489 43.3324 39.4314 42.9102 39.8536C42.6996 40.0652 42.4232 40.17 42.1467 40.17Z" fill="#022B4D" />
        </svg>


}

export default StatIcon