import React from 'react';

const ProjectIcon = ({ small }) => small ?
    <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.7841 37.8477C45.6403 37.7078 45.4247 37.6378 45.2809 37.6378H0.790306C0.574681 37.6378 0.430931 37.7078 0.287181 37.8477C0.143431 37.9876 0.0715563 38.1975 0.143431 38.4074C0.574681 42.0454 3.80906 44.7739 7.61843 44.7739H38.4528C42.2622 44.7739 45.4247 42.0454 45.9278 38.3374C45.9997 38.1975 45.9278 37.9876 45.7841 37.8477ZM25.3716 41.8355H20.6997C20.3403 41.8355 19.9809 41.5556 19.9809 41.1359C19.9809 40.7861 20.2684 40.4363 20.6997 40.4363H25.3716C25.7309 40.4363 26.0903 40.7161 26.0903 41.1359C26.0903 41.5556 25.7309 41.8355 25.3716 41.8355Z" fill="white" />
        <path d="M3.01758 35.5396V15.9505H4.3832V34.91H41.5426V11.2631C41.5426 10.2137 40.6801 9.37418 39.602 9.37418H21.4895V8.04492H39.6738C41.5426 8.04492 43.052 9.5141 43.052 11.3331V35.6795C43.052 36.0293 42.7645 36.3792 42.3332 36.3792H3.73633C3.37695 36.2392 3.01758 35.9594 3.01758 35.5396Z" fill="white" />
        <path d="M17.6812 0H2.44375C1.07813 0 0 1.04942 0 2.30873V12.2432C0 13.5725 1.07813 14.6219 2.44375 14.6219H10.8531L15.4531 18.19C15.6687 18.3299 15.9563 18.3998 16.1719 18.2599C16.3875 18.12 16.6031 17.9101 16.6031 17.6303V14.552H17.6812C19.0469 14.552 20.125 13.5025 20.125 12.1733V2.30873C20.0531 1.04942 18.975 0 17.6812 0ZM15.8844 11.1938H4.24062C3.88125 11.1938 3.52188 10.914 3.52188 10.4942C3.52188 10.0744 3.80938 9.79459 4.24062 9.79459H15.8844C16.2437 9.79459 16.6031 10.0744 16.6031 10.4942C16.6031 10.914 16.2437 11.1938 15.8844 11.1938ZM15.8844 7.9756H4.24062C3.88125 7.9756 3.52188 7.69575 3.52188 7.27598C3.52188 6.92618 3.80938 6.57637 4.24062 6.57637H15.8844C16.2437 6.57637 16.6031 6.85622 16.6031 7.27598C16.6031 7.69575 16.2437 7.9756 15.8844 7.9756ZM15.8844 4.75737H4.24062C3.88125 4.75737 3.52188 4.47753 3.52188 4.05776C3.52188 3.70795 3.80938 3.35815 4.24062 3.35815H15.8844C16.2437 3.35815 16.6031 3.63799 16.6031 4.05776C16.6031 4.47753 16.2437 4.75737 15.8844 4.75737Z" fill="white" />
        <path d="M15.8848 25.2567C16.0285 25.3967 16.1004 25.6765 16.0285 25.8864L13.6566 32.8828C13.5129 33.3026 13.0816 33.3026 13.0098 33.3026C12.7223 33.3026 12.5066 33.0927 12.4348 32.8128L11.7879 29.3146L8.12227 28.615C7.83477 28.545 7.61914 28.3351 7.61914 28.0553C7.61914 27.7754 7.76289 27.4956 8.05039 27.4256L15.2379 25.1168C15.4535 25.0468 15.741 25.1168 15.8848 25.2567Z" fill="white" />
        <path d="M38.8121 26.7958V31.2034C38.8121 31.5532 38.5246 31.903 38.0934 31.903H26.5215C26.1621 31.903 25.8027 31.6231 25.8027 31.2034V26.7958C25.8027 25.1167 27.1684 23.7875 28.8934 23.7875H35.7934C37.4465 23.7875 38.8121 25.1167 38.8121 26.7958Z" fill="white" />
        <path d="M28.6064 18.6796C28.6064 16.7208 30.2596 15.1117 32.2721 15.1117C34.2846 15.1117 35.9377 16.7208 35.9377 18.6796C35.9377 20.6385 34.2846 22.2476 32.2721 22.2476C30.2596 22.2476 28.6064 20.6385 28.6064 18.6796Z" fill="white" />
    </svg>
    : <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80.62 68.4705C80.3668 68.2174 79.9872 68.0908 79.734 68.0908H1.39184C1.01215 68.0908 0.759026 68.2174 0.505901 68.4705C0.252776 68.7236 0.126214 69.1033 0.252776 69.483C1.01215 76.0643 6.70746 81.0002 13.4153 81.0002H67.7106C74.4184 81.0002 79.9872 76.0643 80.8731 69.3564C80.9997 69.1033 80.8731 68.7236 80.62 68.4705ZM44.6762 75.6846H36.4497C35.8168 75.6846 35.184 75.1783 35.184 74.4189C35.184 73.7861 35.6903 73.1533 36.4497 73.1533H44.6762C45.309 73.1533 45.9418 73.6596 45.9418 74.4189C45.9418 75.1783 45.309 75.6846 44.6762 75.6846Z" fill="white" />
        <path d="M5.31445 64.2938V28.8562H7.71914V63.1547H73.1519V20.3766C73.1519 18.4781 71.6332 16.9594 69.7348 16.9594H37.841V14.5547H69.8613C73.1519 14.5547 75.8098 17.2125 75.8098 20.5031V64.5469C75.8098 65.1797 75.3035 65.8125 74.5441 65.8125H6.58008C5.94727 65.5594 5.31445 65.0531 5.31445 64.2938Z" fill="white" />
        <path d="M31.1344 0H4.30312C1.89844 0 0 1.89844 0 4.17656V22.1484C0 24.5531 1.89844 26.4516 4.30312 26.4516H19.1109L27.2109 32.9062C27.5906 33.1594 28.0969 33.2859 28.4766 33.0328C28.8563 32.7797 29.2359 32.4 29.2359 31.8938V26.325H31.1344C33.5391 26.325 35.4375 24.4266 35.4375 22.0219V4.17656C35.3109 1.89844 33.4125 0 31.1344 0ZM27.9703 20.25H7.46719C6.83437 20.25 6.20156 19.7437 6.20156 18.9844C6.20156 18.225 6.70781 17.7188 7.46719 17.7188H27.9703C28.6031 17.7188 29.2359 18.225 29.2359 18.9844C29.2359 19.7437 28.6031 20.25 27.9703 20.25ZM27.9703 14.4281H7.46719C6.83437 14.4281 6.20156 13.9219 6.20156 13.1625C6.20156 12.5297 6.70781 11.8969 7.46719 11.8969H27.9703C28.6031 11.8969 29.2359 12.4031 29.2359 13.1625C29.2359 13.9219 28.6031 14.4281 27.9703 14.4281ZM27.9703 8.60625H7.46719C6.83437 8.60625 6.20156 8.1 6.20156 7.34063C6.20156 6.70781 6.70781 6.075 7.46719 6.075H27.9703C28.6031 6.075 29.2359 6.58125 29.2359 7.34063C29.2359 8.1 28.6031 8.60625 27.9703 8.60625Z" fill="white" />
        <path d="M27.9688 45.689C28.2219 45.9421 28.3484 46.4484 28.2219 46.8281L24.0453 59.4843C23.7922 60.2437 23.0328 60.2437 22.9062 60.2437C22.4 60.2437 22.0203 59.864 21.8937 59.3578L20.7547 53.0296L14.3 51.764C13.7937 51.6374 13.4141 51.2578 13.4141 50.7515C13.4141 50.2453 13.6672 49.739 14.1734 49.6124L26.8297 45.4359C27.2094 45.3093 27.7156 45.4359 27.9688 45.689Z" fill="white" />
        <path d="M68.3414 48.4732V56.4466C68.3414 57.0794 67.8352 57.7123 67.0758 57.7123H46.6992C46.0664 57.7123 45.4336 57.206 45.4336 56.4466V48.4732C45.4336 45.4357 47.8383 43.031 50.8758 43.031H63.0258C65.9367 43.031 68.3414 45.4357 68.3414 48.4732Z" fill="white" />
        <path d="M50.3691 33.7921C50.3691 30.2483 53.2801 27.3374 56.8238 27.3374C60.3676 27.3374 63.2785 30.2483 63.2785 33.7921C63.2785 37.3358 60.3676 40.2468 56.8238 40.2468C53.2801 40.2468 50.3691 37.3358 50.3691 33.7921Z" fill="white" />
    </svg>

export default ProjectIcon