import React from 'react';
import Typography from '../Typography/Typography';
import style from '../../styleConstants';
import { TYPOGRAPHY_VARIANTS } from '../Typography/constants';
import SvgIcon from '../SvgIcon';
import { ICONS } from '../SvgIcon/constants';
import { useContext } from 'react';
import ScreenBreakpointContext from '../ScreenBreakpointProvider/Provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PageHeading = ({ heading, subHeading, mascotUrl, color = style.colors.deepGreen, onBackButtonClick }) => {

    const { screen } = useContext(ScreenBreakpointContext)

    return <div className="d-flex justify-content-center align-items-strech px-2">
        {onBackButtonClick && <div className="mx-lg-5 mx-2 pt-4 pt-lg-5">
            <span className="cursor-pointer" onClick={onBackButtonClick}>
                {screen.lgDown ?
                    <FontAwesomeIcon icon={faArrowLeft} />
                    :
                    <SvgIcon icon={ICONS.backIcon} onClick={() => alert('working')} />
                }
            </span>
        </div>}
        {mascotUrl && <div className="p-lg-3, p-1 d-flex align-items-center">
            <img src={mascotUrl} alt="Mascot" />
        </div>}
        <div className="p-lg-5 py-3 px-1 text-center">
            {heading && <Typography variant={TYPOGRAPHY_VARIANTS.pageHeading} color={color}>{heading}</Typography>}
            {subHeading && <div className="p-3">
                <Typography variant={TYPOGRAPHY_VARIANTS.subHeading} color={style.colors.textBlack}>{subHeading}</Typography>
            </div>
            }
        </div>
    </div>
}

export default PageHeading