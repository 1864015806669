import React from "react";

const FooterLogoIcon = () => {
  return (
    <svg
      width="139"
      height="62"
      viewBox="0 0 139 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.4812 17.5589C69.3075 19.7669 67.0983 21.9748 64.9246 24.1828C59.8646 29.2753 51.7045 29.8451 46.2526 25.2155C42.4754 22.0104 35.4199 14.2113 35.4199 14.2113C35.4199 14.2113 43.1881 5.84244 47.5354 2.42367C52.1678 -1.2444 59.5439 -0.425316 64.0338 3.42081C64.6395 3.955 64.889 4.31112 64.1763 4.98775C62.5728 6.48346 61.0762 8.05041 59.5439 9.65296C59.0807 10.1515 58.7956 10.2584 58.1186 9.83102C55.5529 8.19286 52.9873 8.58459 50.8137 10.7569C49.8516 11.7541 48.8182 12.68 47.9273 13.7484C47.7492 13.962 47.7135 14.6387 47.9273 14.8167C49.1389 16.0988 50.3504 17.3808 51.7045 18.5204C53.7713 20.301 56.9783 20.0161 59.0094 17.9506C63.5349 13.3922 68.0247 8.79827 72.5858 4.23989C77.432 -0.603376 84.5944 -1.38685 90.3671 2.35244C91.2579 2.92224 92.0775 3.59887 92.7902 4.31112C95.819 7.26694 102.304 14.2113 102.304 14.2113C102.304 14.2113 95.1776 21.6543 91.5786 24.9306C86.7324 29.3465 78.5723 29.2397 73.6192 24.9662C72.9066 24.3608 72.8353 23.9691 73.548 23.2925C75.1158 21.8324 76.5768 20.2654 78.0734 18.7341C78.5367 18.2355 78.8574 18.0931 79.5344 18.5204C82.0288 20.123 84.5944 19.8025 86.7324 17.7013C87.6945 16.7754 88.5854 15.7427 89.5831 14.8524C90.2958 14.2113 90.1533 13.8196 89.5118 13.2854C88.5141 12.3951 87.5876 11.398 86.6255 10.472C84.1668 8.08602 80.9598 8.12163 78.5367 10.5077C76.1492 12.8225 73.833 15.2085 71.4812 17.5589Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M12.1511 45.6929L10.5832 47.5447C9.65672 46.5832 8.3739 46.0134 6.80602 46.049C4.52547 46.049 2.85069 47.7584 2.85069 50.2868C2.85069 52.8153 4.48984 54.5247 6.80602 54.5247C8.4808 54.5247 9.83488 53.9905 10.7257 52.9221L12.3292 54.5247C11.1177 56.0916 9.19348 56.9819 6.69913 56.9819C2.74379 56.9819 0 54.2754 0 50.2868C0 46.2627 2.74379 43.5205 6.69913 43.5205C9.05095 43.5205 10.9039 44.304 12.1511 45.6929Z"
        fill="white"
      />
      <path
        d="M22.8049 55.2726C21.8428 56.4478 20.3461 57.0176 18.4219 57.0176C15.5356 57.0176 13.7539 55.237 13.7539 52.8866C13.7539 50.4649 15.5712 48.9336 18.7783 48.898H22.8049V48.5062C22.8049 46.8325 21.7359 45.8353 19.6335 45.8353C18.3507 45.8353 17.0322 46.2983 15.7138 47.1886L14.5379 45.1943C16.3908 44.0903 17.7449 43.5205 20.2392 43.5205C23.6601 43.5205 25.5843 45.2655 25.6199 48.1857L25.6556 56.8751H22.8405V55.2726H22.8049ZM22.8049 52.2455V51.0347H19.2059C17.3173 51.0347 16.4621 51.5333 16.4621 52.8153C16.4621 54.0262 17.4242 54.774 19.0277 54.774C21.0588 54.7384 22.6267 53.67 22.8049 52.2455Z"
        fill="white"
      />
      <path
        d="M32.4625 46.2626C33.3533 44.482 34.9569 43.5205 37.0592 43.5205V46.2626C34.3867 46.1202 32.6763 47.6871 32.4625 49.9663V56.9107H29.5762V43.663H32.4625V46.2626Z"
        fill="white"
      />
      <path
        d="M51.2407 51.3541H41.2277C41.6909 53.384 43.2588 54.666 45.3612 54.666C46.8222 54.666 48.1406 54.1318 49.1027 53.1347L50.635 54.7729C49.3521 56.1973 47.4279 57.0164 45.1474 57.0164C41.0495 57.0164 38.377 54.3099 38.377 50.3213C38.377 46.2971 41.1564 43.5906 45.0761 43.555C49.6728 43.5194 51.5614 46.582 51.2407 51.3541ZM48.7464 49.253C48.6395 47.1162 47.2141 45.7986 45.0405 45.7986C42.9381 45.7986 41.4771 47.1162 41.1564 49.253H48.7464Z"
        fill="white"
      />
      <path
        d="M66.0298 51.3541H56.0167C56.4799 53.384 58.0478 54.666 60.1502 54.666C61.6112 54.666 62.9296 54.1318 63.8918 53.1347L65.424 54.7729C64.1412 56.1973 62.217 57.0164 59.9364 57.0164C55.8385 57.0164 53.166 54.3099 53.166 50.3213C53.166 46.2971 55.9454 43.5906 59.8651 43.555C64.4619 43.5194 66.3861 46.582 66.0298 51.3541ZM63.5354 49.253C63.4285 47.1162 62.0032 45.7986 59.8295 45.7986C57.7271 45.7986 56.2661 47.1162 55.9454 49.253H63.5354Z"
        fill="white"
      />
      <path
        d="M72.0152 46.2626C72.9061 44.482 74.5096 43.5205 76.612 43.5205V46.2626C73.9394 46.1202 72.229 47.6871 72.0152 49.9663V56.9107H69.1289V43.663H72.0152V46.2626Z"
        fill="white"
      />
      <path
        d="M92.5049 48.6487V56.9107H89.6186V49.4678C89.6186 47.4735 88.407 46.2626 86.4115 46.2626C84.2022 46.2983 82.8482 47.794 82.6344 49.8951V56.9463H79.748V43.6986H82.6344V46.227C83.5965 44.3752 85.2713 43.5917 87.5162 43.5917C90.6519 43.5205 92.5049 45.4792 92.5049 48.6487Z"
        fill="white"
      />
      <path
        d="M96.4609 56.9099V43.6621H99.3116V56.9099H96.4609Z"
        fill="white"
      />
      <path
        d="M116.38 48.6487V56.9107H113.494V49.4678C113.494 47.4735 112.282 46.2626 110.287 46.2626C108.077 46.2983 106.723 47.794 106.509 49.8951V56.9463H103.623V43.6986H106.509V46.227C107.471 44.3752 109.146 43.5917 111.391 43.5917C114.527 43.5205 116.38 45.4792 116.38 48.6487Z"
        fill="white"
      />
      <path
        d="M123.149 57.266C123.149 60.3999 120.976 61.86 118.873 61.86C117.875 61.86 116.878 61.5395 116.094 60.9697L117.056 58.7617C117.59 59.1178 118.196 59.3671 118.766 59.3671C119.657 59.3671 120.334 58.833 120.334 57.5865V43.6621H123.185V57.266H123.149Z"
        fill="white"
      />
      <path
        d="M135.586 55.2726C134.624 56.4478 133.127 57.0176 131.203 57.0176C128.317 57.0176 126.535 55.237 126.535 52.8866C126.535 50.4649 128.352 48.9336 131.559 48.898H135.586V48.5062C135.586 46.8325 134.517 45.8353 132.415 45.8353C131.132 45.8353 129.813 46.2983 128.495 47.1886L127.319 45.1943C129.172 44.0903 130.526 43.5205 133.02 43.5205C136.441 43.5205 138.366 45.2655 138.401 48.1857L138.437 56.8751H135.622V55.2726H135.586ZM135.55 52.2455V51.0347H131.951C130.063 51.0347 129.208 51.5333 129.208 52.8153C129.208 54.0262 130.17 54.774 131.773 54.774C133.84 54.7384 135.408 53.67 135.55 52.2455Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="102.234"
          y1="14.766"
          x2="35.4157"
          y2="13.599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7DC242" />
          <stop offset="0.165" stopColor="#6BBF60" />
          <stop offset="0.4955" stopColor="#2AB9A0" />
          <stop offset="0.6006" stopColor="#39BCAB" />
          <stop offset="0.8092" stopColor="#54C3CA" />
          <stop offset="1" stopColor="#68CBEA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FooterLogoIcon;
