import React from 'react';

const CubesIcon = ({ small }) => {
    return small ? <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.3129 17.2766L9.30127 11.8691L4.64643 9.15619L0 11.8593L0.0113233 17.3091L4.64643 20.0003L9.3129 17.2766ZM5.11864 18.6351L5.12695 14.8489L8.36234 12.9588L8.37065 16.7366L5.11864 18.6351ZM4.64578 10.2454L7.89698 12.1406L4.6568 14.0332L1.4055 12.1308L4.64578 10.2454ZM0.943962 12.9515L4.1855 14.8483L4.17725 18.6396L0.951762 16.7666L0.943962 12.9515Z" fill="white" />
        <path d="M14.3338 9.15619L9.6875 11.859L9.69913 17.3088L14.3343 20L19.0002 17.2763L18.9886 11.8688L14.3338 9.15619ZM14.3331 10.2451L17.5844 12.1403L14.3442 14.0329L11.0928 12.1305L14.3331 10.2451ZM10.6314 12.9512L13.8725 14.848L13.8643 18.6393L10.6396 16.7663L10.6314 12.9512ZM14.8063 18.6348L14.8143 14.8486L18.0497 12.9585L18.0579 16.7363L14.8063 18.6348Z" fill="white" />
        <path d="M14.1548 3.31543L9.4998 0.603271L4.85352 3.30562L4.86469 8.75566L9.4998 11.447L14.1659 8.72335L14.1548 3.31543ZM9.4992 1.69207L12.7507 3.58679L9.51052 5.48L6.25902 3.57713L9.4992 1.69207ZM5.79733 4.39789L9.03886 6.29507L9.03076 10.0864L5.80513 8.2137L5.79733 4.39789ZM9.97186 10.0818L9.98001 6.29558L13.2156 4.40514L13.2238 8.18325L9.97186 10.0818Z" fill="white" />
    </svg>
        : <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.8874 48.1363L26.8538 32.5243L13.4148 24.6918L0 32.496L0.0326916 48.2301L13.4148 55.9999L26.8874 48.1363ZM14.7781 52.0584L14.8021 41.1275L24.143 35.6704L24.167 46.5774L14.7781 52.0584ZM13.4129 27.8366L22.7995 33.3082L13.4447 38.7722L4.05783 33.28L13.4129 27.8366ZM2.72532 35.6492L12.084 41.1256L12.0602 52.0715L2.74784 46.664L2.72532 35.6492Z" fill="white" />
            <path d="M41.3811 24.693L27.9668 32.4963L28.0004 48.2304L41.3827 56.0001L54.8537 48.1365L54.8202 32.5246L41.3811 24.693ZM41.3792 27.8369L50.7661 33.3085L41.4111 38.7725L32.0241 33.2801L41.3792 27.8369ZM30.6918 35.6495L40.0495 41.1258L40.0257 52.0717L30.7157 46.6642L30.6918 35.6495ZM42.7453 52.0587L42.7684 41.1277L52.1094 35.6707L52.1332 46.5776L42.7453 52.0587Z" fill="white" />
            <path d="M40.8675 7.83031L27.428 0L14.0137 7.80197L14.0459 23.5368L27.428 31.3071L40.8994 23.4436L40.8675 7.83031ZM27.4263 3.14348L36.8137 8.61374L27.459 14.0796L18.0715 8.58585L27.4263 3.14348ZM16.7386 10.9555L26.0972 16.4329L26.0738 27.3788L16.7611 21.9721L16.7386 10.9555ZM28.7909 27.3655L28.8144 16.4343L38.1558 10.9764L38.1796 21.8842L28.7909 27.3655Z" fill="white" />
        </svg>

}

export default CubesIcon