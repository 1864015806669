import React from 'react';

const StarIcon = ({ small }) => {
    return small ?
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9998 8.3613L15.5768 11.7773L15.8521 14.7854C16.2857 18.5203 16.6346 18.6193 13.0621 17.0139L9.81234 15.5541L5.8534 17.1379C3.02917 18.4233 3.30599 18.218 3.68373 15.481L4.22864 11.5436L0.578792 7.5702C-0.204599 6.78826 -0.406937 6.51142 1.35491 6.20112L6.54072 5.2881L8.93651 1.52945C10.0181 -0.204001 9.93073 -0.855766 11.3978 1.69148L13.5539 5.43111L18.2142 6.45275C20.4052 6.95785 20.4734 6.89093 18.9998 8.3613Z" fill="white" />
        </svg>
        :
        <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.0996 16.7226L29.5959 23.5547L30.1191 29.5709C30.9428 37.0405 31.6057 37.2385 24.818 34.0278L18.6434 31.1082L11.1215 34.2758C5.75542 36.8466 6.28137 36.436 6.99909 30.962L8.03441 23.0872L1.09971 15.1404C-0.388739 13.5765 -0.773181 13.0228 2.57432 12.4022L12.4274 10.5762L16.9794 3.05891C19.0343 -0.408002 18.8684 -1.71153 21.6558 3.38296L25.7524 10.8622L34.607 12.9055C38.7699 13.9157 38.8995 13.7819 36.0996 16.7226Z" fill="white" />
        </svg>

}

export default StarIcon