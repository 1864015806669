import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import style from '../../styleConstants';
import ScreenBreakpointContext from './Provider'

const ScreenBreakpointProvider = ({ children }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const updateScreenWidth = useCallback(() => {
        setScreenWidth(window.innerWidth)
    }, [])
    React.useEffect(() => {
        window.addEventListener("resize", updateScreenWidth);
        return () => window.removeEventListener("resize", updateScreenWidth);
    });
    const screen = useMemo(() => ({
        width: screenWidth,
        xsUp: screenWidth > style.breakpoints.xs,
        smUp: screenWidth >= style.breakpoints.sm,
        smDown: screenWidth < style.breakpoints.sm,
        sm:
            screenWidth >= style.breakpoints.sm && screenWidth < style.breakpoints.md,
        mdUp: screenWidth >= style.breakpoints.md,
        mdDown: screenWidth < style.breakpoints.md,
        md:
            screenWidth >= style.breakpoints.md && screenWidth < style.breakpoints.lg,
        lgUp: screenWidth >= style.breakpoints.lg,
        lgDown: screenWidth < style.breakpoints.lg,
        lg:
            screenWidth >= style.breakpoints.lg && screenWidth < style.breakpoints.xl,
        xlUp: screenWidth >= style.breakpoints.xl,
        xlDown: screenWidth < style.breakpoints.xl,
        xl:
            screenWidth >= style.breakpoints.lg &&
            screenWidth < style.breakpoints.xxl,
        xxlUp: screenWidth >= style.breakpoints.xxl,
        xxlDown: screenWidth < style.breakpoints.xxl,
        xxl: screenWidth >= style.breakpoints.xxl,
    }), [screenWidth])
    return <ScreenBreakpointContext.Provider value={{ screen }}>{children}</ScreenBreakpointContext.Provider>
}

export default ScreenBreakpointProvider