import React from 'react';

const PersonalBrandingIcon = ({ small }) => {
    return small ?
        <svg width="35" height="29" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5013 7.92798C15.1189 7.92798 13.1807 9.71874 13.1807 11.9199C13.1807 14.1211 15.119 15.9119 17.5013 15.9119C19.8839 15.9119 21.8221 14.121 21.8221 11.9199C21.8221 9.71868 19.8839 7.92798 17.5013 7.92798Z" fill="#359E8C" />
            <path d="M26.1267 27.0084C25.8324 25.1085 24.8053 23.3633 23.2344 22.0942C21.6478 20.8124 19.6121 20.1064 17.5021 20.1064C15.3922 20.1064 13.3564 20.8124 11.7698 22.0942C10.1989 23.3633 9.17177 25.1085 8.87753 27.0084C8.83542 27.2804 8.92313 27.5558 9.1179 27.7633C9.31274 27.9708 9.59536 28.0901 9.89268 28.0901H25.1115C25.4088 28.0901 25.6915 27.9708 25.8863 27.7633C26.0811 27.5558 26.1689 27.2803 26.1267 27.0084Z" fill="#01476A" />
            <path d="M10.5115 19.3259C10.249 19.3259 9.98657 19.2334 9.78633 19.0484C5.53216 15.1177 5.53216 8.72203 9.78633 4.79139C10.1867 4.42142 10.8361 4.42142 11.2366 4.79139C11.637 5.16135 11.637 5.76131 11.2366 6.13133C7.78206 9.32318 7.78206 14.5166 11.2366 17.7085C11.637 18.0785 11.637 18.6784 11.2366 19.0484C11.0364 19.2334 10.7739 19.3259 10.5115 19.3259Z" fill="#FFD97D" />
            <path d="M24.4893 19.3259C24.2269 19.3259 23.9644 19.2334 23.7642 19.0484C23.3637 18.6784 23.3637 18.0785 23.7642 17.7084C27.2187 14.5166 27.2187 9.32308 23.7642 6.13132C23.3637 5.76136 23.3637 5.16141 23.7642 4.79139C24.1646 4.42143 24.814 4.42143 25.2145 4.79139C29.4687 8.72206 29.4687 15.1176 25.2145 19.0484C25.0142 19.2334 24.7518 19.3259 24.4893 19.3259Z" fill="#FFA538" />
            <path d="M5.85121 23.4238C5.58876 23.4238 5.3263 23.3313 5.12613 23.1463C1.82049 20.0921 6.83648e-05 16.0313 0 11.7119C0 7.39259 1.82042 3.33178 5.12593 0.277476C5.52634 -0.0924921 6.17567 -0.0924921 6.57615 0.277476C6.97663 0.647444 6.97663 1.24734 6.57615 1.61743C0.552115 7.18357 0.552183 16.2403 6.57629 21.8063C6.97677 22.1763 6.97677 22.7762 6.57629 23.1463C6.37611 23.3313 6.11366 23.4238 5.85121 23.4238Z" fill="#FFD97D" />
            <path d="M29.1485 23.6313C28.886 23.6313 28.6236 23.5388 28.4234 23.3538C28.0229 22.9839 28.0229 22.3839 28.4234 22.0139C34.4475 16.448 34.4475 7.3917 28.4234 1.82586C28.0229 1.45591 28.0229 0.855977 28.4234 0.485961C28.8238 0.116008 29.4732 0.116008 29.8736 0.485961C33.1793 3.54014 34.9997 7.60078 34.9997 11.9199C34.9997 16.2391 33.1792 20.2998 29.8736 23.3539C29.6734 23.5388 29.4109 23.6313 29.1485 23.6313Z" fill="#FFA538" />
            <path d="M21.8206 11.9199C21.8206 9.71873 19.8824 7.92798 17.5 7.92798V15.9119C19.8824 15.9117 21.8206 14.1209 21.8206 11.9199Z" fill="#0E826D" />
            <path d="M26.1245 27.0085C25.8302 25.1086 24.8031 23.3633 23.2322 22.0942C21.6456 20.8124 19.6099 20.1064 17.5 20.1064V28.0901H25.1094C25.4067 28.0901 25.6894 27.9708 25.8842 27.7633C26.079 27.5559 26.1667 27.2804 26.1245 27.0085Z" fill="#013550" />
        </svg>
        :
        <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.5008 23.6877C33.2598 23.6877 28.9961 27.9515 28.9961 33.1923C28.9961 38.4333 33.26 42.697 38.5008 42.697C43.7419 42.697 48.0056 38.4331 48.0056 33.1923C48.0056 27.9513 43.7419 23.6877 38.5008 23.6877Z" fill="#359E8C" />
            <path d="M57.4719 69.1166C56.8245 64.5928 54.565 60.4374 51.1093 57.4157C47.619 54.3637 43.1407 52.6828 38.4992 52.6828C33.8577 52.6828 29.3794 54.3637 25.8891 57.4157C22.4334 60.4374 20.1739 64.5928 19.5267 69.1166C19.434 69.7642 19.627 70.4199 20.0554 70.9141C20.484 71.4081 21.1058 71.692 21.7598 71.692H55.2386C55.8926 71.692 56.5145 71.4081 56.943 70.9139C57.3716 70.4199 57.5647 69.764 57.4719 69.1166Z" fill="#01476A" />
            <path d="M23.1237 50.8248C22.5464 50.8248 21.969 50.6045 21.5285 50.1641C12.1702 40.8056 12.1702 25.5783 21.5285 16.2199C22.4094 15.3391 23.8379 15.3391 24.7188 16.2199C25.5998 17.1007 25.5998 18.5291 24.7188 19.4101C17.1195 27.0095 17.1195 39.3745 24.7188 46.9739C25.5998 47.8547 25.5998 49.2831 24.7188 50.1641C24.2784 50.6045 23.7011 50.8248 23.1237 50.8248Z" fill="#FFD97D" />
            <path d="M53.8769 50.8249C53.2995 50.8249 52.7222 50.6046 52.2818 50.1641C51.4008 49.2833 51.4008 47.8548 52.2818 46.9739C59.8811 39.3745 59.8811 27.0092 52.2818 19.4099C51.4008 18.5291 51.4008 17.1007 52.2818 16.2197C53.1627 15.3389 54.5911 15.3389 55.4721 16.2197C64.8306 25.5782 64.8306 40.8054 55.4721 50.1641C55.0316 50.6046 54.4542 50.8249 53.8769 50.8249Z" fill="#FFA538" />
            <path d="M12.8716 61.0763C12.2943 61.0763 11.7169 60.856 11.2766 60.4155C4.00475 53.144 0.000150391 43.4758 0 33.1921C0 22.9084 4.0046 13.2402 11.2761 5.96837C12.157 5.08753 13.5854 5.08753 14.4664 5.96837C15.3474 6.84921 15.3474 8.27747 14.4664 9.1586C1.21456 22.4107 1.21471 43.9734 14.4667 57.2253C15.3477 58.1061 15.3477 59.5345 14.4667 60.4155C14.0263 60.856 13.449 61.0763 12.8716 61.0763Z" fill="#FFD97D" />
            <path d="M64.1288 61.0763C63.5515 61.0763 62.9741 60.856 62.5338 60.4155C61.6528 59.5347 61.6528 58.1062 62.5338 57.2253C75.7858 43.9731 75.7858 22.4106 62.5338 9.1586C61.6528 8.27777 61.6528 6.84936 62.5338 5.96837C63.4146 5.08753 64.843 5.08753 65.724 5.96837C72.9959 13.2402 77.0005 22.9084 77.0003 33.1921C77.0003 43.4758 72.9956 53.144 65.724 60.4156C65.2835 60.856 64.7062 61.0763 64.1288 61.0763Z" fill="#FFA538" />
            <path d="M48.0047 33.1923C48.0047 27.9515 43.7408 23.6877 38.5 23.6877V42.6971C43.741 42.6968 48.0047 38.4329 48.0047 33.1923Z" fill="#0E826D" />
            <path d="M57.4725 69.1166C56.8251 64.5928 54.5656 60.4374 51.1099 57.4157C47.6197 54.3637 43.1415 52.6828 38.5 52.6828V71.6919H55.2394C55.8934 71.6919 56.5153 71.4079 56.9438 70.9138C57.3722 70.4199 57.5653 69.764 57.4725 69.1166Z" fill="#013550" />
        </svg>
}

export default PersonalBrandingIcon