import { objectIsEmpty } from './helper'
import queryString from 'query-string'

const setCourseConfig = () => {
    const params = queryString.parse(global.location.search)
    const isEmpty = objectIsEmpty(params)

    // course id cookie
    document.cookie = "__c_name=DM;samesite=lax;domain=careerninja.in;secure"

    if (!isEmpty && (params.utm_campaign || params.ref)) {
        let data = {}
        if (params.utm_source)
            data['source'] = params.utm_source
        if (params.utm_medium)
            data['medium'] = params.utm_medium
        if (params.utm_campaign)
            data['campaign'] = params.utm_campaign.toString().toLowerCase()
        if (params.ref) {
            data['medium'] = 'REFERRAL'
            document.cookie = `__u_ref=${params.ref};samesite=lax;domain=careerninja.in;secure`
        }

        document.cookie = `__u_analytics=${JSON.stringify(data)};samesite=lax;domain=careerninja.in;secure`
    }
}

export default setCourseConfig
