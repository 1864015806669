import React from "react";

const FacebookIcon = ({ small }) =>
  small ? (
    <svg
      width="4"
      height="8"
      viewBox="0 0 4 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46433 7.42746V4.2113H3.54556L3.7119 2.95212H2.46433V2.15028C2.46433 1.78686 2.56578 1.53901 3.08729 1.53901H3.74767V0.416193C3.42615 0.381593 3.1029 0.364881 2.77958 0.366293C1.82144 0.366293 1.16522 0.948454 1.16522 2.02553V2.95204H0.0839844V4.21122H1.16522V7.42746H2.46433Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="15"
      height="27"
      viewBox="0 0 15 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.10169 27V14.7024H13.236L13.872 9.88771H9.10169V6.82171C9.10169 5.43211 9.48959 4.48441 11.4837 4.48441H14.0088V0.191116C12.7794 0.0588156 11.5434 -0.00508436 10.3071 0.000315637C6.6435 0.000315637 4.1343 2.22631 4.1343 6.34471V9.88741H0V14.7021H4.1343V27H9.10169Z"
        fill="white"
      />
    </svg>
  );
export default FacebookIcon;
