import React from 'react';

const GrowIcon = ({ small }) => {
    return small ?
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.0683 6.51412C25.826 6.98627 25.3149 7.27947 24.7343 7.27947H23.4929V24.0627C23.4929 25.0662 22.5808 25.8826 21.4598 25.8826H19.2007C18.0796 25.8826 17.1675 25.0662 17.1675 24.0627V7.27947H15.9261C15.3455 7.27947 14.8344 6.98627 14.5921 6.51412C14.3492 6.04247 14.4334 5.50409 14.8112 5.10979C14.8152 5.10524 14.8197 5.10069 14.8242 5.09614L19.657 0.268936C19.8287 0.0980707 20.0732 0 20.3302 0C20.5872 0 20.8317 0.0980707 21.0034 0.268936L25.8362 5.09614C25.8407 5.10069 25.8452 5.10524 25.8492 5.10979C26.227 5.50409 26.3112 6.04247 26.0683 6.51412Z" fill="#086788" />
            <path d="M26.0692 6.51396C25.8269 6.9861 25.3157 7.2793 24.7352 7.2793H24.4432C24.5533 7.17567 24.6454 7.05435 24.7154 6.91837C24.9582 6.44673 24.8741 5.90836 24.4963 5.51407C24.4923 5.50952 24.4878 5.50497 24.4833 5.50042L19.6505 0.673335C19.5833 0.606608 19.5048 0.550497 19.4189 0.507529L19.6578 0.26893C19.8295 0.0980683 20.0741 0 20.3311 0C20.588 0 20.8326 0.0980683 21.0043 0.26893L25.837 5.09601C25.8415 5.10056 25.8461 5.10511 25.85 5.10966C26.2279 5.50396 26.312 6.04232 26.0692 6.51396Z" fill="#234454" />
            <path d="M14.9085 11.5261V24.0625C14.9085 25.0659 13.9964 25.8823 12.8753 25.8823H10.6162C9.49512 25.8823 8.58301 25.0659 8.58301 24.0625V11.5261C8.58301 10.5227 9.49512 9.7063 10.6162 9.7063H12.8753C13.9964 9.7063 14.9085 10.5227 14.9085 11.5261Z" fill="#359E8C" />
            <path d="M6.32546 15.5699V24.0627C6.32546 25.0661 5.41335 25.8826 4.29228 25.8826H2.03318C0.912109 25.8826 0 25.0661 0 24.0627V15.5699C0 14.5664 0.912109 13.75 2.03318 13.75H4.29228C5.41335 13.75 6.32546 14.5664 6.32546 15.5699Z" fill="#EFA000" />
            <path d="M23.495 7.27979V24.0625C23.495 25.0676 22.5847 25.8823 21.4618 25.8823H19.6562C20.7773 25.8823 21.6894 25.0659 21.6894 24.0625V7.78529C21.6894 7.5061 21.9423 7.27979 22.2542 7.27979H23.495Z" fill="#234454" />
            <path d="M14.9108 11.5261V24.0625C14.9108 25.0659 13.9987 25.8823 12.8776 25.8823H11.0703C12.1914 25.8823 13.1035 25.0659 13.1035 24.0625V11.5261C13.1035 10.5227 12.1914 9.7063 11.0703 9.7063H12.8776C13.9987 9.7063 14.9108 10.5227 14.9108 11.5261Z" fill="#267567" />
            <path d="M6.32483 15.5699V24.0627C6.32483 25.0661 5.41272 25.8826 4.29165 25.8826H2.48438C3.60545 25.8826 4.51756 25.0661 4.51756 24.0627V15.5699C4.51756 14.5664 3.60545 13.75 2.48438 13.75H4.29165C5.41272 13.75 6.32483 14.5664 6.32483 15.5699Z" fill="#D5840B" />
        </svg>
        :
        <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M67.3368 17.8693C66.7419 19.1645 65.487 19.9688 64.0614 19.9688H61.0134V66.0078C61.0134 68.7605 58.7738 71 56.0212 71H50.4743C47.7217 71 45.4821 68.7605 45.4821 66.0078V19.9688H42.4341C41.0086 19.9688 39.7536 19.1645 39.1587 17.8693C38.5624 16.5754 38.769 15.0986 39.6968 14.017C39.7065 14.0045 39.7176 13.992 39.7287 13.9795L51.5948 0.737734C52.0164 0.269023 52.6168 0 53.2478 0C53.8787 0 54.4792 0.269023 54.9007 0.737734L66.7669 13.9795C66.778 13.992 66.7891 14.0045 66.7988 14.017C67.7265 15.0986 67.9331 16.5754 67.3368 17.8693Z" fill="#086788" />
                <path d="M67.3364 17.8693C66.7415 19.1645 65.4865 19.9688 64.061 19.9688H63.3441C63.6145 19.6845 63.8405 19.3517 64.0125 18.9786C64.6088 17.6848 64.4021 16.208 63.4744 15.1263C63.4647 15.1138 63.4536 15.1014 63.4425 15.0889L51.5764 1.84711C51.4113 1.66406 51.2186 1.51014 51.0078 1.39227L51.5944 0.737734C52.016 0.269023 52.6164 0 53.2474 0C53.8783 0 54.4788 0.269023 54.9003 0.737734L66.7665 13.9795C66.7776 13.992 66.7887 14.0045 66.7984 14.017C67.7261 15.0986 67.9327 16.5754 67.3364 17.8693Z" fill="#234454" />
                <path d="M39.9355 31.6172V66.0078C39.9355 68.7604 37.696 71 34.9434 71H29.3965C26.6438 71 24.4043 68.7604 24.4043 66.0078V31.6172C24.4043 28.8646 26.6438 26.625 29.3965 26.625H34.9434C37.696 26.625 39.9355 28.8646 39.9355 31.6172Z" fill="#359E8C" />
                <path d="M18.8574 42.7109V66.0078C18.8574 68.7604 16.6179 71 13.8652 71H8.31836C5.56572 71 3.32617 68.7604 3.32617 66.0078V42.7109C3.32617 39.9583 5.56572 37.7188 8.31836 37.7188H13.8652C16.6179 37.7188 18.8574 39.9583 18.8574 42.7109Z" fill="#EFA000" />
                <path d="M61.0134 19.9688V66.0078C61.0134 68.7649 58.7783 71 56.0212 71H51.5879C54.3405 71 56.5801 68.7604 56.5801 66.0078V21.3555C56.5801 20.5896 57.2009 19.9688 57.9668 19.9688H61.0134Z" fill="#234454" />
                <path d="M39.9355 31.6172V66.0078C39.9355 68.7604 37.696 71 34.9434 71H30.5059C33.2585 71 35.498 68.7604 35.498 66.0078V31.6172C35.498 28.8646 33.2585 26.625 30.5059 26.625H34.9434C37.696 26.625 39.9355 28.8646 39.9355 31.6172Z" fill="#267567" />
                <path d="M18.8574 42.7109V66.0078C18.8574 68.7604 16.6179 71 13.8652 71H9.42773C12.1804 71 14.4199 68.7604 14.4199 66.0078V42.7109C14.4199 39.9583 12.1804 37.7188 9.42773 37.7188H13.8652C16.6179 37.7188 18.8574 39.9583 18.8574 42.7109Z" fill="#D5840B" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="71" height="71" fill="white" />
                </clipPath>
            </defs>
        </svg>
}

export default GrowIcon