import axios from 'axios';

const download_api_url = `${process.env.REACT_APP_API_URL}/notifications/brochure-download/notification`

const downloadBrochureTriggers = async (data) => {
    try {
        await axios.post(download_api_url, data);
        console.log("Success");
    } catch (error) {
        console.log(error.response);
    }
}

export default downloadBrochureTriggers;