import { React, useCallback, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import image1 from "../../assets/image1.png";
import Options from "./Options";
import GetStarted from "./GetStarted";
import Companies from "./Companies/Companies";
import classes from "./LandingPage.module.css";
import clsx from "clsx";
import { SECTION1_HEADINGS } from "../../constants";
import vectorImage from "../../assets/section_wave.png";

const words = [
  {
    word1: "Dream",
    word2: "Job Today!",
  },
  {
    word1: "First",
    word2: "Internship Today!",
  },
  {
    word1: "Freelance",
    word2: "Career Today!",
  },
  { word1: "Business", word2: "Growth Today!" },
];

const LandingPage = () => {
  const [newIndex, setNewIndex] = useState(0);
  const shuffle = useCallback(() => {
    setNewIndex((state) => (state + 1) % words.length);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 2000);
    return () => clearInterval(intervalID);
  }, [shuffle]);
  return (
    <div className={classes["section1-wave"]}>
      <div className="blue-gradient">
        <Container
          fluid
          className="pt-3 pt-lg-5 p-3 px-lg-5 pb-0 d-lg-flex justify-content-lg-around"
        >
          <Row>
            <Col xs={12} lg={8} className="pt-2 pt-lg-5 pb-0">
              <div className="d-flex my-0 py-0">
                <span
                  className={clsx(
                    classes["background-line"],
                    classes["hero-text"],
                    "d-flex",
                    "my-0 py-0"
                  )}
                >
                  <span>{SECTION1_HEADINGS.heroText1}</span>
                  {words.map((word, index) => (
                    <div
                      className={clsx(
                        "animate__animated animate__bounceIn",
                        "my-0 py-0",
                        index === newIndex ? "d-flex" : "d-none"
                      )}
                      key={index}
                    >
                      <span className={clsx(classes["hero-text"])} key={index}>
                        &nbsp;{word.word1}
                      </span>
                    </div>
                  ))}
                </span>
              </div>
              <div className="d-flex">
                {words.map((word, index) => (
                  <div
                    className={clsx(
                      "animate__animated animate__bounceIn",
                      index === newIndex ? "d-flex" : "d-none"
                    )}
                    key={index}
                  >
                    <span className={clsx(classes["hero-text"])} key={index}>
                      {word.word2}
                    </span>
                  </div>
                ))}
              </div>
              <br />
              <Row className="pt-2">
                <Col xs={10} lg={9}>
                  <p className={clsx(classes["landing-page-paragraph"])}>
                    {SECTION1_HEADINGS.paragraph}
                  </p>
                </Col>
              </Row>
              <div className="d-none d-lg-block">
                <Options />
                <GetStarted />
              </div>
            </Col>
            <Col
              xs={{ span: 12 }}
              md={{ offset: 6, span: 6 }}
              lg={{ offset: 0, span: 4 }}
            >
              <div
                className={clsx(
                  classes["comment"],
                  "p-2 pt-3",
                  classes["top-bubble"]
                )}
              >
                <div className={classes["green-circle"]}></div>
                30,000 job vacancies
                <br /> in Digital Marketing
              </div>
              <img src={image1} alt="Image1" />
              <div
                className={clsx(
                  classes["comment"],
                  "p-2 pt-3",
                  classes["bottom-bubble"]
                )}
              >
                <div className={classes["green-circle"]}></div>
                Get Started, Just Like 703 People did in the Last 7 Days!
              </div>
            </Col>
          </Row>
          <div className="d-block d-lg-none">
            <Options />
            <GetStarted />
          </div>
        </Container>
        <img src={vectorImage} className="img-fluid w-100" alt="wave" />
      </div>
      <Companies></Companies>
    </div>
  );
};

export default LandingPage;
