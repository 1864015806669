const style = {
  colors: {
    deepBlue: '#022238',
    deepGreen: '#1c8976',
    lightGray: '#EFEFEF',
    lightGreen: '#E1FFE8',
    orange: '#ffb800',
    dark: '#021728',
    white: '#FFFFFF',
    deepYellow: '#FFBF54',
    lightYellow: '#FFEF9C',
    gray: '#D6D6D4',
    veryLightGray: '#F2F2F2',
    textBlue: '#015078',
    textOrange: '#FFB32B',
    textBlack: '#022B4D',
    bgGreen: 'rgba(225, 245, 242, 0.4)',
    checkPointGreen: '#00D559',
    checkPointDark: '#012D54',
    checkPointTextDark: '#014C66',
    checkPointTextPoint: '#071827',
    footerBlue: '#011627',
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  tableBorderRadius: '20px',
};

export default style;
