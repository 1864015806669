import React from "react";

const WhatsappIcon = ({ small }) =>
  small ? (
    <svg
      width="12"
      height="12"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.186276 4.87853C0.186004 5.58974 0.37177 6.28403 0.725023 6.89598L0.152344 8.98691L2.29231 8.42581C2.88191 8.74731 3.54571 8.91678 4.22127 8.91706H4.22299C6.44765 8.91706 8.25871 7.10654 8.25971 4.8817C8.26007 3.80339 7.84049 2.7895 7.07843 2.02671C6.31618 1.26402 5.30275 0.843714 4.22299 0.843262C1.99788 0.843262 0.18709 2.65341 0.186185 4.87844L0.186276 4.87853ZM1.46048 6.79057L1.38059 6.66371C1.04471 6.12967 0.867446 5.51247 0.867627 4.8788C0.868442 3.02947 2.37348 1.52479 4.22417 1.52479C5.12042 1.52516 5.96266 1.87452 6.59623 2.50846C7.22972 3.14239 7.57835 3.98517 7.57808 4.88133C7.57727 6.73076 6.07214 8.23543 4.2229 8.23543H4.22154C3.61937 8.23507 3.02886 8.07346 2.51391 7.76781L2.3913 7.69515L1.12135 8.02813L1.46048 6.79057ZM4.22299 8.91706C4.2229 8.91706 4.2229 8.91706 4.22299 8.91706V8.91706Z"
        fill="white"
      />
      <path
        d="M3.21506 3.19079C3.1395 3.02285 3.05997 3.01941 2.98812 3.01652C2.92931 3.01398 2.86199 3.01416 2.79485 3.01416C2.72752 3.01416 2.61831 3.03941 2.52592 3.1403C2.43345 3.24128 2.17285 3.48523 2.17285 3.98145C2.17285 4.47776 2.53434 4.95724 2.58465 5.02465C2.63514 5.09188 3.28247 6.14277 4.30757 6.54706C5.15958 6.88303 5.33304 6.81625 5.5179 6.79942C5.70285 6.78259 6.11465 6.55547 6.19871 6.31994C6.28277 6.08441 6.28277 5.88254 6.25762 5.84046C6.23237 5.79839 6.16514 5.77314 6.06425 5.72274C5.96336 5.67225 5.4675 5.4283 5.37502 5.39464C5.28255 5.36098 5.21532 5.34424 5.148 5.44522C5.08077 5.54611 4.88758 5.77323 4.82877 5.84046C4.76995 5.90787 4.71105 5.91629 4.61025 5.8658C4.50935 5.81522 4.18451 5.70881 3.79914 5.36523C3.49927 5.09785 3.29686 4.76776 3.23804 4.66678C3.17923 4.56589 3.2318 4.51133 3.28229 4.46102C3.32762 4.41587 3.38318 4.3433 3.43367 4.28439C3.48398 4.22549 3.50081 4.18341 3.53438 4.11618C3.56804 4.04886 3.55121 3.98995 3.52596 3.93946C3.50072 3.88897 3.30473 3.39022 3.21506 3.19079Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.129745 15.4296C0.128707 18.149 0.839016 20.8038 2.18975 23.1437L0 31.1387L8.18257 28.9933C10.437 30.2226 12.9752 30.8706 15.5583 30.8716H15.5649C24.0713 30.8716 30.9962 23.9488 31 15.4417C31.0014 11.3186 29.397 7.44181 26.4832 4.52515C23.5686 1.60883 19.6935 0.00172993 15.5649 0C7.05673 0 0.132859 6.92145 0.129399 15.4292L0.129745 15.4296ZM5.00192 22.7406L4.69641 22.2555C3.41211 20.2135 2.73433 17.8536 2.73502 15.4306C2.73813 8.35937 8.49292 2.60597 15.5694 2.60597C18.9964 2.60735 22.2168 3.9432 24.6394 6.36718C27.0616 8.79116 28.3947 12.0137 28.3937 15.4403C28.3906 22.5119 22.6354 28.2653 15.5645 28.2653H15.5593C13.2568 28.2639 10.9989 27.646 9.02989 26.4773L8.56108 26.1994L3.70516 27.4727L5.00192 22.7406ZM15.5649 30.8716C15.5645 30.8716 15.5645 30.8716 15.5649 30.8716V30.8716Z"
        fill="white"
      />
      <path
        d="M11.7058 8.97811C11.4169 8.33596 11.1127 8.32281 10.838 8.31174C10.6131 8.30205 10.3557 8.30275 10.099 8.30275C9.8416 8.30275 9.42399 8.39928 9.07074 8.78505C8.71714 9.17117 7.7207 10.1039 7.7207 12.0013C7.7207 13.8991 9.10292 15.7324 9.29528 15.9902C9.48835 16.2473 11.9635 20.2656 15.8832 21.8114C19.141 23.0961 19.8043 22.8407 20.5111 22.7764C21.2183 22.712 22.7929 21.8436 23.1143 20.943C23.4357 20.0424 23.4357 19.2705 23.3396 19.1096C23.243 18.9487 22.986 18.8522 22.6002 18.6595C22.2144 18.4664 20.3184 17.5337 19.9648 17.4049C19.6112 17.2762 19.3541 17.2122 19.0967 17.5984C18.8397 17.9841 18.101 18.8526 17.8761 19.1096C17.6512 19.3674 17.426 19.3996 17.0405 19.2065C16.6548 19.0131 15.4127 18.6062 13.9391 17.2925C12.7925 16.2701 12.0185 15.008 11.7936 14.6218C11.5688 14.2361 11.7698 14.0274 11.9628 13.8351C12.1362 13.6624 12.3486 13.3849 12.5417 13.1597C12.734 12.9345 12.7984 12.7736 12.9268 12.5165C13.0555 12.2591 12.9911 12.0339 12.8946 11.8408C12.798 11.6477 12.0486 9.74066 11.7058 8.97811Z"
        fill="white"
      />
    </svg>
  );

export default WhatsappIcon;
