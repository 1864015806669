import React, { useContext } from "react";
import PageHeading from "../../styleGuide/PageHeading";
import { Container, Row, Col } from "react-bootstrap";
import style from "../../styleConstants";
import ScreenBreakpointContext from "../../styleGuide/ScreenBreakpointProvider/Provider";
import { OUR_JOURNEY_TEXT } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import classes from "./OurJourney.module.css";
import clsx from "clsx";

const OurJourney = () => {
  const { screen } = useContext(ScreenBreakpointContext);

  const bigTextStyle = {
    color: style.colors.textOrange,
    fontSize: screen.lgUp ? "3.5rem" : "1.5rem",
    fontWeight: "700",
    fontFamily: "Montserrat",
  };
  const iconStyle = {
    fontSize: screen.lgUp ? "2rem" : "0.6rem",
  };
  const subText = {
    color: style.colors.white,
    fontSize: screen.lgUp ? "2rem" : "0.6rem",
    fontWeight: "600",
  };
  return (
    <div className={classes["journey-bg-image"]}>
      <div className="pb-3">
        <Container fluid>
          <Row className={classes["journey-bg-image-row"]}>
            <Col xs={12}>
              <PageHeading heading="Our Journey So Far!" />
            </Col>
            <Col className="mt-3" xs={12}>
              <Row className="m-1 text-center align-items-end">
                <Col>
                  <div style={bigTextStyle}>{OUR_JOURNEY_TEXT.learners}</div>
                  <div style={subText}>Learners</div>
                </Col>
                <Col>
                  <div style={bigTextStyle}>
                    {OUR_JOURNEY_TEXT.goalsAchieved}
                  </div>
                  <div style={subText}>Goals Achieved</div>
                </Col>
                <Col>
                  <div style={iconStyle}>
                    <FontAwesomeIcon
                      icon={faStar}
                      color={style.colors.textOrange}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      color={style.colors.textOrange}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      color={style.colors.textOrange}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      color={style.colors.textOrange}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      color={style.colors.textOrange}
                    />
                  </div>
                  <div style={bigTextStyle}>
                    {OUR_JOURNEY_TEXT.googleRating}/5
                  </div>
                  <div style={subText}>Google Rating</div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="align-items-end pt-lg-5 mt-2 mt-lg-5">
              <div
                className={clsx(
                  "text-center",
                  classes["journey-text"]
                )}
              >
                {OUR_JOURNEY_TEXT.quote.map((text, index) => (
                  <div key={index}>{text}</div>
                ))}
              </div>
              <div
                className={clsx(
                  classes["journey-text-author"],
                  "text-center mt-1 mt-lg-3"
                )}
              >
                <div>
                  <span>-{OUR_JOURNEY_TEXT.author},</span>
                  <br />
                  <span>{OUR_JOURNEY_TEXT.designation}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OurJourney;
