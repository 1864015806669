import { React } from "react";
import Box from "../../../styleGuide/layout/Box";
import { BOX_BG_COLOR } from "../../../styleGuide/layout/Box/constants";
import { Container, Row, Col } from "react-bootstrap";
import {
  LEARNERS_WORK_AT_LOGOS,
  PARTNERED_WITH_LOGOS,
} from "../../../constants";
import classes from "./Companies.module.css";
import clsx from "clsx";
import Marquee from "react-fast-marquee";

const Companies = () => {
  return (
    <Box bgColor={BOX_BG_COLOR.lightGray}>
      <Container fluid>
        <Row className="m-0 pb-5">
          <Col lg={7} className={clsx("mb-3 mb-lg-0", classes["right-border"])}>
            <div
              className={clsx(
                "text-lg-center mb-lg-5 mb-3",
                classes["learners-heading"]
              )}
            >
              Our Learners Work At
            </div>
            <div>
              <Marquee
                speed={30}
                className={clsx(classes["marquee-div"], "py-2 py-lg-0")}
                gradient={false}
              >
                <div className={"d-flex align-items-center"}>
                  {LEARNERS_WORK_AT_LOGOS.map((element, index) => (
                    <img
                      src={element.image}
                      className={clsx(classes["fixed-width"], "mx-3")}
                      alt="logo"
                      key={index}
                    />
                  ))}
                  {LEARNERS_WORK_AT_LOGOS.map((element, index) => (
                    <img
                      src={element.image}
                      className={clsx(classes["fixed-width"], "mx-3")}
                      alt="logo"
                      key={index}
                    />
                  ))}
                </div>
              </Marquee>
            </div>
          </Col>
          <Col xs={11} lg={5}>
            <div
              className={clsx(
                "text-lg-center mb-lg-5 mb-3",
                classes["learners-heading"]
              )}
            >
              In Partnership With
            </div>
            <div
              className={clsx(
                "d-flex justify-content-around align-items-center py-lg-3 mx-lg-5"
              )}
            >
              {PARTNERED_WITH_LOGOS.map((element, index) => (
                <div className="mx-3 mx-lg-0" key={index}>
                  <img
                    src={element.image}
                    className={clsx("img-fluid", classes["mobile-width"])}
                    alt="logo"
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

export default Companies;
