import { React } from "react";
import { Row, Col } from "react-bootstrap";
import greentick from "../../../assets/green-tick.svg";
import { SECTION1_OPTIONS } from "../../../constants";
import classes from "../LandingPage.module.css";
import clsx from "clsx";

const Options = () => {
  return (
    <Row className="pt-3">
      {SECTION1_OPTIONS.map((element, index) => (
        <Col xs={12} lg={6} key={index}>
          <img src={greentick} className="p-2" alt="green-tick" />
          <span className={clsx(classes["options-text"])}>{element}</span>
        </Col>
      ))}
    </Row>
  );
};

export default Options;
