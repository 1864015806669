import React, { useState, useCallback, useEffect } from "react";
import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import WhatMakesUsUnique from "./components/WhatMakeUsUnique/WhatMakeUsUnique";
import ScreenBreakpointProvider from "./styleGuide/ScreenBreakpointProvider";
import LandingPage from "./components/LandingPage/LandingPage";
import OurJourney from "./components/OurJourney";
import Footer from "./components/Footer/Footer";
import EMI from "./components/EMI/EMI";
import DownloadBrochure from "./components/WhatOurLearnersSay/DownloadBrochure";
import { Suspense } from "react";
import { Mixpanel } from "./helper/Mixpanel";
import setCourseConfig from './helper/CourseConfig'
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";


const CoachingSessions = React.lazy(() =>
  import("./components/CoachingSessions")
);
const FAQ = React.lazy(() => import("./components/FAQ/FAQ"));
const Advantage = React.lazy(() => import("./components/Advantage"));
const WhatWillYouBeLearning = React.lazy(() =>
  import("./components/WhatWillYouBeLearning/WhatWillYouBeLearning")
);
const WhatElseGet = React.lazy(() =>
  import("./components/WhatElseGet/WhatElseGet")
);
const ScopeInDM = React.lazy(() => import("./components/ScopeInDMv2"));
const WhatOurLearnersSay2 = React.lazy(() =>
  import("./components/WhatOurLearnersSay/WhatOurLearnersSay2")
);
const FeaturedIn = React.lazy(() =>
  import("./components/FeaturedIn/FeaturedIn")
);

const Loader = <div className="text-center text-dark p-5">Loading...</div>;

function App() {
  const [threshold1, setThreshold1] = useState(false);
  const [threshold2, setThreshold2] = useState(false);
  const [threshold3, setThreshold3] = useState(false);
  const [threshold4, setThreshold4] = useState(false);
  const updateScreenHeight = useCallback(() => {
    setThreshold1(threshold1 || window.pageYOffset > window.innerHeight);
    setThreshold2(threshold2 || window.pageYOffset > 2 * window.innerHeight);
    setThreshold3(threshold3 || window.pageYOffset > 3 * window.innerHeight);
    setThreshold4(threshold4 || window.pageYOffset > 3 * window.innerHeight);
  }, [
    setThreshold1,
    threshold1,
    setThreshold2,
    threshold2,
    setThreshold3,
    threshold3,
    setThreshold4,
    threshold4,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", updateScreenHeight);
    Mixpanel.track("Landing Page Visit");
    return () => window.removeEventListener("scroll", updateScreenHeight);
  });

  useEffect(() => {
    setCourseConfig()
  }, [])

  return (
    <>
      <Router>
        <ScreenBreakpointProvider>
          <Route exact path={["/", "/digital-marketing-course", `${process.env.REACT_APP_TEMP_CTA_PATH}`]}>
            <Navigation />
            <LandingPage />
            <WhatMakesUsUnique />
            <OurJourney />
            <div id="mentors">
              {threshold1 && (
                <Suspense fallback={Loader}>
                  <CoachingSessions />
                </Suspense>
              )}
            </div>
            <div id="benefits">
              {threshold1 && (
                <Suspense fallback={Loader}>
                  <WhatElseGet />
                </Suspense>
              )}
            </div>
            <div id="syllabus">
              {threshold2 && (
                <Suspense fallback={Loader}>
                  <WhatWillYouBeLearning />
                </Suspense>
              )}
            </div>
            <DownloadBrochure />
            <div id="whatOurLearnersSay">
              {threshold3 && (
                <Suspense fallback={Loader}>
                  <WhatOurLearnersSay2 />
                </Suspense>
              )}
            </div>
            {threshold3 && (
              <Suspense fallback={Loader}>
                <Advantage />
                <FeaturedIn />
              </Suspense>
            )}
            {threshold4 && (
              <Suspense fallback={Loader}>
                <ScopeInDM />
              </Suspense>
            )}
            <EMI />
            {threshold4 && (
              <Suspense fallback={Loader}>
                <FAQ />
              </Suspense>
            )}
            <Footer />
          </Route>

          <Route>
            {(window.location.pathname !== "/" && window.location.pathname !== "/digital-marketing-course" && window.location.pathname !== `${process.env.REACT_APP_TEMP_CTA_PATH}`) && <Redirect to="/" />}
          </Route>
        </ScreenBreakpointProvider>
      </Router>
    </>
  );
}

export default App;
