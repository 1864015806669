import React from 'react';

const LearnIcon = ({ small }) => {
    return small ?
        <svg width="37" height="32" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.18303 16.6875H3.42079C3.11757 16.688 2.85719 16.8748 2.79957 17.1331L0.0231061 29.5011C-0.0489351 29.8242 0.0494115 30.1583 0.291173 30.4118C0.532934 30.6653 0.892731 30.8116 1.27188 30.8105H14.945C14.945 31.4157 16.365 31.9109 18.1145 31.9109C19.8641 31.9109 21.284 31.4157 21.284 30.8105H34.9572C35.3363 30.8116 35.6961 30.6653 35.9379 30.4118C36.1796 30.1583 36.278 29.8242 36.2059 29.5011L33.4295 17.1331C33.3718 16.8748 33.1115 16.688 32.8082 16.6875H5.18303Z" fill="#015078" />
            <path d="M18.1145 18.2883V28.6096C17.1871 28.26 16.2206 27.9945 15.2303 27.8174C11.466 27.1071 7.55168 27.2799 3.88987 28.318C3.6794 28.3738 3.45069 28.3316 3.28452 28.2063C3.11836 28.0811 3.03812 27.8905 3.07215 27.7018L5.18302 16.6872L5.36051 15.7574C5.40594 15.5215 5.62059 15.3374 5.89299 15.3008C6.32404 15.2458 6.76776 15.1907 7.21149 15.1467C7.98609 16.4536 9.53709 17.2753 11.2304 17.2759C11.3445 17.2759 11.4523 17.2704 11.56 17.2649C11.9821 17.2363 12.3979 17.1586 12.7961 17.0338C12.7974 17.0455 12.8018 17.0568 12.8088 17.0669C13.2831 18.2199 14.5423 18.9889 15.9529 18.987C16.7472 18.9893 17.5154 18.7409 18.1145 18.2883Z" fill="#F5EFCA" />
            <path d="M32.3389 28.318C28.6771 27.2799 24.7628 27.1071 20.9985 27.8174C20.0082 27.9945 19.0417 28.26 18.1143 28.6096V18.2883C18.2476 18.1937 18.3706 18.0888 18.4819 17.9747C19.1153 18.1934 19.7908 18.3054 20.4724 18.3048H20.5104C20.5433 18.6982 20.7161 19.0731 21.0048 19.3777C21.6201 19.9056 22.3892 20.2783 23.2298 20.456C23.8398 20.625 24.3839 20.9373 24.8019 21.3583C25.1745 21.7589 25.8059 21.8999 26.3549 21.7049C26.8383 21.5237 27.1519 21.1111 27.1536 20.6541C27.1543 20.5345 27.1329 20.4156 27.0902 20.302C27.0268 20.1314 26.9571 19.9608 26.8747 19.7958C26.7158 19.4549 26.507 19.1332 26.2535 18.8385C27.5107 18.2484 28.3936 17.197 28.656 15.9776C28.675 15.9556 28.7003 15.9336 28.7193 15.9116C28.9363 15.6901 29.1136 15.4415 29.2455 15.1743C29.6131 15.2128 29.9745 15.2513 30.3358 15.3009C30.6082 15.3375 30.8228 15.5215 30.8683 15.7575L31.0458 16.6873L33.1566 27.7018C33.1907 27.8905 33.1104 28.0811 32.9443 28.2063C32.7781 28.3316 32.5494 28.3738 32.3389 28.318Z" fill="#F9EAB0" />
            <path d="M21.2843 30.8108C21.2843 31.416 19.8643 31.9111 18.1148 31.9111C16.3652 31.9111 14.9453 31.416 14.9453 30.8108L15.2306 27.8179C16.2209 27.995 17.1874 28.2605 18.1148 28.6101C19.0422 28.2605 20.0087 27.995 20.999 27.8179L21.2843 30.8108Z" fill="#103C53" />
            <path d="M29.5225 14.0243C29.5219 14.4202 29.4269 14.8117 29.2435 15.1742C29.1117 15.4415 28.9343 15.69 28.7174 15.9115C28.6984 15.9335 28.673 15.9555 28.654 15.9775C28.3916 17.197 27.5088 18.2484 26.2516 18.8386C26.505 19.1333 26.7138 19.4551 26.8728 19.796C26.9552 19.961 27.0249 20.1316 27.0883 20.3021C27.131 20.4158 27.1524 20.5347 27.1517 20.6543C27.15 21.1113 26.8363 21.5239 26.353 21.7052C25.8039 21.9001 25.1725 21.7592 24.7999 21.3585C24.3819 20.9375 23.8378 20.6252 23.2279 20.4562C22.3873 20.2785 21.6182 19.9057 21.0029 19.3778C20.7141 19.0732 20.5414 18.6983 20.5085 18.3049H20.4704C19.7888 18.3055 19.1133 18.1934 18.48 17.9748C18.3687 18.0888 18.2456 18.1938 18.1123 18.2884C17.5132 18.7411 16.745 18.9894 15.9507 18.9872C14.5401 18.9891 13.2809 18.22 12.8066 17.0669C12.7996 17.0569 12.7952 17.0456 12.7939 17.0339C12.3957 17.1587 11.9799 17.2364 11.5578 17.265C11.4501 17.2705 11.3423 17.276 11.2282 17.276C9.51496 17.275 7.94901 16.435 7.18175 15.1054C6.41449 13.7758 6.57725 12.1842 7.60232 10.9927C7.52607 10.813 7.48743 10.6229 7.48822 10.4315C7.4844 9.61836 8.1424 8.91597 9.06028 8.75335C9.0637 8.75036 9.06815 8.74843 9.07296 8.74785C9.06662 8.68182 9.06028 8.6158 9.06028 8.54427C9.05943 8.23912 9.13288 7.93725 9.27581 7.65844C9.68026 6.84653 10.6021 6.32103 11.6212 6.32145C12.0056 6.31986 12.3852 6.39517 12.7305 6.54153C13.4054 5.55535 14.6292 4.95102 15.9507 4.95144C16.8574 4.94858 17.7334 5.23663 18.4103 5.76024C18.7988 5.21619 19.5056 4.90679 20.2407 4.95899C20.9757 5.01119 21.6152 5.4162 21.8967 6.00783C22.0777 5.99028 22.2597 5.9811 22.4418 5.98032C24.8979 5.98385 26.9418 7.61923 27.1453 9.74372H27.1644C27.6866 9.74372 28.1874 9.92417 28.5561 10.2452C28.9248 10.5663 29.1311 11.0015 29.1294 11.4549C29.1282 11.7513 29.041 12.0427 28.8759 12.3022C29.2966 12.8021 29.523 13.4052 29.5225 14.0243Z" fill="#329B89" />
            <path d="M18.1144 1.65112C17.7643 1.65112 17.4805 1.40471 17.4805 1.10075V0.550374C17.4805 0.246411 17.7643 0 18.1144 0C18.4645 0 18.7483 0.246411 18.7483 0.550374V1.10075C18.7483 1.40471 18.4645 1.65112 18.1144 1.65112Z" fill="#F0C419" />
            <path d="M28.331 5.4375C28.0747 5.43745 27.8436 5.30339 27.7455 5.09782C27.6474 4.89224 27.7016 4.65562 27.8829 4.49825L28.5168 3.94802C28.6759 3.80499 28.9116 3.74762 29.1332 3.79798C29.3549 3.84833 29.5279 3.99857 29.586 4.19095C29.644 4.38332 29.5779 4.5879 29.4131 4.72605L28.7792 5.27628C28.6604 5.37948 28.4991 5.43747 28.331 5.4375Z" fill="#F0C419" />
            <path d="M7.89962 5.4375C7.73152 5.43747 7.57031 5.37948 7.45146 5.27628L6.81756 4.72605C6.65277 4.5879 6.58669 4.38332 6.6447 4.19095C6.70271 3.99857 6.87579 3.84833 7.09742 3.79798C7.31905 3.74762 7.55473 3.80499 7.71389 3.94802L8.34779 4.49825C8.52902 4.65562 8.58323 4.89224 8.48514 5.09782C8.38705 5.30339 8.15597 5.43745 7.89962 5.4375Z" fill="#F0C419" />
            <path d="M12.7773 3.12207C12.5209 3.12213 12.2898 2.98816 12.1916 2.78263L11.4639 1.2576C11.3713 1.07512 11.3998 0.863487 11.5382 0.704409C11.6767 0.54533 11.9035 0.463705 12.131 0.491051C12.3585 0.518397 12.5512 0.650435 12.6347 0.83618L13.363 2.36121C13.4276 2.49609 13.4278 2.64774 13.3635 2.78273C13.2991 2.91772 13.1756 3.02496 13.0201 3.08081C12.943 3.10808 12.8605 3.12209 12.7773 3.12207Z" fill="#F0C419" />
            <path d="M23.4505 3.12207C23.3672 3.12192 23.2848 3.10771 23.2077 3.08026C23.0523 3.0244 22.9289 2.91725 22.8646 2.78239C22.8003 2.64754 22.8003 2.49602 22.8648 2.36121L23.5931 0.83618C23.6766 0.650435 23.8692 0.518397 24.0968 0.491051C24.3243 0.463705 24.5511 0.54533 24.6895 0.704409C24.828 0.863487 24.8565 1.07512 24.7639 1.2576L24.0362 2.78263C23.938 2.98816 23.7069 3.12213 23.4505 3.12207Z" fill="#F0C419" />
            <path d="M30.9992 9.67309C30.696 9.67389 30.4347 9.48833 30.3755 9.23031C30.3164 8.97229 30.476 8.71403 30.7564 8.61407L32.5136 7.98251C32.7228 7.90733 32.9624 7.93475 33.142 8.05444C33.3217 8.17413 33.4141 8.3679 33.3845 8.56277C33.3549 8.75764 33.2077 8.92399 32.9985 8.99917L31.242 9.63073C31.165 9.65857 31.0825 9.67296 30.9992 9.67309Z" fill="#F0C419" />
            <path d="M5.22965 9.67309C5.1463 9.67296 5.0638 9.65857 4.98687 9.63073L3.23034 8.99917C3.02108 8.92399 2.87393 8.75764 2.84432 8.56277C2.81471 8.3679 2.90714 8.17413 3.08678 8.05444C3.26643 7.93475 3.50601 7.90733 3.71527 7.98251L5.47243 8.61407C5.75283 8.71403 5.91244 8.97229 5.85329 9.23031C5.79414 9.48833 5.53278 9.67389 5.22965 9.67309Z" fill="#F0C419" />
            <path d="M28.7211 15.9111C28.702 15.9331 28.6767 15.9551 28.6577 15.9771C28.546 16.507 28.3132 17.0121 27.9731 17.4627C27.6411 17.5444 27.2975 17.5852 26.9525 17.5837C26.3243 17.5821 25.7053 17.4521 25.1459 17.2041C24.881 17.0811 24.6324 16.9335 24.4042 16.7639C24.1514 16.8489 23.883 16.8936 23.6118 16.896C22.7745 16.8997 22.0076 16.49 21.6341 15.8396C21.5322 15.6636 21.5461 15.4539 21.6707 15.2893C21.7953 15.1247 22.0116 15.0304 22.2381 15.0417C22.4645 15.0531 22.6668 15.1685 22.7688 15.3444C22.894 15.553 23.1158 15.7053 23.3784 15.7631C23.6411 15.8209 23.9198 15.7787 24.1443 15.647C24.3979 15.4968 24.5497 15.2477 24.55 14.9813C24.5509 14.8537 24.5184 14.7276 24.4549 14.6126C24.3273 14.3466 24.4578 14.0401 24.7541 13.9101C25.0504 13.7801 25.4121 13.8705 25.5833 14.1174C25.7374 14.3857 25.8177 14.6814 25.8178 14.9813C25.8127 15.3657 25.6734 15.7398 25.4185 16.0541C25.5199 16.1147 25.6213 16.1752 25.7227 16.2247C26.1031 16.3946 26.5247 16.4833 26.9525 16.4833C27.6027 16.4864 28.2318 16.2829 28.7211 15.9111Z" fill="#177867" />
            <path d="M20.9666 13.7514C21.7878 13.2603 22.3525 12.5078 22.5381 11.6574C22.5852 11.4627 22.5077 11.261 22.3352 11.13C22.1627 10.999 21.9221 10.9591 21.706 11.0255C21.49 11.0919 21.3321 11.2544 21.2931 11.4505C21.1688 12.0179 20.7916 12.5199 20.2434 12.8474C19.1887 13.4639 17.7743 13.324 16.9065 12.5173C16.6704 12.2924 16.2689 12.2763 16.0099 12.4813C15.7508 12.6862 15.7322 13.0346 15.9684 13.2595C16.5061 13.7748 17.2168 14.1304 18 14.2762C17.6449 14.7094 17.0697 14.9662 16.4571 14.965C16.107 14.965 15.8232 15.2114 15.8232 15.5152C15.8232 15.8191 16.107 16.0654 16.4571 16.0654C17.7694 16.07 18.9468 15.3665 19.4104 14.301C19.9697 14.2203 20.5013 14.0325 20.9666 13.7514Z" fill="#177867" />
            <path d="M26.8759 19.7957C26.4099 20.0021 25.9093 20.1433 25.3926 20.214C25.359 20.2186 25.3251 20.2204 25.2911 20.2195C24.9573 20.2222 24.6783 19.9995 24.6532 19.7105C24.6281 19.4214 24.8655 19.1649 25.1961 19.1241C25.5636 19.0758 25.9205 18.9793 26.2547 18.8379C26.5081 19.1327 26.7169 19.4546 26.8759 19.7957Z" fill="#177867" />
            <path d="M12.7533 15.9554C12.7213 16.1098 12.7044 16.2662 12.7026 16.4231C12.7013 16.6295 12.7333 16.8351 12.7977 17.0338C12.3995 17.1586 11.9837 17.2363 11.5616 17.2649C11.4805 16.9954 11.4379 16.7182 11.4348 16.4396C10.3182 16.3339 9.47184 15.5163 9.46974 14.5414C9.46762 14.235 9.75063 13.9845 10.1036 13.9802C10.271 13.9794 10.4318 14.0363 10.5507 14.1385C10.6697 14.2406 10.7369 14.3797 10.7375 14.5249V14.5414C10.747 14.9762 11.1435 15.33 11.644 15.3502C11.7317 15.1021 11.8529 14.8638 12.0053 14.6405C12.0905 14.5143 12.2306 14.4233 12.3941 14.3881C12.5576 14.3529 12.7306 14.3764 12.8738 14.4534C13.1718 14.6133 13.2677 14.9509 13.0893 15.2127C12.9283 15.4414 12.8146 15.6928 12.7533 15.9554Z" fill="#177867" />
            <path d="M22.1769 19.1754C21.796 19.2812 21.4027 19.3496 21.0042 19.3792C20.7154 19.0742 20.5427 18.6989 20.5098 18.3051C20.5711 18.3061 20.6325 18.3043 20.6936 18.2996C21.07 18.2788 21.4424 18.2197 21.8029 18.1233C22.0215 18.0582 22.2636 18.1016 22.4347 18.2366C22.6058 18.3716 22.6788 18.5768 22.6252 18.7722C22.5715 18.9675 22.3997 19.122 22.1769 19.1754Z" fill="#177867" />
            <path d="M16.7207 9.35288C16.7207 9.04904 16.4369 8.80273 16.0868 8.80273C14.4771 8.80425 13.1726 9.93638 13.1709 11.3334C13.1709 11.6372 13.4547 11.8835 13.8048 11.8835C14.1549 11.8835 14.4387 11.6372 14.4387 11.3334C14.4387 10.954 14.6123 10.5902 14.9214 10.322C15.2305 10.0537 15.6497 9.90302 16.0868 9.90302C16.4369 9.90302 16.7207 9.65672 16.7207 9.35288Z" fill="#177867" />
            <path d="M8.44563 12.4465C8.33049 12.4466 8.21754 12.4192 8.11917 12.3672C7.59645 12.0935 7.20097 11.6692 7.00161 11.1684C6.90518 10.9303 6.85609 10.6797 6.85645 10.4273C6.85644 9.18049 8.01882 8.16869 9.45542 8.16506C10.2786 8.16232 11.0536 8.5016 11.5403 9.07779C11.6754 9.23562 11.7032 9.44402 11.6133 9.62447C11.5233 9.80493 11.3293 9.93003 11.1042 9.95265C10.8792 9.97527 10.6574 9.89197 10.5223 9.73414C10.2738 9.43991 9.87826 9.26651 9.45796 9.26759C8.85797 9.27052 8.33342 9.61939 8.17412 10.1215C8.01483 10.6235 8.25966 11.1563 8.77335 11.4254C9.01761 11.5528 9.13381 11.8061 9.05737 12.0444C8.98093 12.2827 8.73064 12.4474 8.44563 12.447V12.4465Z" fill="#177867" />
            <path d="M22.048 7.21362C21.6979 7.21362 21.4141 6.96728 21.4141 6.66339C21.4141 6.02417 20.8171 5.50598 20.0807 5.50598C19.3443 5.50598 18.7473 6.02417 18.7473 6.66339C18.7473 6.96728 18.4635 7.21362 18.1134 7.21362C17.7633 7.21362 17.4795 6.96728 17.4795 6.66339C17.4795 5.4164 18.6441 4.40552 20.0807 4.40552C21.5173 4.40552 22.6819 5.4164 22.6819 6.66339C22.6819 6.80932 22.6151 6.94928 22.4962 7.05246C22.3774 7.15565 22.2161 7.21362 22.048 7.21362Z" fill="#177867" />
            <path d="M27.9946 13.5571C27.7003 13.5546 27.4467 13.3766 27.3823 13.1273C27.3179 12.878 27.4597 12.623 27.7246 12.5117C27.9772 12.4087 28.1869 12.2403 28.3249 12.0297C28.6474 11.5527 28.5352 10.9442 28.0573 10.5784C27.5794 10.2126 26.8698 10.192 26.3649 10.5293C26.0848 10.7116 25.6875 10.6624 25.4774 10.4193C25.2673 10.1762 25.3241 9.8313 25.6042 9.64898C26.0478 9.35747 26.5882 9.19842 27.1446 9.19561C28.3693 9.1884 29.4345 9.92237 29.7042 10.9593C29.9739 11.9962 29.3754 13.0563 28.2659 13.5065C28.181 13.5408 28.0883 13.5581 27.9946 13.5571Z" fill="#177867" />
        </svg>
        :
        <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M11.7131 41.3115H7.92651C7.27496 41.3127 6.71548 41.7751 6.59168 42.4148L0.625821 73.0341C0.471024 73.834 0.682344 74.6611 1.20182 75.2887C1.7213 75.9163 2.4944 76.2785 3.3091 76.2759H32.6889C32.6889 77.7741 35.74 79 39.4993 79C43.2586 79 46.3096 77.7741 46.3096 76.2759H75.6894C76.5041 76.2785 77.2772 75.9163 77.7967 75.2887C78.3162 74.6611 78.5275 73.834 78.3727 73.0341L72.4069 42.4148C72.2831 41.7751 71.7236 41.3127 71.072 41.3115H11.7131Z" fill="#015078" />
                <path d="M39.5005 45.2752V70.8276C37.5078 69.9621 35.431 69.3048 33.3031 68.8662C25.2147 67.1078 16.8039 67.5356 8.93571 70.1057C8.48346 70.2437 7.99203 70.1392 7.63498 69.8292C7.27794 69.5192 7.10553 69.0473 7.17865 68.5802L11.7143 41.3116L12.0957 39.0097C12.1933 38.4255 12.6546 37.9698 13.2399 37.8792C14.1661 37.743 15.1195 37.6068 16.073 37.4978C17.7374 40.7332 21.07 42.7675 24.7085 42.769C24.9536 42.769 25.1852 42.7554 25.4167 42.7418C26.3237 42.671 27.2171 42.4786 28.0728 42.1697C28.0756 42.1986 28.0849 42.2266 28.1 42.2514C29.1192 45.1059 31.8249 47.0097 34.8559 47.005C36.5626 47.0107 38.2133 46.3959 39.5005 45.2752Z" fill="#F5EFCA" />
                <path d="M70.0648 70.1057C62.1966 67.5356 53.7858 67.1077 45.6974 68.8662C43.5696 69.3048 41.4927 69.962 39.5 70.8276V45.2752C39.7864 45.041 40.0509 44.7811 40.29 44.4988C41.6509 45.0401 43.1023 45.3174 44.5669 45.316H44.6486C44.7194 46.29 45.0906 47.218 45.711 47.9721C47.0331 49.279 48.6857 50.2018 50.4919 50.6417C51.8025 51.0602 52.9717 51.8333 53.8698 52.8755C54.6704 53.8674 56.0271 54.2162 57.2069 53.7336C58.2455 53.285 58.9194 52.2634 58.9231 51.1321C58.9245 50.8361 58.8785 50.5418 58.7869 50.2603C58.6507 49.8381 58.5009 49.4159 58.3238 49.0072C57.9822 48.1633 57.5336 47.3668 56.989 46.6372C59.6903 45.1763 61.5874 42.5734 62.1512 39.5545C62.1921 39.5 62.2465 39.4455 62.2874 39.391C62.7535 38.8427 63.1346 38.2274 63.4179 37.5659C64.2079 37.6612 64.9843 37.7565 65.7607 37.8791C66.346 37.9697 66.8072 38.4255 66.9048 39.0096L67.2862 41.3115L71.8219 68.5802C71.895 69.0473 71.7226 69.5192 71.3656 69.8292C71.0085 70.1392 70.5171 70.2437 70.0648 70.1057Z" fill="#F9EAB0" />
                <path d="M46.3082 76.2759C46.3082 77.7741 43.2571 79 39.4978 79C35.7385 79 32.6875 77.7741 32.6875 76.2759L33.3004 68.8662C35.4283 69.3048 37.5051 69.962 39.4978 70.8276C41.4906 69.962 43.5674 69.3048 45.6952 68.8662L46.3082 76.2759Z" fill="#103C53" />
                <path d="M64.017 34.7193C64.0157 35.6994 63.8117 36.6686 63.4177 37.566C63.1344 38.2276 62.7533 38.8429 62.2872 39.3912C62.2463 39.4457 62.1918 39.5002 62.151 39.5546C61.5872 42.5736 59.6901 45.1764 56.9887 46.6374C57.5334 47.3669 57.982 48.1635 58.3235 49.0074C58.5006 49.416 58.6504 49.8383 58.7866 50.2605C58.8783 50.5419 58.9243 50.8362 58.9228 51.1322C58.9192 52.2636 58.2452 53.2851 57.2066 53.7338C56.0269 54.2164 54.6702 53.8675 53.8696 52.8757C52.9714 51.8335 51.8023 51.0603 50.4916 50.6419C48.6854 50.202 47.0328 49.2792 45.7108 47.9722C45.0903 47.2182 44.7191 46.2901 44.6484 45.3162H44.5666C43.1021 45.3176 41.6506 45.0402 40.2897 44.499C40.0506 44.7813 39.7862 45.0412 39.4997 45.2753C38.2125 46.3961 36.5618 47.0108 34.8551 47.0052C31.8241 47.0099 29.1184 45.106 28.0992 42.2515C28.0841 42.2267 28.0748 42.1988 28.072 42.1698C27.2163 42.4787 26.3229 42.6711 25.416 42.7419C25.1844 42.7555 24.9528 42.7691 24.7077 42.7691C21.0264 42.7666 17.6616 40.687 16.013 37.3955C14.3643 34.104 14.714 30.164 16.9166 27.2143C16.7528 26.7695 16.6698 26.299 16.6715 25.825C16.6633 23.8121 18.0771 22.0732 20.0494 21.6707C20.0567 21.6633 20.0663 21.6585 20.0766 21.6571C20.063 21.4936 20.0494 21.3302 20.0494 21.1531C20.0476 20.3977 20.2054 19.6504 20.5125 18.9602C21.3816 16.9502 23.3624 15.6493 25.5522 15.6503C26.378 15.6464 27.1937 15.8328 27.9358 16.1952C29.3858 13.7538 32.0156 12.2577 34.8551 12.2588C36.8033 12.2517 38.6855 12.9648 40.1399 14.261C40.9749 12.9142 42.4936 12.1483 44.073 12.2775C45.6523 12.4067 47.0264 13.4093 47.6313 14.874C48.0203 14.8305 48.4113 14.8078 48.8027 14.8058C54.0801 14.8146 58.4719 18.8631 58.9092 24.1224H58.9501C60.0723 24.1224 61.1484 24.5691 61.9406 25.3639C62.7329 26.1587 63.1761 27.2362 63.1725 28.3584C63.1699 29.0923 62.9825 29.8137 62.6277 30.456C63.5316 31.6936 64.0182 33.1868 64.017 34.7193Z" fill="#329B89" />
                <path d="M39.5007 4.08621C38.7485 4.08621 38.1387 3.47639 38.1387 2.72414V1.36207C38.1387 0.609819 38.7485 0 39.5007 0C40.253 0 40.8628 0.609819 40.8628 1.36207V2.72414C40.8628 3.47639 40.253 4.08621 39.5007 4.08621Z" fill="#F0C419" />
                <path d="M61.4517 13.4615C60.9008 13.4614 60.4043 13.1295 60.1935 12.6206C59.9828 12.1117 60.0993 11.526 60.4887 11.1364L61.8507 9.77438C62.1927 9.4203 62.6991 9.2783 63.1754 9.40295C63.6516 9.5276 64.0235 9.8995 64.1481 10.3757C64.2728 10.8519 64.1308 11.3584 63.7767 11.7003L62.4146 13.0624C62.1593 13.3179 61.8129 13.4614 61.4517 13.4615Z" fill="#F0C419" />
                <path d="M17.5476 13.4615C17.1863 13.4614 16.8399 13.3179 16.5846 13.0624L15.2225 11.7003C14.8684 11.3584 14.7264 10.8519 14.8511 10.3757C14.9757 9.8995 15.3476 9.5276 15.8238 9.40295C16.3001 9.2783 16.8065 9.4203 17.1485 9.77438L18.5105 11.1364C18.9 11.526 19.0164 12.1117 18.8057 12.6206C18.5949 13.1295 18.0984 13.4614 17.5476 13.4615Z" fill="#F0C419" />
                <path d="M28.0313 7.72983C27.4805 7.72997 26.9838 7.39829 26.7728 6.88943L25.2091 3.11378C25.0103 2.66201 25.0715 2.13804 25.369 1.7442C25.6665 1.35035 26.1537 1.14826 26.6427 1.21596C27.1316 1.28367 27.5456 1.61057 27.7249 2.07043L29.2899 5.84609C29.4288 6.18003 29.4291 6.55548 29.2909 6.88969C29.1527 7.22389 28.8872 7.4894 28.553 7.62768C28.3874 7.69519 28.2102 7.72988 28.0313 7.72983Z" fill="#F0C419" />
                <path d="M50.966 7.72983C50.787 7.72945 50.6099 7.69429 50.4443 7.62631C50.1105 7.48803 49.8453 7.22274 49.7071 6.88886C49.5689 6.55498 49.569 6.17987 49.7074 5.84609L51.2725 2.07043C51.4517 1.61057 51.8658 1.28367 52.3547 1.21596C52.8436 1.14826 53.3309 1.35035 53.6284 1.7442C53.9259 2.13804 53.987 2.66201 53.7882 3.11378L52.2246 6.88943C52.0136 7.39829 51.5169 7.72997 50.966 7.72983Z" fill="#F0C419" />
                <path d="M67.1828 23.9478C66.5314 23.9498 65.9698 23.4903 65.8427 22.8515C65.7156 22.2127 66.0586 21.5733 66.6611 21.3258L70.4368 19.7622C70.8864 19.576 71.4012 19.6439 71.7872 19.9402C72.1732 20.2366 72.3718 20.7163 72.3082 21.1988C72.2446 21.6813 71.9284 22.0931 71.4787 22.2793L67.7044 23.8429C67.5391 23.9118 67.3619 23.9475 67.1828 23.9478Z" fill="#F0C419" />
                <path d="M11.8149 23.9478C11.6358 23.9475 11.4585 23.9118 11.2932 23.8429L7.51889 22.2793C7.06925 22.0931 6.75307 21.6813 6.68945 21.1988C6.62583 20.7163 6.82443 20.2366 7.21044 19.9402C7.59645 19.6439 8.11124 19.576 8.56087 19.7622L12.3365 21.3258C12.939 21.5733 13.282 22.2127 13.1549 22.8515C13.0278 23.4903 12.4662 23.9498 11.8149 23.9478Z" fill="#F0C419" />
                <path d="M62.2874 39.3911C62.2465 39.4456 62.1921 39.5 62.1512 39.5545C61.9112 40.8663 61.411 42.1167 60.6802 43.2321C59.9669 43.4345 59.2287 43.5354 58.4872 43.5318C57.1374 43.5279 55.8075 43.2059 54.6053 42.5919C54.0363 42.2874 53.502 41.9221 53.0117 41.5023C52.4684 41.7127 51.8917 41.8234 51.3091 41.8292C49.51 41.8385 47.862 40.8243 47.0595 39.214C46.8405 38.7785 46.8705 38.2592 47.1382 37.8518C47.4059 37.4444 47.8706 37.2108 48.3572 37.2389C48.8439 37.267 49.2786 37.5526 49.4976 37.9881C49.7666 38.5046 50.2432 38.8817 50.8076 39.0248C51.3721 39.1678 51.9708 39.0632 52.4533 38.7373C52.998 38.3655 53.3242 37.7487 53.325 37.0892C53.327 36.7734 53.2571 36.4613 53.1207 36.1766C52.8464 35.518 53.1268 34.7594 53.7635 34.4375C54.4002 34.1155 55.1773 34.3394 55.5452 34.9507C55.8764 35.6149 56.0489 36.347 56.0491 37.0892C56.0381 38.0409 55.7389 38.9669 55.191 39.7452C55.409 39.895 55.6269 40.0449 55.8448 40.1675C56.6621 40.5881 57.568 40.8076 58.4872 40.8076C59.8844 40.8154 61.2362 40.3115 62.2874 39.3911Z" fill="#177867" />
                <path d="M45.629 34.0436C47.3935 32.8279 48.6069 30.9649 49.0056 28.8596C49.107 28.3776 48.9403 27.8784 48.5696 27.5541C48.1989 27.2297 47.682 27.1308 47.2178 27.2953C46.7535 27.4598 46.4142 27.8621 46.3305 28.3474C46.0634 29.7523 45.2528 30.995 44.0749 31.8057C41.8087 33.332 38.7695 32.9856 36.9049 30.9885C36.3976 30.4318 35.535 30.3919 34.9783 30.8993C34.4216 31.4067 34.3817 32.2693 34.8891 32.8259C36.0445 34.1017 37.5716 34.9821 39.2545 35.343C38.4914 36.4156 37.2555 37.0513 35.9392 37.0483C35.187 37.0483 34.5772 37.6582 34.5772 38.4104C34.5772 39.1627 35.187 39.7725 35.9392 39.7725C38.7589 39.7837 41.2889 38.0422 42.2851 35.4043C43.4869 35.2046 44.6292 34.7398 45.629 34.0436Z" fill="#177867" />
                <path d="M58.3229 49.007C57.3217 49.5179 56.2459 49.8673 55.1356 50.0422C55.0635 50.0535 54.9906 50.0581 54.9177 50.0558C54.2003 50.0624 53.6008 49.5115 53.5469 48.7962C53.493 48.0808 54.0031 47.4463 54.7134 47.3453C55.5031 47.2258 56.2701 46.987 56.988 46.637C57.5327 47.3666 57.9813 48.1631 58.3229 49.007Z" fill="#177867" />
                <path d="M27.9774 39.4998C27.9087 39.882 27.8722 40.2692 27.8685 40.6575C27.8657 41.1686 27.9345 41.6775 28.0728 42.1694C27.2171 42.4783 26.3237 42.6707 25.4167 42.7415C25.2424 42.0742 25.1509 41.388 25.1443 40.6984C22.7451 40.4367 20.9264 38.4127 20.9219 35.9993C20.9173 35.2407 21.5255 34.6204 22.284 34.6099C22.6435 34.6078 22.9892 34.7487 23.2447 35.0017C23.5002 35.2546 23.6446 35.5988 23.646 35.9584V35.9993C23.6664 37.0756 24.5184 37.9515 25.5938 38.0015C25.7821 37.3871 26.0427 36.7973 26.3702 36.2444C26.5531 35.9321 26.8543 35.7068 27.2056 35.6196C27.5569 35.5325 27.9285 35.5908 28.2362 35.7813C28.8767 36.1772 29.0826 37.0129 28.6993 37.661C28.3535 38.2272 28.1091 38.8495 27.9774 39.4998Z" fill="#177867" />
                <path d="M48.2307 47.4679C47.4123 47.7297 46.567 47.8988 45.7109 47.9719C45.0904 47.2178 44.7192 46.2898 44.6484 45.3159C44.7803 45.3184 44.9121 45.3138 45.0434 45.3022C45.8523 45.2509 46.6524 45.1046 47.4271 44.8664C47.8968 44.7052 48.4171 44.8126 48.7847 45.1465C49.1523 45.4803 49.3091 45.9879 49.1938 46.4709C49.0786 46.954 48.7095 47.336 48.2307 47.4679Z" fill="#177867" />
                <path d="M36.5045 23.1549C36.5045 22.4027 35.8947 21.7928 35.1425 21.7928C31.6837 21.7966 28.8807 24.5996 28.877 28.0584C28.877 28.8106 29.4868 29.4204 30.239 29.4204C30.9913 29.4204 31.6011 28.8106 31.6011 28.0584C31.6011 27.1191 31.9742 26.2184 32.6383 25.5542C33.3025 24.8901 34.2032 24.517 35.1425 24.517C35.8947 24.517 36.5045 23.9072 36.5045 23.1549Z" fill="#177867" />
                <path d="M18.7253 30.8138C18.4779 30.8141 18.2352 30.7462 18.0238 30.6177C16.9006 29.94 16.0508 28.8896 15.6225 27.6497C15.4153 27.0603 15.3098 26.4399 15.3106 25.815C15.3105 22.7282 17.8082 20.2232 20.895 20.2142C22.6638 20.2074 24.329 21.0474 25.3749 22.4739C25.6651 22.8646 25.7249 23.3806 25.5316 23.8273C25.3384 24.2741 24.9214 24.5838 24.4379 24.6398C23.9543 24.6958 23.4777 24.4896 23.1874 24.0988C22.6535 23.3704 21.8036 22.9411 20.9005 22.9438C19.6113 22.951 18.4841 23.8147 18.1419 25.0577C17.7996 26.3007 18.3257 27.6196 19.4294 28.2858C19.9543 28.6014 20.204 29.2285 20.0397 29.8184C19.8755 30.4084 19.3377 30.8162 18.7253 30.8152V30.8138Z" fill="#177867" />
                <path d="M47.9532 17.858C47.2009 17.858 46.5911 17.2482 46.5911 16.4959C46.5911 14.9136 45.3083 13.6308 43.726 13.6308C42.1436 13.6308 40.8609 14.9136 40.8609 16.4959C40.8609 17.2482 40.251 17.858 39.4988 17.858C38.7465 17.858 38.1367 17.2482 38.1367 16.4959C38.1367 13.4091 40.6391 10.9067 43.726 10.9067C46.8128 10.9067 49.3152 13.4091 49.3152 16.4959C49.3152 16.8572 49.1717 17.2036 48.9163 17.4591C48.6609 17.7145 48.3144 17.858 47.9532 17.858Z" fill="#177867" />
                <path d="M60.7301 33.5628C60.0977 33.5566 59.5527 33.1159 59.4144 32.4987C59.276 31.8816 59.5806 31.2504 60.1499 30.9748C60.6927 30.7198 61.1432 30.303 61.4397 29.7816C62.1327 28.6008 61.8916 27.0945 60.8647 26.1888C59.8379 25.2832 58.3133 25.2322 57.2282 26.0673C56.6264 26.5186 55.7727 26.3967 55.3213 25.7949C54.87 25.1931 54.9919 24.3393 55.5937 23.888C56.5469 23.1663 57.7081 22.7726 58.9036 22.7656C61.5351 22.7478 63.824 24.5648 64.4035 27.1318C64.9831 29.6987 63.697 32.3229 61.3131 33.4374C61.1306 33.5223 60.9314 33.5651 60.7301 33.5628Z" fill="#177867" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="79" height="79" fill="white" />
                </clipPath>
            </defs>
        </svg>
}

export default LearnIcon