import clsx from "clsx";
import React from "react";
import { useContext } from "react";
import style from "../../../styleConstants";
import ScreenBreakpointContext from "../../ScreenBreakpointProvider/Provider";
import classes from "./RoundedButton.module.css";

const RoundedButton = ({
  text,
  icon,
  color = style.colors.orange,
  onClick,
  shine,
  outline,
  outlineColor = "rgba(255, 231, 51, 0.13)",
  fontSize,
}) => {
  const { screen } = useContext(ScreenBreakpointContext);
  const buttonStyle = {
    backgroundColor: color,
    color: color === style.colors.deepGreen ? "#FFFFFF" : "#021728",
    borderRadius: "50px",
    fontSize: fontSize ? fontSize : screen.lgUp ? "1.5rem" : "1.125rem",
    fontWeight: "400",
  };
  const outlineStyle = {
    background: outlineColor,
    borderRadius: "50px",
  };
  return (
    <div
      className={clsx("d-inline-block", "px-4 py-1")}
      style={outline ? outlineStyle : {}}
    >
      <button
        style={buttonStyle}
        className={clsx("px-3 py-2 px-lg-5 py-lg-3", classes["rounded-button"])}
        onClick={onClick}
      >
        <div>
          {text}&nbsp; {icon}
        </div>
        {shine && <span className={classes["rounded-button-inner"]}></span>}
      </button>
    </div>
  );
};

export default RoundedButton;
