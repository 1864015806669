import React from 'react';

const BagIcon = ({ small }) => {
    return small ?
        <svg width="37" height="29" viewBox="0 0 37 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8367 1.43902C23.7779 1.39886 23.2333 1.03622 22.2632 0.687266C21.0134 0.237661 19.5794 0 18.1162 0C16.6529 0 15.219 0.237661 13.969 0.687266C12.999 1.03622 12.4544 1.39886 12.3955 1.43908C12.1001 1.6407 11.9268 1.95418 11.9268 2.28667V3.95897L13.1175 4.52905L14.3084 3.95897V2.89026C14.9619 2.59172 16.2688 2.13173 18.1161 2.13173C19.9635 2.13173 21.2703 2.59172 21.9238 2.89026V3.95891L23.1146 4.52899L24.3054 3.95891V2.28667C24.3056 1.95412 24.1322 1.64063 23.8367 1.43902Z" fill="#EFA000" />
            <path d="M11.9268 3.95923V5.13602L13.1176 5.70605L14.3084 5.13602V3.95923H11.9268Z" fill="#BC7E00" />
            <path d="M24.3084 3.95921H21.9268V5.13552L23.1176 5.70531L24.3084 5.13552V3.95921Z" fill="#BC7E00" />
            <path d="M0.938477 16.8608V26.33C0.938477 27.4542 1.95658 28.3655 3.21244 28.3655H33.0214C34.2773 28.3655 35.2954 27.4542 35.2954 26.33V16.8608H0.938477Z" fill="#002C4E" />
            <path d="M0.938477 15.6839V16.8609C2.82762 18.2036 7.82053 20.8101 18.1169 20.8101C28.4133 20.8101 33.4062 18.2036 35.2954 16.8609V15.6838H0.938477V15.6839Z" fill="#244359" />
            <path d="M0.838709 15.0065C2.64924 16.3259 7.63521 19.0264 18.1159 19.0264C28.5966 19.0264 33.5825 16.3259 35.3931 15.0065C35.9238 14.6198 36.2318 13.4701 36.2318 12.8575V7.17113C36.2318 6.04703 35.2137 5.13574 33.9578 5.13574H2.27397C1.0181 5.13574 0 6.04703 0 7.17113V12.8575C0 13.4701 0.30797 14.6198 0.838709 15.0065Z" fill="#002A40" />
            <path d="M35.3931 14.4362C33.5825 15.7556 28.5966 18.4559 18.1159 18.4559C7.63521 18.4559 2.64931 15.7555 0.838709 14.4362C0.30797 14.0495 0 13.47 0 12.8574V14.0343C0 14.6468 0.30797 15.2264 0.838709 15.6131C2.64924 16.9325 7.63521 19.6328 18.1159 19.6328C28.5966 19.6328 33.5825 16.9325 35.3931 15.6131C35.9238 15.2264 36.2318 14.6468 36.2318 14.0343V12.8574C36.2318 13.47 35.9238 14.0495 35.3931 14.4362Z" fill="#002C4E" />
            <path d="M18.1154 21.5913C19.2918 21.5913 20.2454 20.8096 20.2454 19.1151V17.9593C20.2454 17.5847 19.906 17.281 19.4874 17.281H16.7433C16.3247 17.281 15.9854 17.5847 15.9854 17.9593V19.1153C15.9853 20.8096 16.939 21.5913 18.1154 21.5913Z" fill="#FFE27A" />
            <path d="M18.1154 21.0216C16.939 21.0216 15.9854 20.168 15.9854 19.115V20.2919C15.9854 21.3449 16.939 22.1985 18.1154 22.1985C19.2918 22.1985 20.2454 21.3449 20.2454 20.2919V19.115C20.2454 20.1679 19.2918 21.0216 18.1154 21.0216Z" fill="#F9CF58" />
        </svg>

        :
        <svg width="67" height="59" viewBox="0 0 67 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.0787 2.97286C43.97 2.8899 42.9629 2.14073 41.1689 1.41982C38.8578 0.490984 36.2059 0 33.5002 0C30.7944 0 28.1427 0.490984 25.8313 1.41982C24.0375 2.14073 23.0304 2.8899 22.9215 2.97299C22.3753 3.38952 22.0547 4.03714 22.0547 4.72402V8.17884L24.2567 9.35658L26.4588 8.17884V5.97098C27.6672 5.35424 30.084 4.40394 33.5 4.40394C36.9162 4.40394 39.3328 5.35424 40.5413 5.97098V8.17871L42.7433 9.35645L44.9454 8.17871V4.72402C44.9456 4.03701 44.625 3.38939 44.0787 2.97286Z" fill="#EFA000" />
            <path d="M22.0547 8.17889V10.6103L24.2568 11.788L26.4589 10.6103V8.17889H22.0547Z" fill="#BC7E00" />
            <path d="M44.9451 8.17889H40.541V10.6103L42.7431 11.788L44.9451 10.6103V8.17889Z" fill="#BC7E00" />
            <path d="M1.73438 34.8344V54.3968C1.73438 56.7191 3.61705 58.6018 5.93941 58.6018H61.0623C63.3847 58.6018 65.2674 56.7191 65.2674 54.3968V34.8344H1.73438Z" fill="#002C4E" />
            <path d="M1.73438 32.403V34.8345C5.2278 37.6082 14.4607 42.9926 33.5009 42.9926C52.5411 42.9926 61.774 37.6082 65.2674 34.8343V32.4028H1.73438V32.403Z" fill="#244359" />
            <path d="M1.55095 31.0028C4.89898 33.7286 14.1191 39.3076 33.5 39.3076C52.8809 39.3076 62.1009 33.7286 65.4491 31.0028C66.4305 30.2037 67 27.8286 67 26.5631V14.8152C67 12.4928 65.1173 10.6102 62.795 10.6102H4.20504C1.88267 10.6102 0 12.4928 0 14.8152V26.5631C0 27.8286 0.5695 30.2037 1.55095 31.0028Z" fill="#002A40" />
            <path d="M65.4491 29.8249C62.101 32.5507 52.8809 38.1298 33.5 38.1298C14.1191 38.1298 4.89911 32.5506 1.55095 29.8249C0.5695 29.0259 0 27.8285 0 26.563V28.9945C0 30.26 0.5695 31.4574 1.55095 32.2564C4.89898 34.9822 14.1191 40.5613 33.5 40.5613C52.8809 40.5613 62.1009 34.9822 65.4491 32.2564C66.4305 31.4574 67 30.26 67 28.9945V26.563C67 27.8285 66.4305 29.0259 65.4491 29.8249Z" fill="#002C4E" />
            <path d="M33.4994 44.608C35.6748 44.608 37.4383 42.9926 37.4383 39.4914V37.1032C37.4383 36.329 36.8107 35.7015 36.0366 35.7015H30.9622C30.188 35.7015 29.5605 36.3291 29.5605 37.1032V39.4918C29.5604 42.9926 31.324 44.608 33.4994 44.608Z" fill="#FFE27A" />
            <path d="M33.4994 43.4303C31.324 43.4303 29.5605 41.6668 29.5605 39.4914V41.9229C29.5605 44.0983 31.324 45.8618 33.4994 45.8618C35.6748 45.8618 37.4383 44.0983 37.4383 41.9229V39.4914C37.4383 41.6667 35.6748 43.4303 33.4994 43.4303Z" fill="#F9CF58" />
        </svg>

}

export default BagIcon