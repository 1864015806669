import clsx from "clsx";
import React, { useMemo, useContext } from "react";
import classes from "./RoundedCorner.module.css";
import ScreenBreakpointContext from "../ScreenBreakpointProvider/Provider";

const RoundedCorner = ({
  text,
  icon,
  title,
  roundedTopLeft,
  roundedTopRight,
  roundedBottomLeft,
  roundedBottomRight,
  topOutline,
  rightOutline,
  bottomOutline,
  leftOutline,
}) => {
  const { screen } = useContext(ScreenBreakpointContext);

  const titleStyle = useMemo(
    () => ({
      fontSize: screen.xxlUp ? "1.65rem" : screen.xlUp ? "1.45rem" : "1rem",
      fontWeight: "600",
    }),
    [screen]
  );

  const textStyle = useMemo(
    () => ({
      fontSize: screen.xxlUp ? "1.1rem" : screen.xlUp ? "0.9rem" : "0.75rem",
      fontWeight: "400",
    }),
    [screen]
  );

  const outlineClasses = useMemo(
    () => [
      topOutline ? classes["top-outline"] : classes["top-outline-margin"],
      rightOutline ? classes["right-outline"] : classes["right-outline-margin"],
      bottomOutline
        ? classes["bottom-outline"]
        : classes["bottom-outline-margin"],
      leftOutline ? classes["left-outline"] : classes["left-outline-margin"],
    ],
    [topOutline, rightOutline, bottomOutline, leftOutline]
  );
  const roundedClasses = useMemo(
    () => [
      roundedTopLeft ? classes["rounded-border-top-left"] : "",
      roundedTopRight ? classes["rounded-border-top-right"] : "",
      roundedBottomLeft ? classes["rounded-border-bottom-left"] : "",
      roundedBottomRight ? classes["rounded-border-bottom-right"] : "",
    ],
    [roundedTopLeft, roundedTopRight, roundedBottomLeft, roundedBottomRight]
  );
  return (
    <div
      className={clsx(
        classes["box-wrapper"],
        ...roundedClasses,
        ...outlineClasses,
        "d-inline-block"
      )}
    >
      <div
        className={clsx(
          classes.box,
          "d-flex",
          "p-xl-3",
          "align-items-center",
          ...roundedClasses
        )}
      >
        <div className="d-flex justify-content-center align-items-center p-3">
          {icon}
        </div>
        <div className="py-3 p-lg-3">
          <div
            className={clsx("text-white", "pb-2", "pb-lg-3")}
            style={titleStyle}
          >
            {title}
          </div>
          <div className="text-white" style={textStyle}>
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundedCorner;
