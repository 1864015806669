import React from "react";
import BarIcon from "./BarIcon";
import BookIcon from "./BookIcon";
import ConnectIcon from "./ConnectIcon";
import CubesIcon from "./CubesIcon";
import { ICONS } from "./constants";
import DartIcon from "./DartIcon";
import ProjectIcon from "./ProjectIcon";
import StatIcon from "./StatIcon";
import SupportIcon from "./SupportIcon";
import Location from "./Location";
import Speaker from "./Speaker";
import StarIcon from "./StarIcon";
import RocketIcon from "./RocketIcon";
import BagIcon from "./BagIcon";
import FreelancerIcon from "./FreelancerIcon";
import PersonalBrandingIcon from "./PersonalBrandingIcon";
import GrowIcon from "./GrowIcon";
import LearnIcon from "./LearnIcon";
import FooterLogoIcon from "./FooterLogoIcon";
import EmailIcon from "./EmailIcon";
import WhatsappIcon from "./WhatsappIcon";
import YoutubeIcon from "./YoutubeIcon";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import LinkedInIcon from "./LinkedInIcon";
import BackIcon from "./BackIcon";
import ArrowRight from "./ArrowRight";
import CNLogoHeader from "./CNLogoHeader";

const SvgIcon = ({ icon, small }) => {
  switch (icon) {
    case ICONS.bar:
      return <BarIcon small={small} />;
    case ICONS.book:
      return <BookIcon small={small} />;
    case ICONS.connect:
      return <ConnectIcon small={small} />;
    case ICONS.cubes:
      return <CubesIcon small={small} />;
    case ICONS.dart:
      return <DartIcon small={small} />;
    case ICONS.project:
      return <ProjectIcon small={small} />;
    case ICONS.stat:
      return <StatIcon small={small} />;
    case ICONS.support:
      return <SupportIcon small={small} />;
    case ICONS.location:
      return <Location small={small} />;
    case ICONS.speaker:
      return <Speaker small={small} />;
    case ICONS.star:
      return <StarIcon small={small} />;
    case ICONS.rocket:
      return <RocketIcon small={small} />;
    case ICONS.bag:
      return <BagIcon small={small} />;
    case ICONS.freelancer:
      return <FreelancerIcon small={small} />;
    case ICONS.personalBranding:
      return <PersonalBrandingIcon small={small} />;
    case ICONS.grow:
      return <GrowIcon small={small} />;
    case ICONS.learn:
      return <LearnIcon small={small} />;
    case ICONS.footerLogoIcon:
      return <FooterLogoIcon />;
    case ICONS.emailIcon:
      return <EmailIcon small={small} />;
    case ICONS.whatsappIcon:
      return <WhatsappIcon small={small} />;
    case ICONS.youtubeIcon:
      return <YoutubeIcon small={small} />;
    case ICONS.instagramIcon:
      return <InstagramIcon small={small} />;
    case ICONS.facebookIcon:
      return <FacebookIcon small={small} />;
    case ICONS.linkedInIcon:
      return <LinkedInIcon small={small} />;
    case ICONS.backIcon:
      return <BackIcon small={small} />;
    case ICONS.arrowRight:
      return <ArrowRight small={small} />;
    case ICONS.cnLogoHeader:
      return <CNLogoHeader small={small} />;
    default:
      return null;
  }
};

export default SvgIcon;
