import { React, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import clsx from "clsx";
import logo from "../../assets/mainLogo.svg";
import { NAV_ITEMS } from "../../constants";
import "./Navigation.css";
import { BOX_BG_COLOR } from "../../styleGuide/layout/Box/constants";
import style from "../../styleConstants";
import googleLogo from "../../assets/company_logos/google.png";
import { redirectGoogleLogin } from "../../helper/redirect";
import { captureLeadBasic } from "../../helper/leadsquared";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import contactUsConfirmation from "../../assets/contact-us-confirmation.svg"

function Navigation() {
  const signUpButton = {
    borderRadius: "30px",
    backgroundColor: style.colors.orange,
    borderColor: style.colors.orange,
    fontWeight: "600",
  };
  const scrollMargin = (elem) => {
    const element = elem.offsetTop;
    window.scrollTo({
      left: 0,
      top: element - 75,
    });
    return 1;
  };
  const handleClick = (e) => {
    e.preventDefault();
    const target = e.target.getAttribute("href");
    const element = document.querySelector(target);
    return element ? scrollMargin(element) : null;
  };

  const handleSignUpClick = () => {
    redirectGoogleLogin()
  }
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [invalidDetailsLabel, setInvalidDetailsLabel] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const openModal = () => {
    setShowModal(true);
    setInvalidDetailsLabel(false);
  }

  const closeModal = () => {
    setShowModal(false);
  }
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showConfirmed = async () => {
    setShowConfirmation(true);
    sleep(3500).then(() => {
      closeModal();
      redirectGoogleLogin();
    });
  };

  const isValidEmail = (leadEmail) => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(leadEmail)) {
      return true
    }
    return false
  }

  const submitDetails = async () => {
    if (firstname !== "" && lastname !== ""
      && (mobileNumber && (/^\d{10}$/.test(mobileNumber))
        && isValidEmail(email)
      )) {
      setBtnDisabled(true)
      await window.fbq('trackSingle', '333999584825866', 'CompleteRegistration')
      await window.fbq('trackSingle', '2001403463411336', 'CompleteRegistration')
      await captureLeadBasic(firstname, lastname, email, mobileNumber);
      await showConfirmed();
    } else {
      setInvalidDetailsLabel(true);
    }
  };

  const handleNameChange = (e) => {
    let fullname = e.target.value.split(" ");
    setFirstname(fullname[0]);
    setLastname(fullname[1]);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  }
  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className="w-100 px-lg-5"
        style={{ backgroundColor: BOX_BG_COLOR.lightGreen }}
      >
        <Navbar.Brand href="#" className="px-2 px-lg-0 pb-2 pb-lg-0">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="mx-2 mx-lg-0 mb-2 mb-lg-0"
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="navbar-collapse-mobile"
        >
          <Nav className="justify-content-lg-end align-items-center w-100">
            {NAV_ITEMS.map((element) => (
              <Nav.Link
                onClick={handleClick}
                href={element.url}
                className="mx-3 my-2 my-lg-0 text-dark navigation-menu"
                key={element.menu}
              >
                {element.menu}
              </Nav.Link>
            ))}
            <Nav.Link href={"#"} className="mx-3 text-dark">
              <div
                style={signUpButton}
                onClick={window.location.pathname === process.env.REACT_APP_TEMP_CTA_PATH ? openModal : handleSignUpClick}
                className="p-2 px-3 my-2 my-lg-0 sign-up-button"
              >
                Sign Up &nbsp; <img src={googleLogo} alt="google" />
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div style={{ height: "65px" }}></div>
      <Modal
        show={showModal}
        onHide={closeModal}
        animation={true}
        size="md"
        dialogClassName="contact-form"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="pb-5">
          <div className="text-center py-4 px-4 px-lg-0">
            <div className="contact-form-title">
              <span>Your Personalised</span>
            </div>
            <div className="contact-form-title">
              <span>Digital Marketing Course</span>
            </div>
          </div>
          {showModal ? (
            <Form className="px-5">
              {invalidDetailsLabel ? <Form.Label className="form-label-alert">Invalid Details!</Form.Label> : null}
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={handleNameChange} type="text" placeholder="" required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control onChange={handleEmailChange} type="email" placeholder="" required />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicNumber">
                <Form.Label>Number</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>+ 91</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control onChange={handleMobileNumberChange} type="text" placeholder="" required />
                </InputGroup>
                <Form.Label className="form-label-info">*Will be used to provide course access</Form.Label>
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="contact-form-submit-button"
                  disabled={btnDisabled}
                  onClick={submitDetails}
                >
                  Continue
                </Button>
              </div>
            </Form>
          ) : null}
        </div>
        {showModal && showConfirmation ? (
          <div className={clsx("contact-form-sent")}>
            <div className="d-flex justify-content-center">
              <img src={contactUsConfirmation} alt="confirmation logo"></img>
            </div>

            <div className="text-center py-4 px-4 px-lg-0">
              <div className="contact-form-redirect-msg">
                <span>Awesome, let's begin! Redirecting you to course onboarding. Please login with Google to continue.</span>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}

export default Navigation;
