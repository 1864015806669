import style from "../../../styleConstants";

export const BOX_GRADIANT_DIRECTIONS = {
  horizontal: "to right",
  diagonal: "to bottom",
};

export const BOX_BG_COLOR = {
  ...style.colors,
};
