
const cta_redirect_url = `${process.env.REACT_APP_PLATFORM_URL}/login`
const google_login_url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=permission%20id_token&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&scope=openid%20email%20profile&redirect_uri=${process.env.REACT_APP_PLATFORM_URL}/login&nonce=0394852-3190485-2490358`

const redirectToApp = () => {
    window.open(cta_redirect_url, "_self");
}

const redirectGoogleLogin = () => {
    window.open(google_login_url, "_self");
}

export { redirectToApp, redirectGoogleLogin }