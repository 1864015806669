import React from 'react';

const ArrowRight = ({ small }) => {
    return small ?
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.392239 0.71098C0.310311 0.771657 0.245313 0.84373 0.200965 0.923073C0.156617 1.00242 0.133789 1.08747 0.133789 1.17337C0.133789 1.25927 0.156617 1.34432 0.200965 1.42366C0.245313 1.50301 0.310311 1.57508 0.392239 1.63576L3.82121 4.18053L0.392239 6.72531C0.226996 6.84794 0.134163 7.01427 0.134163 7.1877C0.134163 7.36112 0.226996 7.52745 0.392239 7.65008C0.557481 7.77272 0.781598 7.84161 1.01529 7.84161C1.24898 7.84161 1.47309 7.77272 1.63834 7.65008L5.69478 4.63964C5.77671 4.57896 5.8417 4.50689 5.88605 4.42755C5.9304 4.34821 5.95323 4.26315 5.95323 4.17725C5.95323 4.09135 5.9304 4.0063 5.88605 3.92696C5.8417 3.84761 5.77671 3.77554 5.69478 3.71486L1.63834 0.704422C1.30251 0.455191 0.736904 0.455191 0.392239 0.71098Z" fill="#021728" />
        </svg>

        :
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.240329 0.219065C0.375888 0.0950681 0.554605 0.0185978 0.745345 0.00297615C0.936085 -0.0126455 1.12676 0.0335721 1.28415 0.133576L1.39867 0.219065L8.76103 6.96819C8.89629 7.09245 8.97971 7.25629 8.99675 7.43114C9.01379 7.60599 8.96338 7.78078 8.85429 7.92506L8.76103 8.03005L1.39867 14.7792C1.25439 14.912 1.06105 14.9905 0.856766 14.9992C0.652479 15.0079 0.452058 14.9461 0.295003 14.826C0.137947 14.7059 0.0356487 14.5363 0.00826718 14.3505C-0.0191143 14.1647 0.0304078 13.9763 0.147073 13.8223L0.240329 13.7173L7.02515 7.49912L0.240329 1.28093C0.164148 1.21127 0.103707 1.12851 0.0624669 1.03741C0.0212271 0.946303 0 0.848634 0 0.749996C0 0.651358 0.0212271 0.553689 0.0624669 0.462583C0.103707 0.371477 0.164148 0.288724 0.240329 0.219065Z" fill="black" />
        </svg>
}

export default ArrowRight