import React from 'react'
import { useMemo } from 'react'
import { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { WHAT_MAKE_US_UNIQUE_CONTENT } from '../../constants'
import PageHeading from '../../styleGuide/PageHeading'
import RoundedCorner from '../../styleGuide/RoundedCorner'
import ScreenBreakpointContext from '../../styleGuide/ScreenBreakpointProvider/Provider'
import SvgIcon from '../../styleGuide/SvgIcon'

const WhatMakesUsUnique = () => {
    const { screen } = useContext(ScreenBreakpointContext)
    const roundedMobileCorner = useMemo(() => ({
        roundedTopLeft: true,
        roundedBottomRight: true
    }), [])
    const fullOutline = useMemo(() => ({
        topOutline: true,
        rightOutline: true,
        bottomOutline: true,
        leftOutline: true
    }), [])
    return <div className="pb-5 pt-3">
        <PageHeading heading="What Makes Us Unique?" />
        <div className="d-flex justify-content-center">
            <Row className="m-0 w-75">
                <Col lg={6} className="d-flex justify-content-center justify-content-lg-end my-2 my-lg-0 mx-0">
                    <RoundedCorner
                        title={WHAT_MAKE_US_UNIQUE_CONTENT.personalizedToGoals.title}
                        text={WHAT_MAKE_US_UNIQUE_CONTENT.personalizedToGoals.text}
                        icon={<SvgIcon icon={WHAT_MAKE_US_UNIQUE_CONTENT.personalizedToGoals.icon} small={screen.xlDown} />}
                        {...(screen.lgUp ? {
                            roundedTopRight: true,
                            roundedBottomLeft: true
                        } : { ...roundedMobileCorner, ...fullOutline })}
                    />
                </Col>
                <Col lg={6} className="d-flex justify-content-center justify-content-lg-start my-2 my-lg-0 mx-0">
                    <RoundedCorner
                        title={WHAT_MAKE_US_UNIQUE_CONTENT.coachingWithMentor.title}
                        text={WHAT_MAKE_US_UNIQUE_CONTENT.coachingWithMentor.text}
                        icon={<SvgIcon icon={WHAT_MAKE_US_UNIQUE_CONTENT.coachingWithMentor.icon} small={screen.xlDown} />}
                        {...(screen.lgUp ? {
                            roundedTopLeft: true,
                            roundedBottomRight: true,
                            topOutline: true,
                            rightOutline: true
                        } : { ...roundedMobileCorner, ...fullOutline })}
                    />
                </Col>
                <Col lg={6} className="d-flex justify-content-center justify-content-lg-end my-2 my-lg-0 mx-0">
                    <RoundedCorner
                        title={WHAT_MAKE_US_UNIQUE_CONTENT.liveIndustryProject.title}
                        text={WHAT_MAKE_US_UNIQUE_CONTENT.liveIndustryProject.text}
                        icon={<SvgIcon icon={WHAT_MAKE_US_UNIQUE_CONTENT.liveIndustryProject.icon} small={screen.xlDown} />}
                        {...(screen.lgUp ? {
                            roundedTopLeft: true,
                            roundedBottomRight: true,
                            leftOutline: true,
                            bottomOutline: true,
                        } : { ...roundedMobileCorner, ...fullOutline })}
                    />
                </Col>
                <Col lg={6} className="d-flex justify-content-center justify-content-lg-start my-2 my-lg-0 mx-0">
                    <RoundedCorner
                        title={WHAT_MAKE_US_UNIQUE_CONTENT.jobPlacementSupport.title}
                        text={WHAT_MAKE_US_UNIQUE_CONTENT.jobPlacementSupport.text}
                        icon={<SvgIcon icon={WHAT_MAKE_US_UNIQUE_CONTENT.jobPlacementSupport.icon} small={screen.xlDown} />}
                        {...(screen.lgUp ? {
                            roundedTopRight: true,
                            roundedBottomLeft: true,
                        } : { ...roundedMobileCorner, ...fullOutline })}
                    />
                </Col>
            </Row>
        </div>
    </div>
}

export default WhatMakesUsUnique