import React from "react";

const LinkedInIcon = ({ small }) =>
  small ? (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.822074 2.06128H2.17382V5.90449H0.822074V2.06128ZM1.49798 0.150879C1.93002 0.150879 2.28113 0.461231 2.28113 0.843759C2.28113 1.2259 1.93002 1.53625 1.49798 1.53625C1.06494 1.53625 0.714844 1.2259 0.714844 0.843759C0.714844 0.461231 1.06494 0.150879 1.49798 0.150879Z"
        fill="white"
      />
      <path
        d="M3.01953 2.0605H4.31571V2.58604H4.33419C4.51459 2.28375 4.95554 1.96533 5.61276 1.96533C6.98117 1.96533 7.23374 2.76106 7.23374 3.79587V5.90371H5.88301V4.03475C5.88301 3.58895 5.87424 3.0157 5.1808 3.0157C4.47757 3.0157 4.37027 3.50159 4.37027 4.00273V5.90371H3.01953V2.0605Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.410014 7.30476H5.57868V22H0.410014V7.30476ZM2.99448 0C4.64645 0 5.98897 1.18669 5.98897 2.64935C5.98897 4.11055 4.64645 5.29724 2.99448 5.29724C1.33864 5.29724 0 4.11055 0 2.64935C0 1.18669 1.33864 0 2.99448 0Z"
        fill="white"
      />
      <path
        d="M8.82031 7.30432H13.7765V9.3138H13.8471C14.537 8.15797 16.223 6.94043 18.736 6.94043C23.9684 6.94043 24.9341 9.98306 24.9341 13.9398V21.9996H19.7693V14.8532C19.7693 13.1486 19.7358 10.9567 17.0843 10.9567C14.3954 10.9567 13.9851 12.8146 13.9851 14.7308V21.9996H8.82031V7.30432Z"
        fill="white"
      />
    </svg>
  );
export default LinkedInIcon;
