export const ICONS = {
  bar: "bar",
  book: "book",
  connect: "connect",
  cubes: "cubes",
  dart: "dart",
  project: "project",
  stat: "stat",
  support: "support",
  speaker: "speaker",
  location: "location",
  star: "star",
  rocket: "rocket",
  bag: "bag",
  freelancer: "freelancer",
  personalBranding: "personalBranding",
  grow: "grow",
  learn: "learn",
  footerLogoIcon: "footerLogoIcon",
  emailIcon: "emailIcon",
  whatsappIcon: "whatsappIcon",
  youtubeIcon: "youtubeIcon",
  instagramIcon: "instagramIcon",
  facebookIcon: "facebookIcon",
  linkedInIcon: "linkedInIcon",
  backIcon: "backIcon",
  arrowRight: "arrowRight",
  cnLogoHeader: "cnLogoHeader",
};
