import React from 'react';

const RocketIcon = ({ small }) => {
    return small ? <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.18708 12.5671C3.86025 10.1587 4.84445 8.60704 5.56202 7.46036C2.80438 7.70425 0.161133 11.1356 0.161133 11.1356L1.98132 12.9557C2.03414 12.8211 2.10157 12.6904 2.18708 12.5671Z" fill="white" />
        <path d="M9.29297 20.2684L11.112 22.0873C11.112 22.0873 14.5432 19.4443 14.7871 16.6867C13.6408 17.404 12.0897 18.3879 9.68042 20.0615C9.55854 20.1461 9.42862 20.2151 9.29297 20.2684Z" fill="white" />
        <path d="M6.52697 19.3281C6.21472 19.4543 5.90784 19.5884 5.58789 19.6953C4.73334 19.9809 3.85322 20.1739 2.96089 20.2971C2.58685 20.3487 2.20936 20.3925 1.83123 20.4211C1.83483 20.3745 1.83829 20.3277 1.84224 20.2811C1.92027 19.3719 2.0586 18.4674 2.28209 17.5821C2.38977 17.1548 2.52718 16.7395 2.67469 16.3247C2.76642 16.0668 2.88088 15.82 2.99633 15.5732L2.79622 15.3733C2.35115 15.6722 1.96405 16.05 1.63556 16.4774C1.14925 17.1103 0.784745 17.8273 0.529903 18.5829C0.256138 19.3942 0.0928814 20.2534 0.0674608 21.1099C0.0638596 21.2302 0.0555979 21.3532 0.0742397 21.4726C0.098248 21.6268 0.165966 21.7727 0.267718 21.891C0.368412 22.008 0.500952 22.0964 0.647827 22.1437C0.771116 22.1835 0.895112 22.1842 1.02299 22.1832C1.80058 22.1767 2.59024 22.0416 3.33457 21.8205C4.69591 21.416 6.07025 20.6551 6.8758 19.4527L6.68105 19.2581C6.62999 19.2821 6.5788 19.3056 6.52697 19.3281Z" fill="white" />
        <path d="M21.6692 0.578236C21.6692 0.578236 21.6691 0.578165 21.6689 0.578024C21.5273 0.436445 21.3353 0.357147 21.1355 0.357147C21.1305 0.357147 21.1255 0.357218 21.1206 0.357288C18.6908 0.404952 12.696 0.859416 9.51233 4.0432C8.0671 5.48843 7.59738 6.24074 6.56785 7.88941C5.82641 9.07641 4.81135 10.7023 2.95622 13.3724C2.74784 13.6725 2.78406 14.0788 3.04243 14.337L7.90963 19.2043C8.05566 19.3503 8.24907 19.4255 8.44374 19.4255C8.59337 19.4255 8.74371 19.3811 8.8742 19.2905C11.5453 17.435 13.1706 16.4201 14.3571 15.679C16.0057 14.6495 16.7581 14.1797 18.2036 12.7343C21.3929 9.54485 21.8442 3.554 21.89 1.12626C21.894 0.921272 21.8142 0.723415 21.6692 0.578236ZM16.0331 9.76192C15.0532 10.7417 13.4647 10.7417 12.4848 9.76192C11.5048 8.78202 11.5049 7.19359 12.485 6.21377C13.4647 5.23387 15.0532 5.23373 16.0331 6.21356C17.0127 7.19338 17.0129 8.78216 16.0331 9.76192Z" fill="white" />
    </svg>
        :
        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.15532 29.6488C9.21776 23.8011 11.6074 20.0337 13.3496 17.2496C6.65411 17.8418 0.236328 26.1731 0.236328 26.1731L4.65572 30.5923C4.78397 30.2655 4.9477 29.9482 5.15532 29.6488Z" fill="white" />
            <path d="M22.4121 48.3477L26.8286 52.764C26.8286 52.764 35.1595 46.3467 35.7519 39.6513C32.9686 41.3929 29.2024 43.7817 23.3528 47.8452C23.0569 48.0505 22.7415 48.2182 22.4121 48.3477Z" fill="white" />
            <path d="M15.6957 46.0641C14.9375 46.3705 14.1924 46.6961 13.4156 46.9557C11.3408 47.6492 9.20384 48.1177 7.03726 48.4167C6.12911 48.5421 5.21255 48.6484 4.29445 48.718C4.3032 48.6046 4.3116 48.4911 4.3212 48.378C4.51065 46.1704 4.84651 43.9744 5.38914 41.8248C5.6506 40.7873 5.98424 39.7789 6.34239 38.7718C6.5651 38.1457 6.84301 37.5465 7.12333 36.9473L6.63745 36.4617C5.55682 37.1876 4.61694 38.1049 3.81937 39.1427C2.63862 40.6793 1.75361 42.4202 1.13485 44.2547C0.470152 46.2244 0.0737659 48.3106 0.0120449 50.3903C0.00330109 50.6824 -0.0167582 50.9809 0.0285038 51.2708C0.0867959 51.6453 0.251214 51.9996 0.498269 52.2868C0.742753 52.5709 1.06456 52.7854 1.42117 52.9004C1.72052 52.997 2.02158 52.9987 2.33207 52.9963C4.22004 52.9805 6.13734 52.6525 7.94456 52.1155C11.2499 51.1335 14.5868 49.286 16.5426 46.3666L16.0698 45.8941C15.9458 45.9524 15.8215 46.0095 15.6957 46.0641Z" fill="white" />
            <path d="M52.4638 0.539823C52.4638 0.539823 52.4635 0.539651 52.4631 0.539308C52.1192 0.195556 51.6531 0.00302124 51.1679 0.00302124C51.1559 0.00302124 51.1437 0.00319269 51.1317 0.00336413C45.2322 0.119091 30.6768 1.22253 22.947 8.95274C19.438 12.4618 18.2975 14.2884 15.7978 18.2913C13.9976 21.1733 11.533 25.1209 7.02877 31.604C6.52283 32.3327 6.61079 33.319 7.23811 33.946L19.0556 45.7637C19.4102 46.1184 19.8798 46.3008 20.3525 46.3008C20.7158 46.3008 21.0808 46.1931 21.3976 45.9732C27.8831 41.4679 31.8291 39.0038 34.7101 37.2043C38.7127 34.7048 40.5397 33.564 44.0494 30.0546C51.793 22.3107 52.8887 7.76495 52.9998 1.87042C53.0096 1.37271 52.8158 0.892318 52.4638 0.539823ZM38.7794 22.8377C36.4003 25.2167 32.5434 25.2167 30.1642 22.8377C27.7847 20.4586 27.785 16.6019 30.1645 14.2229C32.5434 11.8437 36.4003 11.8433 38.7794 14.2223C41.1579 16.6013 41.1583 20.4589 38.7794 22.8377Z" fill="white" />
        </svg>

}

export default RocketIcon