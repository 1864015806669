import React from "react";

const YoutubeIcon = ({ small }) =>
  small ? (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.103 1.65661C9.99472 1.25155 9.67566 0.932487 9.2706 0.824215C8.53644 0.627441 5.59227 0.627441 5.59227 0.627441C5.59227 0.627441 2.64809 0.627441 1.91393 0.824215C1.50888 0.932487 1.18981 1.25155 1.08154 1.65661C0.884766 2.39077 0.884766 3.92269 0.884766 3.92269C0.884766 3.92269 0.884766 5.45462 1.08154 6.18878C1.18981 6.59383 1.50888 6.9129 1.91393 7.02117C2.64809 7.21794 5.59227 7.21794 5.59227 7.21794C5.59227 7.21794 8.53644 7.21794 9.2706 7.02117C9.67566 6.9129 9.99472 6.59383 10.103 6.18878C10.2998 5.45462 10.2998 3.92269 10.2998 3.92269C10.2998 3.92269 10.2998 2.39077 10.103 1.65661Z"
        fill="white"
      />
      <path
        d="M4.65137 5.33524L7.09718 3.92299L4.65137 2.51074V5.33524Z"
        fill="#011627"
      />
    </svg>
  ) : (
    <svg
      width="36"
      height="26"
      viewBox="0 0 36 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.2476 3.9352C34.8336 2.3864 33.6136 1.1664 32.0648 0.7524C29.2576 -1.90735e-07 18 0 18 0C18 0 6.7424 -1.90735e-07 3.9352 0.7524C2.3864 1.1664 1.1664 2.3864 0.7524 3.9352C0 6.7424 0 12.6 0 12.6C0 12.6 0 18.4576 0.7524 21.2648C1.1664 22.8136 2.3864 24.0336 3.9352 24.4476C6.7424 25.2 18 25.2 18 25.2C18 25.2 29.2576 25.2 32.0648 24.4476C33.6136 24.0336 34.8336 22.8136 35.2476 21.2648C36 18.4576 36 12.6 36 12.6C36 12.6 36 6.7424 35.2476 3.9352Z"
        fill="white"
      />
      <path
        d="M14.4004 18.0002L23.7524 12.6002L14.4004 7.2002V18.0002Z"
        fill="#011627"
      />
    </svg>
  );

export default YoutubeIcon;
