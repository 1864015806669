import React from 'react';

const BarIcon = ({ small }) => {
    return small ? <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.1333 21.7249V7.51781C25.1333 7.29617 25.042 7.0836 24.8795 6.92687C24.717 6.77015 24.4965 6.6821 24.2667 6.6821H19.0667C18.8368 6.6821 18.6164 6.77015 18.4538 6.92687C18.2913 7.0836 18.2 7.29617 18.2 7.51781V21.7249H16.4667V10.8607C16.4667 10.639 16.3754 10.4265 16.2128 10.2697C16.0503 10.113 15.8299 10.025 15.6 10.025H10.4C10.1701 10.025 9.94971 10.113 9.78717 10.2697C9.62464 10.4265 9.53333 10.639 9.53333 10.8607V21.7249H7.8V14.2035C7.8 13.9819 7.70869 13.7693 7.54616 13.6126C7.38363 13.4559 7.16319 13.3678 6.93333 13.3678H1.73333C1.50348 13.3678 1.28304 13.4559 1.12051 13.6126C0.957976 13.7693 0.866667 13.9819 0.866667 14.2035V21.7249C0.636812 21.7249 0.416372 21.813 0.253841 21.9697C0.0913092 22.1264 0 22.339 0 22.5607C0 22.7823 0.0913092 22.9949 0.253841 23.1516C0.416372 23.3083 0.636812 23.3964 0.866667 23.3964H25.1333C25.3632 23.3964 25.5836 23.3083 25.7462 23.1516C25.9087 22.9949 26 22.7823 26 22.5607C26 22.339 25.9087 22.1264 25.7462 21.9697C25.5836 21.813 25.3632 21.7249 25.1333 21.7249Z" fill="#022B4C" />
        <path d="M2.95721 11.6969C3.12516 11.6971 3.28949 11.6498 3.42989 11.561L16.6396 3.25123L16.3016 5.09181C16.2615 5.31011 16.3129 5.53483 16.4446 5.7166C16.5762 5.89837 16.7773 6.02232 17.0036 6.0612C17.0539 6.0696 17.1049 6.07382 17.156 6.07382C17.3594 6.07353 17.5563 6.00431 17.7122 5.87826C17.868 5.7522 17.973 5.57733 18.0087 5.38422L18.6967 1.64321C18.6992 1.60105 18.6981 1.55877 18.6935 1.51677C18.699 1.4549 18.6974 1.39263 18.6889 1.33108C18.6712 1.26824 18.6462 1.20756 18.6142 1.15024C18.6019 1.11263 18.5868 1.07595 18.5689 1.04052L18.5594 1.03116C18.5161 0.97613 18.4656 0.926674 18.4093 0.883996C18.3817 0.854073 18.3518 0.826142 18.32 0.800428C18.2872 0.784595 18.2534 0.770806 18.2188 0.759146C18.152 0.723048 18.0804 0.695916 18.006 0.678502L17.9947 0.67424L14.115 0.0103762C13.8907 -0.0240222 13.6614 0.0278554 13.4763 0.154847C13.2913 0.281839 13.1654 0.473795 13.1256 0.689415C13.0859 0.905035 13.1355 1.12709 13.2638 1.30779C13.3921 1.48849 13.5888 1.61341 13.8117 1.65566L15.5353 1.95074L2.4828 10.1614C2.32638 10.2598 2.20714 10.4044 2.14283 10.5735C2.07852 10.7426 2.07259 10.9272 2.12592 11.0998C2.17925 11.2724 2.28899 11.4238 2.43879 11.5314C2.58859 11.639 2.77042 11.697 2.95721 11.6969Z" fill="#022B4C" />
    </svg> :
        <svg width="59" height="55" viewBox="0 0 59 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56.9708 51.0705V17.6739C56.9708 17.1528 56.7638 16.6532 56.3954 16.2847C56.027 15.9163 55.5273 15.7094 55.0063 15.7094H43.2192C42.6982 15.7094 42.1985 15.9163 41.8301 16.2847C41.4617 16.6532 41.2547 17.1528 41.2547 17.6739V51.0705H37.3257V25.5319C37.3257 25.0109 37.1187 24.5112 36.7503 24.1428C36.3819 23.7744 35.8822 23.5674 35.3612 23.5674H23.5741C23.0531 23.5674 22.5534 23.7744 22.185 24.1428C21.8166 24.5112 21.6096 25.0109 21.6096 25.5319V51.0705H17.6806V33.3899C17.6806 32.8689 17.4736 32.3692 17.1052 32.0008C16.7368 31.6324 16.2371 31.4254 15.7161 31.4254H3.92902C3.408 31.4254 2.90832 31.6324 2.5399 32.0008C2.17148 32.3692 1.96451 32.8689 1.96451 33.3899V51.0705C1.44349 51.0705 0.943808 51.2775 0.575391 51.6459C0.206974 52.0143 0 52.514 0 53.035C0 53.556 0.206974 54.0557 0.575391 54.4241C0.943808 54.7926 1.44349 54.9995 1.96451 54.9995H56.9708C57.4918 54.9995 57.9915 54.7926 58.3599 54.4241C58.7283 54.0557 58.9353 53.556 58.9353 53.035C58.9353 52.514 58.7283 52.0143 58.3599 51.6459C57.9915 51.2775 57.4918 51.0705 56.9708 51.0705Z" fill="#022B4C" />
            <path d="M6.70438 27.4969C7.08507 27.4974 7.45756 27.3863 7.77583 27.1774L37.7189 7.64296L36.9527 11.9698C36.8618 12.483 36.9783 13.0112 37.2767 13.4385C37.5751 13.8658 38.0309 14.1572 38.544 14.2486C38.658 14.2684 38.7736 14.2783 38.8893 14.2783C39.3505 14.2776 39.7967 14.1149 40.15 13.8186C40.5033 13.5222 40.7413 13.1111 40.8222 12.6572L42.3818 3.86285C42.3873 3.76373 42.3849 3.66434 42.3746 3.56562C42.3869 3.42016 42.3834 3.27379 42.364 3.1291C42.324 2.98137 42.2672 2.83872 42.1946 2.70398C42.1669 2.61555 42.1327 2.52933 42.0921 2.44604L42.0705 2.42404C41.9723 2.29468 41.8579 2.17842 41.7302 2.07809C41.6676 2.00775 41.6 1.94209 41.5279 1.88164C41.4535 1.84442 41.3769 1.812 41.2984 1.78459C41.147 1.69973 40.9848 1.63595 40.8161 1.59502L40.7906 1.585L31.9963 0.0243923C31.4878 -0.0564712 30.968 0.0654823 30.5485 0.364013C30.1291 0.662544 29.8436 1.11379 29.7535 1.62067C29.6634 2.12755 29.7759 2.64954 30.0667 3.07434C30.3576 3.49914 30.8035 3.79279 31.3087 3.89212L35.2157 4.58579L5.62901 23.8873C5.27445 24.1187 5.00416 24.4585 4.85838 24.856C4.71261 25.2535 4.69917 25.6875 4.82006 26.0933C4.94095 26.499 5.1897 26.8549 5.52925 27.1078C5.8688 27.3608 6.28098 27.4972 6.70438 27.4969Z" fill="#022B4C" />
        </svg>

}

export default BarIcon