import { Row, Col } from "react-bootstrap";
import { React, useState } from "react";
import RoundedButton from "../../../styleGuide/Button/RoundedButton";
import classes from "./GetStarted.module.css";
import clsx from "clsx";
import SvgIcon from "../../../styleGuide/SvgIcon";
import { ICONS } from "../../../styleGuide/SvgIcon/constants";
import { useContext } from "react";
import ScreenBreakpointContext from "../../../styleGuide/ScreenBreakpointProvider/Provider";
import { redirectGoogleLogin } from "../../../helper/redirect";
import { captureLeadBasic } from "../../../helper/leadsquared";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import contactUsConfirmation from "../../../assets/contact-us-confirmation.svg"

//* Added temporarily to redirect to modal instead of login
const TEMP_CTA_PATHNAME = process.env.REACT_APP_TEMP_CTA_PATH

const GetStarted = () => {
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [invalidDetailsLabel, setInvalidDetailsLabel] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const openModal = () => {
    setShowModal(true);
    setInvalidDetailsLabel(false);
  }

  const closeModal = () => {
    setShowModal(false);
  }
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const showConfirmed = async () => {
    setShowConfirmation(true);
    sleep(3500).then(() => {
      closeModal();
      redirectGoogleLogin();
    });
  };

  const isValidEmail = (leadEmail) => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(leadEmail)) {
      return true
    }
    return false
  }

  const submitDetails = async () => {
    if (firstname !== "" && lastname !== ""
      && (mobileNumber && (/^\d{10}$/.test(mobileNumber))
        && isValidEmail(email)
      )) {
      setBtnDisabled(true)
      await window.fbq('trackSingle', '333999584825866', 'CompleteRegistration')
      await window.fbq('trackSingle', '2001403463411336', 'CompleteRegistration')
      await captureLeadBasic(firstname, lastname, email, mobileNumber);
      await showConfirmed();
    } else {
      setInvalidDetailsLabel(true);
    }
  };

  const handleNameChange = (e) => {
    let fullname = e.target.value.split(" ");
    setFirstname(fullname[0]);
    setLastname(fullname[1]);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  }

  const { screen } = useContext(ScreenBreakpointContext);
  return (
    <>
      <Row
        className={clsx(
          "pt-5",
          classes["button-row"],
          "justify-content-lg-left"
        )}
      >
        <Col xs="auto">
          <RoundedButton
            text="Get Started"
            onClick={window.location.pathname === TEMP_CTA_PATHNAME ? openModal : redirectGoogleLogin}
            icon={<SvgIcon icon={ICONS.arrowRight} small={screen.lgDown} />}
            shine
          />
          <div
            className={clsx(
              classes["fees"],
              "text-center",
              "text-white",
              "pt-2"
            )}
          >
            *No Upfront Fees{" "}
          </div>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={closeModal}
        animation={true}
        size="md"
        dialogClassName="contact-form"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="pb-5">
          <div className="text-center py-4 px-4 px-lg-0">
            <div className="contact-form-title">
              <span>Your Personalised</span>
            </div>
            <div className="contact-form-title">
              <span>Digital Marketing Course</span>
            </div>
          </div>
          {showModal ? (
            <Form className="px-5">
              {invalidDetailsLabel ? <Form.Label className="form-label-alert">Invalid Details!</Form.Label> : null}
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={handleNameChange} type="text" placeholder="" required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control onChange={handleEmailChange} type="email" placeholder="" required />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicNumber">
                <Form.Label>Number</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>+ 91</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control onChange={handleMobileNumberChange} type="text" placeholder="" required />
                </InputGroup>
                <Form.Label className="form-label-info">*Will be used to provide course access</Form.Label>
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  className="contact-form-submit-button"
                  onClick={submitDetails}
                  disabled={btnDisabled}
                >
                  Continue
                </Button>
              </div>
            </Form>
          ) : null}
        </div>
        {showModal && showConfirmation ? (
          <div className={clsx("contact-form-sent")}>
            <div className="d-flex justify-content-center">
              <img src={contactUsConfirmation} alt="confirmation logo"></img>
            </div>

            <div className="text-center py-4 px-4 px-lg-0">
              <div className="contact-form-redirect-msg">
                <span>Awesome, let's begin! Redirecting you to course onboarding. Please login with Google to continue.</span>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default GetStarted;
