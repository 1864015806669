import React from 'react';

const Location = ({ small }) => {
    return small ? <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99881 0.296875C5.58486 0.296875 1.99414 3.75926 1.99414 8.01099C1.99414 11.2088 4.04995 14.0792 7.12905 15.2228L9.58485 19.9993C9.65925 20.1518 9.82206 20.2505 9.99881 20.2505C10.1756 20.2505 10.3383 20.1518 10.4174 19.9993L12.8686 15.2228C15.9523 14.0792 18.0081 11.2088 18.0081 8.01099C18.0081 3.75926 14.4128 0.296875 9.99881 0.296875ZM9.99881 3.23452C11.2221 3.23452 12.2174 4.18982 12.2174 5.36937C12.2174 6.54889 11.2221 7.50868 9.99881 7.50868C8.7802 7.50868 7.78485 6.54889 7.78485 5.36937C7.78485 4.18982 8.7802 3.23452 9.99881 3.23452ZM13.3895 11.6124C13.3058 11.7021 13.1848 11.747 13.0593 11.747H6.93833C6.81274 11.747 6.69646 11.7021 6.60809 11.6124C6.51972 11.5272 6.47322 11.4106 6.47322 11.294C6.51043 9.45068 8.09181 7.95269 9.99881 7.95269C11.9104 7.95269 13.4918 9.45068 13.5244 11.294C13.529 11.4106 13.4779 11.5272 13.3895 11.6124Z" fill="#022B4D" />
        <path d="M20 18.617C20 17.0203 17.1349 16.0919 14.0744 15.6838C13.9023 15.769 13.7256 15.8452 13.5488 15.9215L13.2605 16.4821C17.214 16.8947 19.0698 17.9577 19.0698 18.617C19.0698 19.5364 15.9349 20.8012 10.7721 20.9178C10.5209 20.9223 10.2605 20.9268 10 20.9268C9.73488 20.9268 9.47442 20.9223 9.22326 20.9178C4.06511 20.8012 0.930233 19.5364 0.930233 18.617C0.930233 17.9577 2.78604 16.8947 6.73953 16.4821L6.45116 15.9215C6.27441 15.8452 6.09767 15.769 5.92558 15.6838C2.86976 16.0919 0 17.0203 0 18.617C0 20.7205 5.03256 21.8238 10 21.8238C14.9721 21.8238 20 20.7205 20 18.617Z" fill="#015078" />
    </svg>
        :
        <svg width="52" height="58" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.979 0C14.512 0 5.18359 9.32829 5.18359 20.7832C5.18359 29.3987 10.5244 37.132 18.5236 40.2132L24.9036 53.082C25.0969 53.4928 25.5198 53.7587 25.979 53.7587C26.4382 53.7587 26.8611 53.4928 27.0665 53.082L33.4344 40.2132C41.4456 37.132 46.7864 29.3987 46.7864 20.7832C46.7864 9.32829 37.4461 0 25.979 0ZM25.979 7.91456C29.1569 7.91456 31.7427 10.4883 31.7427 13.6662C31.7427 16.8441 29.1569 19.4299 25.979 19.4299C22.8132 19.4299 20.2273 16.8441 20.2273 13.6662C20.2273 10.4883 22.8132 7.91456 25.979 7.91456ZM34.7877 30.4862C34.5702 30.7279 34.2561 30.8487 33.9299 30.8487H18.0282C17.7019 30.8487 17.3998 30.7279 17.1702 30.4862C16.9406 30.2566 16.8198 29.9424 16.8198 29.6282C16.9165 24.662 21.0248 20.6262 25.979 20.6262C30.9452 20.6262 35.0536 24.662 35.1382 29.6282C35.1502 29.9424 35.0173 30.2566 34.7877 30.4862Z" fill="#022B4D" />
            <path d="M51.9583 49.3602C51.9583 45.0585 44.5149 42.5573 36.5641 41.4577C36.1171 41.6873 35.6579 41.8927 35.1987 42.0981L34.4496 43.6085C44.7204 44.7202 49.5416 47.5839 49.5416 49.3602C49.5416 51.8373 41.3975 55.2448 27.9849 55.5589C27.3325 55.571 26.6558 55.5831 25.9791 55.5831C25.2904 55.5831 24.6137 55.571 23.9612 55.5589C10.5608 55.2448 2.41666 51.8373 2.41666 49.3602C2.41666 47.5839 7.2379 44.7202 17.5087 43.6085L16.7596 42.0981C16.3004 41.8927 15.8412 41.6873 15.3941 41.4577C7.45539 42.5573 0 45.0585 0 49.3602C0 55.0273 13.0742 57.9998 25.9791 57.9998C38.8962 57.9998 51.9583 55.0273 51.9583 49.3602Z" fill="#015078" />
        </svg>

}

export default Location