import React from 'react';

const FreelancerIcon = ({ small }) => {
    return small ?
        <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1865 8.5035H15.999H2.81152C2.29334 8.5035 1.87402 8.87882 1.87402 9.34264V26.1253H15.999H30.124V9.34264C30.124 8.87882 29.7047 8.5035 29.1865 8.5035Z" fill="#0D5F7B" />
            <path d="M30.124 9.34264C30.124 8.87882 29.7047 8.5035 29.1865 8.5035H15.999V26.1253H30.124V9.34264Z" fill="#013E5E" />
            <path d="M16.001 10.1821H3.75098V23.6079H16.001H28.251V10.1821H16.001Z" fill="#D5E8FE" />
            <path d="M15.999 10.1821H28.249V23.6079H15.999V10.1821Z" fill="#D5E8FE" />
            <path d="M31.0625 25.2861H16H0.9375C0.41975 25.2861 0 25.6617 0 26.1253C0 27.5157 1.25919 28.6428 2.8125 28.6428H16H29.1875C30.7408 28.6428 32 27.5157 32 26.1253C32 25.6617 31.5802 25.2861 31.0625 25.2861Z" fill="#D5E8FE" />
            <path d="M31.999 26.1253C31.999 25.6617 31.5793 25.2861 31.0615 25.2861H15.999V28.6428H29.1865C30.7398 28.6428 31.999 27.5157 31.999 26.1253Z" fill="#EFA000" />
            <path d="M21.625 16.8946H17.875C17.3577 16.8946 16.9375 16.5184 16.9375 16.0555V9.34232C16.9375 8.87849 16.5182 8.50317 16 8.50317C15.4818 8.50317 15.0625 8.87849 15.0625 9.34232V16.0555C15.0625 16.7966 15.4288 17.4569 16 17.9181C16.4987 18.3207 17.1521 18.5729 17.875 18.5729H21.625C22.1423 18.5729 22.5625 18.949 22.5625 19.412C22.5625 19.875 22.1423 20.2512 21.625 20.2512H16H8.5C7.98181 20.2512 7.5625 20.6265 7.5625 21.0903C7.5625 21.5541 7.98181 21.9294 8.5 21.9294H16H21.625C23.1759 21.9294 24.4375 20.8002 24.4375 19.412C24.4375 18.0239 23.1759 16.8946 21.625 16.8946Z" fill="#D5E8FE" />
            <path d="M24.4385 19.412C24.4385 18.0239 23.1768 16.8946 21.626 16.8946H17.876C17.3587 16.8946 16.9385 16.5184 16.9385 16.0555V9.34232C16.9385 8.87849 16.5192 8.50317 16.001 8.50317V17.9181C16.4997 18.3207 17.153 18.5729 17.876 18.5729H21.626C22.1432 18.5729 22.5635 18.949 22.5635 19.412C22.5635 19.875 22.1432 20.2512 21.626 20.2512H16.001V21.9294H21.626C23.1768 21.9294 24.4385 20.8002 24.4385 19.412Z" fill="#EFA000" />
            <path d="M24.3388 14.0018L16.8388 0.426502C16.6795 0.142205 16.3398 0 16.0001 0C15.6605 0 15.3208 0.142205 15.1615 0.426558L7.66152 14.0019C7.49764 14.2952 7.53977 14.6468 7.76864 14.9025C7.99752 15.1573 8.37383 15.2704 8.72721 15.1909L16.0001 13.5642L23.2731 15.1908C23.6193 15.2701 23.9988 15.1616 24.2316 14.9024C24.4605 14.6467 24.5026 14.2952 24.3388 14.0018Z" fill="#4FCEB8" />
            <path d="M24.2325 14.9024C24.4613 14.6468 24.5035 14.2952 24.3396 14.0018L16.8396 0.426502C16.6803 0.142205 16.3407 0 16.001 0V13.5642L23.2739 15.1908C23.6201 15.2701 23.9996 15.1616 24.2325 14.9024Z" fill="#1C8976" />
        </svg>
        :
        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M67.4961 21.9688H37H6.50391C5.3056 21.9688 4.33594 22.9384 4.33594 24.1367V67.4961H37H69.6641V24.1367C69.6641 22.9384 68.6944 21.9688 67.4961 21.9688Z" fill="#0D5F7B" />
            <path d="M69.6641 24.1367C69.6641 22.9384 68.6944 21.9688 67.4961 21.9688H37V67.4961H69.6641V24.1367Z" fill="#013E5E" />
            <path d="M37 26.3047H8.67188V60.9922H37H65.3281V26.3047H37Z" fill="#D5E8FE" />
            <path d="M37 26.3047H65.3281V60.9922H37V26.3047Z" fill="#D5E8FE" />
            <path d="M71.832 65.3281H37H2.16797C0.970672 65.3281 0 66.2985 0 67.4961C0 71.0881 2.91187 74 6.50391 74H37H67.4961C71.0881 74 74 71.0881 74 67.4961C74 66.2985 73.0293 65.3281 71.832 65.3281Z" fill="#D5E8FE" />
            <path d="M74 67.4961C74 66.2985 73.0293 65.3281 71.832 65.3281H37V74H67.4961C71.0881 74 74 71.0881 74 67.4961Z" fill="#EFA000" />
            <path d="M50.0078 43.6484H41.3359C40.1398 43.6484 39.168 42.6766 39.168 41.4805V24.1367C39.168 22.9384 38.1983 21.9688 37 21.9688C35.8017 21.9688 34.832 22.9384 34.832 24.1367V41.4805C34.832 43.3952 35.6791 45.1013 37 46.2928C38.1534 47.3328 39.6641 47.9844 41.3359 47.9844H50.0078C51.204 47.9844 52.1758 48.9562 52.1758 50.1523C52.1758 51.3485 51.204 52.3203 50.0078 52.3203H37H19.6562C18.4579 52.3203 17.4883 53.29 17.4883 54.4883C17.4883 55.6866 18.4579 56.6562 19.6562 56.6562H37H50.0078C53.5942 56.6562 56.5117 53.7387 56.5117 50.1523C56.5117 46.5659 53.5942 43.6484 50.0078 43.6484Z" fill="#D5E8FE" />
            <path d="M56.5117 50.1523C56.5117 46.5659 53.5942 43.6484 50.0078 43.6484H41.3359C40.1398 43.6484 39.168 42.6766 39.168 41.4805V24.1367C39.168 22.9384 38.1983 21.9688 37 21.9688V46.2928C38.1534 47.3328 39.6641 47.9844 41.3359 47.9844H50.0078C51.204 47.9844 52.1758 48.9562 52.1758 50.1523C52.1758 51.3485 51.204 52.3203 50.0078 52.3203H37V56.6562H50.0078C53.5942 56.6562 56.5117 53.7387 56.5117 50.1523Z" fill="#EFA000" />
            <path d="M56.2834 36.1749L38.9397 1.10191C38.5712 0.367398 37.7859 0 37.0003 0C36.2148 0 35.4294 0.367398 35.061 1.10205L17.7173 36.175C17.3383 36.9329 17.4357 37.8412 17.965 38.5018C18.4943 39.1603 19.3645 39.4524 20.1817 39.247L37.0003 35.0443L53.819 39.2469C54.6196 39.4517 55.4972 39.1714 56.0357 38.5017C56.565 37.841 56.6624 36.9328 56.2834 36.1749Z" fill="#4FCEB8" />
            <path d="M56.0353 38.5017C56.5646 37.8412 56.662 36.9329 56.2831 36.1749L38.9393 1.10191C38.5709 0.367398 37.7855 0 37 0V35.0443L53.8187 39.2469C54.6192 39.4517 55.4968 39.1714 56.0353 38.5017Z" fill="#1C8976" />
        </svg>
}

export default FreelancerIcon