import React from "react";

const EmailIcon = () => (
  <svg
    width="34"
    height="26"
    viewBox="0 0 34 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3819 9.7574L30.8267 3.30203C30.9842 3.16528 31.0012 2.92691 30.8648 2.769C30.7277 2.61109 30.4893 2.59447 30.3318 2.73122L20.373 11.3663L10.4145 2.73122C10.2566 2.59485 10.0179 2.61147 9.88149 2.769C9.74473 2.92691 9.76173 3.16528 9.91926 3.30203L17.4112 9.79857L12.2267 13.9288C12.0635 14.0591 12.0363 14.2967 12.1666 14.4599C12.2414 14.5536 12.3514 14.6023 12.4624 14.6023C12.5452 14.6023 12.6283 14.5755 12.6978 14.52L17.9919 10.3021L20.1251 12.1521C20.1965 12.2137 20.2846 12.2446 20.3726 12.2446C20.4606 12.2446 20.549 12.2137 20.62 12.1521L22.7986 10.2629L30.3488 16.0632C30.4175 16.116 30.4987 16.1414 30.5788 16.1414C30.6922 16.1414 30.8044 16.0904 30.8788 15.9937C31.0061 15.8278 30.9747 15.5909 30.8093 15.4636L23.3819 9.7574Z"
      fill="white"
    />
    <path
      d="M32.0756 0H8.67041C7.60963 0 6.7468 0.86283 6.7468 1.92361V3.74334L6.42721 3.20464C6.12952 2.70258 5.50242 2.50085 4.97052 2.73544C4.63808 2.88164 4.20591 3.07091 3.75409 3.26697C2.74204 3.70594 2.0273 4.00778 1.62951 4.1638C1.40284 4.25257 1.21471 4.38215 1.07003 4.54913C-1.90832 7.98496 1.79346 14.6081 6.49332 19.3075C10.0209 22.8355 14.632 25.8014 18.1661 25.801C19.3394 25.8007 20.3953 25.4735 21.2521 24.7308C21.4191 24.5861 21.5483 24.398 21.637 24.1717C21.835 23.6674 22.2339 22.7381 22.5331 22.0486C22.7299 21.5961 22.9196 21.1631 23.0658 20.8307C23.2996 20.2977 23.0979 19.6713 22.5966 19.374L21.9498 18.9902H32.0764C33.1368 18.9902 34 18.127 34 17.0662V1.92361C33.9992 0.86283 33.136 0 32.0756 0ZM22.2105 20.0238C22.3839 20.1265 22.4538 20.3426 22.3729 20.5262C22.2267 20.8594 22.0364 21.2942 21.8403 21.746C21.5377 22.4423 21.135 23.3807 20.9333 23.8956C20.8894 24.007 20.8297 24.0958 20.7565 24.1593C17.6863 26.8218 11.4867 23.2326 7.02711 18.773C2.56751 14.313 -1.02056 8.11453 1.64084 5.04401C1.70431 4.97072 1.79308 4.91141 1.9049 4.86759C2.30723 4.70968 3.02084 4.40859 4.02647 3.97265C4.48962 3.77167 4.93425 3.57712 5.27425 3.42752C5.45784 3.3463 5.67393 3.41694 5.77706 3.59034L8.58768 8.32684C8.6587 8.44622 8.66173 8.59695 8.59599 8.71972L6.85333 11.9655C6.61005 12.4181 6.69089 12.9663 7.05431 13.3289L12.4716 18.7462C12.8346 19.1084 13.382 19.1897 13.8349 18.9471L17.0807 17.2045C17.2035 17.1391 17.3542 17.1418 17.4736 17.2132L22.2105 20.0238ZM33.2437 17.0658C33.2437 17.7099 32.7197 18.2343 32.0756 18.2343H20.6756L17.8601 16.5634C17.5144 16.3583 17.0789 16.3488 16.7237 16.5392L13.4779 18.2815C13.3215 18.3661 13.1319 18.337 13.0061 18.212L7.58885 12.7944C7.46306 12.6689 7.43548 12.4793 7.51934 12.3229L9.262 9.07672C9.4524 8.72161 9.44296 8.28642 9.23745 7.94076L7.50234 5.01681V1.92361C7.50234 1.27951 8.02631 0.755543 8.67041 0.755543H32.0756C32.7197 0.755543 33.2437 1.27951 33.2437 1.92361V17.0658Z"
      fill="white"
    />
  </svg>
);

export default EmailIcon;
