import axios from "axios";

const captureLeadBasic = async (firstname, lastname, email, mobile) => {
    let date_string = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const leadJson = [
        {
            "Attribute": "FirstName",
            "Value": firstname
        },
        {
            "Attribute": "LastName",
            "Value": lastname
        },
        {
            "Attribute": "Phone",
            "Value": mobile
        },
        {
            "Attribute": "Mobile",
            "Value": mobile
        },
        {
            "Attribute": "EmailAddress",
            "Value": email
        },
        {
            "Attribute": "mx_City",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Sign_up_date",
            "Value": date_string
        },
        {
            "Attribute": "mx_Work_or_Education_Status",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Learning_stage",
            "Value": "NA"
        },
        {
            "Attribute": "mx_User_Primary_goal",
            "Value": "NA"
        },
        {
            "Attribute": "mx_User_Secondary_goal",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Majors_selected",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Minors_selected",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Last_Activity_on_Website",
            "Value": date_string
        },
        {
            "Attribute": "mx_UTM_Tag",
            "Value": "DIRECT - LANDING PAGE"
        }
    ];

    try {
        const res = await axios.post(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=${process.env.REACT_APP_LEADSQUARED_ACCESS_KEY}&secretKey=${process.env.REACT_APP_LEADSQUARED_SECRET_KEY}`,
            leadJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
        return res
    } catch (err) {
        console.log(err);
    }
}

const captureLeadDownloadBrochure = async (firstname, lastname, email, mobile, utm) => {
    let date_string = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const leadJson = [
        {
            "Attribute": "FirstName",
            "Value": firstname
        },
        {
            "Attribute": "LastName",
            "Value": lastname
        },
        {
            "Attribute": "Phone",
            "Value": mobile
        },
        {
            "Attribute": "Mobile",
            "Value": mobile
        },
        {
            "Attribute": "EmailAddress",
            "Value": email
        },
        {
            "Attribute": "mx_City",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Sign_up_date",
            "Value": date_string
        },
        {
            "Attribute": "mx_Work_or_Education_Status",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Learning_stage",
            "Value": "NA"
        },
        {
            "Attribute": "mx_User_Primary_goal",
            "Value": "NA"
        },
        {
            "Attribute": "mx_User_Secondary_goal",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Majors_selected",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Minors_selected",
            "Value": "NA"
        },
        {
            "Attribute": "mx_Last_Activity_on_Website",
            "Value": date_string
        },
        {
            "Attribute": "mx_UTM_Tag",
            "Value": utm
        }
    ];

    try {
        const res = await axios.post(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=${process.env.REACT_APP_LEADSQUARED_ACCESS_KEY}&secretKey=${process.env.REACT_APP_LEADSQUARED_SECRET_KEY}`,
            leadJson, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
        return res
    } catch (err) {
        console.log(err);
    }
}

export { captureLeadBasic, captureLeadDownloadBrochure }