import React from 'react';

const Speaker = ({ small }) => {
    return small
        ? <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.71836 10.2045H6.05892V4.66507H1.72659C0.776161 4.66507 0 5.35311 0 6.19562V8.67044C0 9.51644 0.776161 10.2045 1.72659 10.2045H1.99587L4.30855 17.2955C4.36003 17.4464 4.51448 17.5517 4.68873 17.5517H7.58751C7.71027 17.5517 7.82907 17.4991 7.90431 17.4113C7.97955 17.3236 7.99935 17.2077 7.96769 17.1024L5.71836 10.2045Z" fill="white" />
            <path d="M16.3437 5.10471H16.2012V9.76264H16.3437C17.7892 9.76264 18.9693 8.71663 18.9693 7.43543C18.9693 6.15074 17.7892 5.10471 16.3437 5.10471Z" fill="white" />
            <path d="M15.2063 0.0422634C15.0796 -0.0174062 14.9291 -0.0138924 14.8064 0.0528047L6.85059 4.38805V10.482L14.8064 14.8172C14.8698 14.8523 14.941 14.8699 15.0123 14.8699C15.0796 14.8699 15.147 14.8558 15.2063 14.8242C15.3331 14.7611 15.4083 14.6452 15.4083 14.5188V0.351174C15.4083 0.221294 15.3331 0.105447 15.2063 0.0422634Z" fill="white" />
        </svg>
        : <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.44 29.0704H15.3V13.2904H4.35999C1.95996 13.2904 0 15.2504 0 17.6504V24.7004C0 27.1104 1.95996 29.0704 4.35999 29.0704H5.03998L10.8799 49.2704C11.0099 49.7004 11.4 50.0004 11.84 50.0004H19.16C19.47 50.0004 19.77 49.8504 19.96 49.6004C20.15 49.3504 20.2 49.0204 20.12 48.7204L14.44 29.0704Z" fill="white" />
            <path d="M41.2701 14.5404H40.9102V27.8104H41.2701C44.9202 27.8104 47.9001 24.8304 47.9001 21.1804C47.9001 17.5204 44.9202 14.5404 41.2701 14.5404Z" fill="white" />
            <path d="M38.4008 0.120397C38.0807 -0.0495856 37.7007 -0.0395758 37.3907 0.150427L17.3008 12.5004V29.8604L37.3907 42.2104C37.5508 42.3104 37.7308 42.3604 37.9108 42.3604C38.0807 42.3604 38.2508 42.3204 38.4008 42.2304C38.7208 42.0504 38.9108 41.7204 38.9108 41.3604V1.0004C38.9108 0.630407 38.7208 0.30039 38.4008 0.120397Z" fill="white" />
        </svg>

}

export default Speaker