import React from "react";
import { useContext } from "react";
import { useMemo } from "react";
import ScreenBreakpointContext from "../ScreenBreakpointProvider/Provider";
import { TYPOGRAPHY_VARIANTS } from "./constants";

const Typography = ({ variant, color, children }) => {
  const { screen } = useContext(ScreenBreakpointContext);

  const fontSize = useMemo(() => {
    switch (variant) {
      case TYPOGRAPHY_VARIANTS.pageHeading:
        return screen.lgUp ? '3rem' : '1.25rem';
      case TYPOGRAPHY_VARIANTS.sectionHeading:
        return screen.lgUp ? '2.25rem' : '1.125rem'
      case TYPOGRAPHY_VARIANTS.subHeading:
        return screen.lgUp ? '1.65rem' : '1rem'
      default:
        return '1rem'
    }
  }, [variant, screen])

  const fontWeight = useMemo(() => {
    switch (variant) {
      case TYPOGRAPHY_VARIANTS.pageHeading:
        return '700';
      case TYPOGRAPHY_VARIANTS.sectionHeading:
      case TYPOGRAPHY_VARIANTS.subHeading:
        return '600';
      default:
        return '450'
    }
  }, [variant])

  const fontFamily = "Montserrat";

  return (
    <span style={{ color, fontSize, fontWeight, fontFamily }}>{children}</span>
  );
};

export default Typography;
