import { React } from "react";
import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SvgIcon from "../../styleGuide/SvgIcon";
import { ICONS } from "../../styleGuide/SvgIcon/constants";
import clsx from "clsx";
import classes from "./Footer.module.css";
import screenBreakpointContext from "../../styleGuide/ScreenBreakpointProvider/Provider";
import { FOOTER_SOCIAL_ICONS } from "../../constants";

function Footer() {
  const { screen } = useContext(screenBreakpointContext);
  return (
    <>
      <div className={clsx(classes["bg-image"])}>
        <Container fluid className={clsx("py-5", classes["footer-section"])}>
          <Row className="pt-5">
            <Col xs={12} className="d-flex justify-content-center pt-5">
              <SvgIcon icon={ICONS.footerLogoIcon} />
            </Col>
            <Col xs={12} className="d-flex justify-content-center pt-4">
              <div className="d-flex align-items-center">
                <SvgIcon icon={ICONS.emailIcon} small={screen.lgDown} />
              </div>
              <div className="mx-3 pt-2">
                <a href="mailto:hello@careerninja" className={clsx("text-white", classes["email-text"])}  style={{textDecoration:'none'}}>
                  hello@careerninja.in
                </a>
              </div>
            </Col>
          </Row>
          <Row className="px-lg-5 pt-lg-5 pt-3 no-gutters">
            <Col className="d-flex">
              <div>
                <SvgIcon icon={ICONS.whatsappIcon} small={screen.lgDown} />
              </div>
              <div className="pt-lg-1">
                <a href="https://wa.me/+918591247669" target="_blank" className={clsx("text-white", classes["email-text"])} style={{textDecoration:'none'}} rel="noreferrer">
                  &nbsp; +918591247669
                </a>
              </div>
            </Col>
            <Col className="d-flex justify-content-between">
              {FOOTER_SOCIAL_ICONS.map((element, index) => (
                <div className="mx-1 mx-lg-3" key={index}>
                  <a href={element.url} target="_blank" rel="noreferrer">
                    <SvgIcon icon={element.iconName} small={screen.lgDown} />
                  </a>
                </div>
              ))}
            </Col>
            <Col className="pt-2 pt-lg-0 d-flex justify-content-end">
              <div>
                <a href="/privacypolicy.html" target="_blank" className={clsx("text-white", classes["email-text"])} style={{textDecoration:'none'}}>
                  Terms &amp; Conditions
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
