import React, {useMemo} from 'react';
import clsx from "clsx";

const Box = ({
                 bgColor,
                 gradiant,
                 gradiantStartColor,
                 gradiantEndColor,
                 gradiantDirection,
                 children,
                 ref,
                 cName,
             }) => {
    const boxStyle = useMemo(
        () =>
            gradiant && gradiantStartColor && gradiantEndColor
                ? {
                    backgroundImage: gradiantDirection
                        ? `linear-gradient(${gradiantDirection},${gradiantStartColor}, ${gradiantEndColor})`
                        : `linear-gradient(${gradiantStartColor}, ${gradiantEndColor})`,
                }
                : {
                    backgroundColor: bgColor,
                },
        [gradiant, gradiantStartColor, gradiantEndColor, bgColor, gradiantDirection]
    );

    return (
        <div style={boxStyle} ref={ref} className={clsx(cName, 'w-100')}>
            {children}
        </div>
    );
};

export default Box;
