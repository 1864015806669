import { React, useState } from "react";
import RoundedButton from "../../styleGuide/Button/RoundedButton";
import style from "../../styleConstants";
// import SvgIcon from "../../styleGuide/SvgIcon";
// import { ICONS } from "../../styleGuide/SvgIcon/constants";
import classes from "./WhatOurLearnersSay.module.css";
import clsx from "clsx";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// import ScreenBreakpointContext from "../../styleGuide/ScreenBreakpointProvider/Provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import contactUsConfirmation from "../../assets/contact-us-confirmation.svg";
import "./ContactForm.css";
// import { redirectGoogleLogin } from "../../helper/redirect";
import Brochure from "../../assets/Brochure.pdf"
import downloadBrochureTriggers from "../../helper/notificationTriggers";
import { captureLeadDownloadBrochure } from "../../helper/leadsquared";
import { objectIsEmpty } from "../../helper/helper";
import queryString from 'query-string';

const DownloadBrochure = () => {
  // const { screen } = useContext(ScreenBreakpointContext);
  const [modalShow, setModalShow] = useState(false);
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  // const [btnDisabled, setBtnDisabled] = useState(false);
  const [invalidDetailsLabel, setInvalidDetailsLabel] = useState(false);

  const openModal = () => {
    setModalShow(true);
    setshowConfirmation(false);
    setInvalidDetailsLabel(false);
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const isValidEmail = (leadEmail) => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(leadEmail)) {
      return true
    }
    return false
  }

  const showConfirmed = async () => {
    if (firstname !== "" && lastname !== ""
      && (mobileNumber && (/^\d{10}$/.test(mobileNumber))
        && isValidEmail(email)
      )) {
      // setBtnDisabled(true);
      const params = queryString.parse(global.location.search)
      const isEmpty = objectIsEmpty(params);
      if (!isEmpty && params.utm_campaign) {
        await captureLeadDownloadBrochure(firstname, lastname, email, mobileNumber, params.utm_campaign.toString().toLowerCase() + "_bd");
      } else {
        await captureLeadDownloadBrochure(firstname, lastname, email, mobileNumber, "BROCHURE DOWNLOAD");
      }
      await downloadBrochureTriggers({ mobile: mobileNumber, email: email, firstname: firstname, lastname: lastname });
      await sleep(2500).then(() => {
        setshowConfirmation(true);
        closeModal();
      });
    } else {
      setInvalidDetailsLabel(true)
    }
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const handleNameChange = (e) => {
    let fullname = e.target.value.split(" ");
    setFirstname(fullname[0]);
    setLastname(fullname[1]);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleMobileNumberChange = (e) => {
    const regex = /^[0-9]*$/
    if (regex.test(e.target.value) && e.target.value.length <= 10)
      setMobileNumber(e.target.value);
  }

  return (
    <>
      <div className="d-sm-flex justify-content-sm-center align-items-center py-5">
        {/* <span className={classes["span-flex"]}>
          <RoundedButton
            text="Get Started"
            onClick={redirectGoogleLogin}
            icon={<SvgIcon icon={ICONS.arrowRight} small={screen.lgDown} />}
            shine
          />
        </span> */}
        <span
          className={clsx(
            classes["span-flex"],
            classes["span-flex-margin"],
            "my-3 my-lg-0"
          )}
        >
          <RoundedButton
            text="Download Brochure"
            icon={<FontAwesomeIcon icon={faChevronDown} />}
            color={style.colors.deepGreen}
            outline={style.colors.deepYellow}
            onClick={openModal}
          />
        </span>
      </div>

      <Modal
        show={modalShow}
        animation={true}
        onHide={closeModal}
        size="md"
        dialogClassName="contact-form"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="pb-5">
          <div className="text-center py-4 px-4 px-lg-0">
            <div className="contact-form-title">
              <span>Your Personalised</span>
            </div>
            <div className="contact-form-title">
              <span>Digital Marketing Course</span>
            </div>
          </div>
          {modalShow && !showConfirmation ? (
            <Form className="px-5">
              {invalidDetailsLabel ? <Form.Label className="form-label-alert">Invalid Details!</Form.Label> : null}
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={handleNameChange} type="text" placeholder="" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control onChange={handleEmailChange} type="email" placeholder="" value={email} required />
              </Form.Group>

              <Form.Group className="mb-4" controlId="formBasicNumber">
                <Form.Label>Number</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>+ 91</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control onChange={handleMobileNumberChange} type="text" placeholder="" value={mobileNumber} required />
                </InputGroup>
                <Form.Label className="form-label-info">*Will be used to provide course access</Form.Label>
              </Form.Group>

              <a download='Career Ninja - Digital Marketing' href={Brochure} className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  // type="submit"
                  className="contact-form-submit-button"
                  onClick={showConfirmed}
                >
                  Send Me The Brochure
                </Button>
              </a>
            </Form>
          ) : null}
          {modalShow && showConfirmation ? (
            <div className={clsx("contact-form-sent")}>
              <div className="d-flex justify-content-center">
                <img src={contactUsConfirmation} alt="confirmation logo"></img>
              </div>

              <div className="text-center py-4 px-4 px-lg-0">
                <div className="contact-form-title">
                  <span>Sent!</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default DownloadBrochure;
