import React from 'react';

const BookIcon = ({ small }) => small ? <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.38798 0C5.38703 0 5.38596 0 5.38501 0C5.05185 0 4.73849 0.129875 4.50186 0.365904C4.26263 0.604542 4.13086 0.922291 4.13086 1.26056L4.13086 20.1624C4.13086 20.8556 4.69697 21.4209 5.39296 21.4226C8.32707 21.4296 13.2429 22.0412 16.6341 25.59V5.80868C16.6341 5.57372 16.5741 5.35299 16.4608 5.17034C13.6775 0.687923 8.32873 0.00687923 5.38798 0Z" fill="white" />
    <path d="M31.0959 20.1626V1.26056C31.0959 0.922292 30.9641 0.604542 30.7249 0.365904C30.4883 0.129875 30.1747 0 29.8419 0C29.8408 0 29.8397 0 29.8388 0C26.8981 0.00699784 21.5494 0.688042 18.7659 5.17046C18.6527 5.35311 18.5928 5.57384 18.5928 5.8088L18.5928 25.59C21.984 22.0412 26.8998 21.4296 29.8339 21.4226C30.5298 21.4209 31.0959 20.8556 31.0959 20.1626Z" fill="white" />
    <path d="M33.9648 4.35913L33.0508 4.35913V20.1625C33.0508 21.9323 31.6089 23.3753 29.8365 23.3797C27.3477 23.3857 23.2441 23.8723 20.3379 26.6229C25.3642 25.3923 30.6629 26.1923 33.6827 26.8804C34.0597 26.9663 34.4493 26.8776 34.7514 26.6368C35.0525 26.3966 35.2251 26.0376 35.2251 25.6523V5.61945C35.2253 4.92453 34.6597 4.35913 33.9648 4.35913Z" fill="white" />
    <path d="M2.17431 20.1625L2.17431 4.35913L1.26032 4.35913C0.56552 4.35913 0 4.92453 0 5.61945L0 25.6519C0 26.0374 0.172692 26.3963 0.473718 26.6365C0.775574 26.8771 1.16484 26.9662 1.54249 26.8801C4.56224 26.1918 9.86102 25.3919 14.8871 26.6226C11.981 23.8721 7.87743 23.3855 5.38869 23.3796C3.61634 23.3754 2.17431 21.9323 2.17431 20.1625Z" fill="white" />
</svg>
    : <svg width="65" height="50" viewBox="0 0 65 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.94271 0.000244141C9.94096 0.000244141 9.93899 0.000244141 9.93724 0.000244141C9.32247 0.000244141 8.74425 0.239892 8.30763 0.675417C7.8662 1.11576 7.62305 1.70207 7.62305 2.32625L7.62305 37.2044C7.62305 38.4834 8.66765 39.5264 9.9519 39.5297C15.366 39.5426 24.4367 40.671 30.6943 47.2195L30.6943 10.7185C30.6943 10.285 30.5835 9.87769 30.3745 9.54065C25.2386 1.26961 15.369 0.0129378 9.94271 0.000244141Z" fill="white" />
        <path d="M57.3776 37.2043V2.32601C57.3776 1.70183 57.1345 1.11551 56.6931 0.675173C56.2564 0.239648 55.6778 0 55.0637 0C55.0617 0 55.0597 0 55.058 0C49.6319 0.0129126 39.7623 1.26959 34.6262 9.54062C34.4172 9.87766 34.3066 10.285 34.3066 10.7185L34.3066 47.2192C40.5642 40.6708 49.6349 39.5424 55.049 39.5295C56.333 39.5262 57.3776 38.4831 57.3776 37.2043Z" fill="white" />
        <path d="M62.674 8.04346L60.9875 8.04346V37.2042C60.9875 40.4697 58.3268 43.1325 55.0562 43.1406C50.464 43.1516 42.8919 44.0496 37.5293 49.1251C46.804 46.8542 56.5812 48.3304 62.1533 49.6002C62.8491 49.7587 63.568 49.5949 64.1254 49.1507C64.6809 48.7075 64.9995 48.045 64.9995 47.3339V10.369C64.9998 9.08675 63.9563 8.04346 62.674 8.04346Z" fill="white" />
        <path d="M4.01208 37.2042L4.01208 8.04346L2.32557 8.04346C1.04351 8.04346 0 9.08675 0 10.369L0 47.3333C0 48.0446 0.318655 48.7068 0.874114 49.15C1.4311 49.5941 2.14939 49.7584 2.84623 49.5995C8.41832 48.3295 18.1957 46.8535 27.47 49.1244C22.1076 44.0491 14.5356 43.1514 9.94332 43.1404C6.67294 43.1325 4.01208 40.4697 4.01208 37.2042Z" fill="white" />
    </svg>

export default BookIcon