import React from "react";

const InstagramIcon = ({ small }) =>
  small ? (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.15458 7.42746H2.44581C1.24579 7.42746 0.269531 6.45121 0.269531 5.25126V2.54249C0.269531 1.34247 1.24579 0.366211 2.44581 0.366211H5.15458C6.35453 0.366211 7.33078 1.34247 7.33078 2.54249V5.25126C7.33078 6.45121 6.35453 7.42746 5.15458 7.42746ZM2.44581 0.915419C1.54864 0.915419 0.81874 1.64532 0.81874 2.54249V5.25126C0.81874 6.14836 1.54864 6.87825 2.44581 6.87825H5.15458C6.05168 6.87825 6.78158 6.14836 6.78158 5.25126V2.54249C6.78158 1.64532 6.05168 0.915419 5.15458 0.915419H2.44581Z"
        fill="white"
      />
      <path
        d="M3.8211 5.89513C2.73022 5.89513 1.84277 5.00761 1.84277 3.9168C1.84277 2.826 2.73029 1.93848 3.8211 1.93848C4.91191 1.93848 5.79943 2.826 5.79943 3.9168C5.79943 5.00761 4.91199 5.89513 3.8211 5.89513ZM3.8211 2.48769C3.03307 2.48769 2.39198 3.12877 2.39198 3.9168C2.39198 4.70484 3.03307 5.34592 3.8211 5.34592C4.60914 5.34592 5.25022 4.70484 5.25022 3.9168C5.2503 3.12877 4.60922 2.48769 3.8211 2.48769Z"
        fill="white"
      />
      <path
        d="M5.80609 2.31481C6.01828 2.31481 6.1903 2.14279 6.1903 1.9306C6.1903 1.7184 6.01828 1.54639 5.80609 1.54639C5.59389 1.54639 5.42188 1.7184 5.42188 1.9306C5.42188 2.14279 5.59389 2.31481 5.80609 2.31481Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6789 27H8.3214C3.7329 27 0 23.2671 0 18.6789V8.3214C0 3.7329 3.7329 0 8.3214 0H18.6789C23.2671 0 27 3.7329 27 8.3214V18.6789C27 23.2671 23.2671 27 18.6789 27ZM8.3214 2.1C4.8909 2.1 2.1 4.8909 2.1 8.3214V18.6789C2.1 22.1091 4.8909 24.9 8.3214 24.9H18.6789C22.1091 24.9 24.9 22.1091 24.9 18.6789V8.3214C24.9 4.8909 22.1091 2.1 18.6789 2.1H8.3214Z"
        fill="white"
      />
      <path
        d="M13.5762 21.1407C9.40502 21.1407 6.01172 17.7471 6.01172 13.5762C6.01172 9.40532 9.40532 6.01172 13.5762 6.01172C17.7471 6.01172 21.1407 9.40532 21.1407 13.5762C21.1407 17.7471 17.7474 21.1407 13.5762 21.1407ZM13.5762 8.11172C10.563 8.11172 8.11172 10.563 8.11172 13.5762C8.11172 16.5894 10.563 19.0407 13.5762 19.0407C16.5894 19.0407 19.0407 16.5894 19.0407 13.5762C19.041 10.563 16.5897 8.11172 13.5762 8.11172Z"
        fill="white"
      />
      <path
        d="M21.1683 7.44992C21.9797 7.44992 22.6374 6.79218 22.6374 5.98082C22.6374 5.16946 21.9797 4.51172 21.1683 4.51172C20.357 4.51172 19.6992 5.16946 19.6992 5.98082C19.6992 6.79218 20.357 7.44992 21.1683 7.44992Z"
        fill="white"
      />
    </svg>
  );
export default InstagramIcon;
